import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { isMobile } from "react-device-detect";
import cx from "classnames";

import { useThemeContext } from "../../components/Layout/ThemeContext";

import "./Weather.scss";
import { removeEmptyKeys } from "../../helpers";

export function Weather({ weather }) {
	const { theme } = useThemeContext();

	const current = get(weather, "current", []);
	const temp = get(current, "temp", null);
	const icon = get(current, "weather[0].icon", null);
	const title = get(current, "weather[0].main", "");

	return current ? (
		<div className={cx("weather", "d-flex", { [`weather_${theme.color.key}`]: isMobile })}>
			{
				icon && (
					<div className={"weather__img"}>
						<img src={`//openweathermap.org/img/wn/${icon}@2x.png`} alt={title}/>
					</div>
				)
			}
			{
				temp && (
					<div
						className={"text weather__text"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						<strong>
							{Math.ceil(temp)}&deg;
						</strong>
					</div>
				)
			}
		</div>
	) : null;
}

Weather.propTypes = {
	weather: PropTypes.object,
};

Weather.defaultProps = {
	weather: {},
};
