import React, { useEffect, useRef, useReducer } from "react";
import PropTypes from "prop-types";
import { get, first } from "lodash";
import { graphql, useStaticQuery } from "gatsby";

import { RadioContextProvider, radio } from "../Context";

export default function RadioProvider({ children }) {
	const { hasura } = useStaticQuery(graphql`
		query radioProviderQuery {
			hasura {
				...RadioStations
			}
		}
	`);

	const radioStations = get(hasura, "radioStations", []);

	const stopAll = useRef([]);

	function init() {
		return {
			updatedRadio: radio,
			play: false,
			muted: false,
			volume: 0.5,
			stations: [],
			currentStationIndex: 0,
		};
	}

	function reducer(state, { type, payload }) {
		switch (type) {
			case "update":
				return {
					...state,
					updatedRadio: payload.station,
					currentStationIndex: payload.currentIndex,
				};
			case "play":
				return {
					...state,
					play: payload,
				};
			case "mute":
				return {
					...state,
					muted: payload,
				};
			case "volume":
				return {
					...state,
					volume: payload,
				};
			case "stations":
				return {
					...state,
					stations: payload,
				};
			case "reset":
				return init();
			default:
				throw new Error("Not found the method or unexpected problem");
		}
	}

	const [state, dispatch] = useReducer(reducer, {}, init);

	const { updatedRadio, play, volume, muted, stations, currentStationIndex } = state;

	function nextStation() {
		const currentIndex = stations.findIndex(station => station.slug === updatedRadio.slug) + 1;

		if (stations[currentIndex]) {
			dispatch({
				type: "update",
				payload: {
					station: stations[currentIndex],
					currentIndex,
				},
			});
		}
	}

	function prevStation() {
		const currentIndex = stations.findIndex(station => station.slug === updatedRadio.slug) - 1;

		if (stations[currentIndex]) {
			dispatch({
				type: "update",
				payload: {
					station: stations[currentIndex],
					currentIndex,
				},
			});
		}
	}

	function changeStation(slug) {
		const currentIndex = stations.findIndex(station => station.slug === slug);

		if (stations[currentIndex]) {
			dispatch({
				type: "update",
				payload: {
					station: stations[currentIndex],
					currentIndex,
				},
			});
		}
	}

	useEffect(() => {
		if (radioStations) {
			const payload = radioStations.map(station => {
				return {
					...station,
					disabled: false,
				};
			});

			dispatch({
				type: "stations",
				payload,
			});
		}
	}, []);

	useEffect(() => {
		if (stations.length) {
			const radio = first(stations);

			dispatch({
				type: "update",
				payload: {
					station: radio,
					currentIndex: currentStationIndex,
				},
			});
		}
	}, [stations]);

	return (
		<RadioContextProvider
			value={{
				fwdRef: stopAll,
				radioStations: stations,
				radio: updatedRadio,
				play,
				changeStation,
				muted,
				volume,
				prevStation,
				nextStation,
				currentStationIndex,
				stop() {
					dispatch({
						type: "play",
						payload: false,
					});
				},
				playPause() {
					dispatch({
						type: "play",
						payload: !play,
					});
				},
				soundOff() {
					dispatch({
						type: "mute",
						payload: !muted,
					});
				},
				setVolume(payload) {
					dispatch({
						type: "volume",
						payload,
					});
				},
			}}
		>
			{children}
		</RadioContextProvider>
	);
}

RadioProvider.propTypes = {
	children: PropTypes.element.isRequired,
};

RadioProvider.defaultProps = {
	children: <></>,
};

RadioProvider.displayName = "RadioProvider";
