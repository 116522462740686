import React from "react";
import PropTypes from "prop-types";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";

import { useThemeContext } from "../Layout/ThemeContext";

const ImageSplit = ({
	src,
	mediaSize,
	idx,
	alt,
	responsive,
	title,
}) => {
	const { theme } = useThemeContext();

	const isSplit = src instanceof Array;

	const [width, height] = mediaSize;

	return (
		<div
			style={{
				height: title ? "90%" : "100%",
				flex: 1,
			}}
		>
			{
				isSplit && (
					<ReactCompareSlider
						boundsPadding={0}
						itemOne={
							<ReactCompareSliderImage
								alt={alt}
								src={src[0]}
							/>
						}
						itemTwo={
							<ReactCompareSliderImage
								alt={alt}
								src={src[1]}
								// style={{ transform: "scale(1.125)" }}
							/>
						}
						position={50}
						style={{
							height: "100%",
							width: "100%",
						}}
					/>
				)
			}
			<picture
				style={{
					height: "100px",
					flex: 1,
				}}
			>
				{
					!isSplit && responsive.map(({ src, media }, idx) => <source key={`source-img-${idx}`} srcSet={src} media={media} />)
				}
				{
					!isSplit && (
						<img
							src={src}
							alt={alt}
							data-src={src}
							width={width}
							height={height}
							style={Object.assign({}, { objectFit: "cover" }, { ...theme.image })}
							data-slide={idx}
							className={"carousel__image"}
							orientation={"horizontal"}
						/>
					)
				}
			</picture>
		</div>
	);
};

export default ImageSplit;

ImageSplit.propTypes = {
	src: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
	mediaSize: PropTypes.arrayOf(PropTypes.string),
	idx: PropTypes.number,
	alt: PropTypes.string,
	title: PropTypes.string,
	responsive: PropTypes.arrayOf(PropTypes.shape({
		src: PropTypes.string,
		media: PropTypes.string,
	})),
};

ImageSplit.defaultProps = {
	src: [],
	mediaSize: [],
	idx: Number.MAX_SAFE_INTEGER,
	alt: "",
	responsive: [],
	title: "",
};

ImageSplit.displayName = "ImageSplit";
