import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "gatsby";

import SubMenu from "./SubMenu";
import { Icon } from "../../Media";
import { useThemeContext } from "../../Layout/ThemeContext";
import { MetaPosition } from "../../Meta";

import "./FooterMenu.scss";
import { removeEmptyKeys } from "../../../helpers";

export default function FooterMenu({ items }) {
	const { theme } = useThemeContext();
	const [opened, setOpened] = useState(null);

	function handleExpand(event, idx) {
		event.preventDefault();

		setOpened(opened !== idx ? idx : null);
	}

	return (
		<div className={"menu-footer"}>
			<div className="container">
				<div className="row">
					{items.filter(item => item.kind === "footerMenu").map(({ sub, title, url }, idx) => {
						return sub && sub.length ? (
							<div key={`menu-footer__group-${idx}`} className={"col-12 col-md-3 "}>
								<ul className={"menu-footer__group"}>
									<div
										className={"menu-footer__header"}
										onClick={event => handleExpand(event, idx)}
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.h4,
											color: theme.color.text,
										})}
									>
										{
											url ? <Link to={url} className={"link_light"}
												style={removeEmptyKeys({
													fontFamily: theme.fontFamily,
													fontSize: theme.fontSize.h4,
													color: theme.color.text,
												})}>
												<span itemProp="name">
													{title}
												</span>
												<MetaPosition content={idx} />
											</Link> : title
										}
										<span>
											<Icon
												id={"chevron"}
												className={cx("d-md-none menu-footer__header__chevron", {
													"menu-footer__header__chevron__opened": opened === idx,
												})}
											/>
										</span>
									</div>
									<SubMenu items={sub} isOpen={opened === idx} />
								</ul>
							</div>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
}

FooterMenu.propTypes = {
	items: PropTypes.array,
};
