import React, { useState } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import PropTypes from "prop-types";
import { Input, Icon, Button } from "antd";
import { get } from "lodash";

import Calendar from "../../../../containers/Calendar";
import { Image } from "../../../Media";
import { ClusterMap } from "../../../ObjectMap";
import { translit, formatURL, removeEmptyKeys } from "../../../../helpers";
import { useThemeContext } from "../../../Layout/ThemeContext";
import { MetaPosition } from "../../../../components/Meta";

function SubMenuBody({ subtitle, subDescription, children, extra, actions, buttonLink }) {
	const { theme } = useThemeContext();

	return (
		<div
			className={"py-3 d-flex flex-nowrap flex-column"}
			style={{ height: "100%" }}
		>
			<div
				className={"col-12"}
				style={{ flex: 0 }}
			>
				<div className={"py-3 d-flex flex-nowrap justify-content-between"}>
					{subtitle && <h4
						className={"light menu-desktop__sub-header pl-0"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h4,
							lineHeight: theme.lineHeight.h4,
							color: theme.color.text,
						})}
					>
						{subtitle}
					</h4>}
					{buttonLink && <Link
						key={"about-link"}
						to={buttonLink}
						className={"pr-0"}
					>
						<Button
							className={"button-accent"}
							size={"small"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.button,
								backgroundColor: theme.color.text,
							})}
						>
							Подробнее
						</Button>
					</Link>}
				</div>

				{subDescription && <p
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text || "#fff",
					})}
				>
					{subDescription}
				</p>}
			</div>
			{!!actions.length && <div
				className={"col-12 pb-3 d-flex flex-row"}
				style={{
					flex: 0,
				}}
			>
				{actions}
			</div>}
			<div
				className={"pb-3 col-12"}
				style={{
					flex: 0,
				}}
			>
				<div className={"d-flex flex-wrap justify-content-around"}>
					{children}
				</div>
			</div>
			{extra && <div
				className={"col-12 d-flex flex-column-reverse"}
				style={{
					flex: 1,
				}}
			>
				{extra}
			</div>}
		</div>
	);
}

SubMenuBody.propTypes = {
	subtitle: PropTypes.string,
	subDescription: PropTypes.string,
	buttonLink: PropTypes.string,
	children: PropTypes.node,
	extra: PropTypes.node,
	actions: PropTypes.node,
};

SubMenuBody.defaultProps = {
	subtitle: "",
	subDescription: "",
	children: <></>,
	extra: <></>,
	actions: [],
};

export function InfoMenu() {
	const [filter, setFilter] = useState();

	return (
		<SubMenuBody
			subtitle={"Кластер «Арзамас - Дивеево - Саров»"}
			buttonLink={"https://bigsarov.ru"}
			extra={<ClusterMap filter={filter} />}
			actions={[
				<Input
					key={"filter-input"}
					suffix={<Icon type="search" />}
					placeholder={"Введите название места"}
					onChange={event => setFilter(event.target.value)}
				/>,
			]}
		/>
	);
}

export function ContentMenu({ sub = [] }) {
	const { theme } = useThemeContext();
	const data = [
		{
			date: "1 августа 1754г",
			text: "Родился в Курске",
		},
		{
			date: "1778г",
			text: "Стал послушником в Саровском монастыре",
		},
		{
			date: "1786г",
			text: "Принял монашество и был рукоположен в иеродиаконы",
		},
		{
			date: "1793г",
			text: "Рукоположен в иеромонаха",
		},
		{
			date: "1810г",
			text: "Стал затворником",
		},
		{
			date: "1825г",
			text: "Создание девичьей Мельничной общины",
		},
		{
			date: "15 января 1833г",
			text: "Почил в Саровском монастыре",
		},
		{
			date: "29 января 1903г",
			text: "Причислен к лику святых по инициативе императора Николая II",
		},
		{
			date: "31 июля 1991г",
			text: "Принесение мощей в Серафимо-Дивеевский монастырь",
		},
	];

	return (
		<SubMenuBody
			subtitle={"Преподобный Серафим Саровский"}
			extra={
				<div className={"d-flex justify-content-around flex-wrap"}>
					{
						sub.map(({ url, title, path, img }, idx) => (
							<Link
								key={`menu-desktop__sub-item-${idx}`}
								to={formatURL(`/${path || url}`)}
								className={
									"col menu-desktop__sub-item menu-desktop__sub-link menu-desktop__sub-link__about-saint"
								}
								itemProp="url"
								style={{
									minWidth: "160px",
								}}
							>
								<Image
									className={"menu-desktop__sub-link_image"}
									alt={title}
									src={get(img, "src", "")}
									minHeight={140}
									style={{ height: 130 }}
								/>
								<div>
									<span
										className={"link link_light"}
										itemProp="name"
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.text,
											lineHeight: theme.lineHeight.text,
											color: theme.color.text,
										})}
									>
										{title}
									</span>
								</div>
								<MetaPosition content={idx} />
							</Link>
						))
					}
				</div>
			}
		>
			<div className={"row mb-3"}>
				<div className={"col-9"}>
					{data.map(({ date, text }, idx) => {
						return (
							<div
								key={`${translit(text)}-${idx}`}
								className={"row mt-2"}
								style={Object.assign(
									{},
									removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text || "#fff",
									}),
								)}
							>
								<div className={"col-3"}>{date}</div>
								<div className={"col-9"} style={{}}>
									{text}
								</div>
							</div>
						);
					})}
				</div>
				<div className={"col-3"}>
					<Image
						height={"auto"}
						src={
							"https://storage.yandexcloud.net/serafim-uploads/content/2020/12/03/reverend_71966d6b-d02e-4167-9b68-392418d01b7c.jpg"
						}
						style={{ "width": "75%",
							"float": "right" }}
					/>
				</div>
			</div>
		</SubMenuBody>
	);
}

ContentMenu.propTypes = {
	sub: PropTypes.array,
};

export function ImageMenu({ sub = [], subtitle = "", subDescription, className }) {
	const { theme } = useThemeContext();

	return (
		<SubMenuBody
			subtitle={subtitle}
			subDescription={subDescription}
		>
			{sub.filter(({is_public}) => is_public || is_public === undefined).map(({ img, path, url, title, classNameSub, style = {} }, idx) => (
				<div
					key={`menu-desktop__sub-link-${idx}`}
					className={cx("my-3", className, classNameSub)}
				>
					{classNameSub !== "col-1" && (
						<Link
							to={formatURL(`/${path || url}`)}
							className={
								"d-flex flex-column justify-content-center align-items-center link menu-desktop__sub-link menu-desktop__sub-link_image"
							}
							itemProp="url"
						>
							<div style={style}>{img && <Image src={get(img, "src", "")} className={"mb-3"} />}</div>
							<span
								className={"menu-desktop__sub-title"}
								style={removeEmptyKeys({
									textAlign: "center",
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text || 19,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text || "#fff",
								})}
								itemProp="name"
							>
								{title}
							</span>
							<meta itemProp={"position"} content={idx} />
						</Link>
					)}
				</div>
			))}
		</SubMenuBody>
	);
}

ImageMenu.propTypes = {
	sub: PropTypes.array,
	subtitle: PropTypes.string,
	subDescription: PropTypes.string,
	className: PropTypes.string,
};

export function CalendarMenu() {
	return (
		<div className={"menu-desktop__sub-list p-3"}>
			<Calendar details={{ handleClose: () => Function }} />
		</div>
	);
}

export function Default() {
	return null;
}

const menu = {
	InfoMenu,
	ImageMenu,
	CalendarMenu,
	ContentMenu,
	Default,
};
export function SubMenu({ sub = [], component, subDescription, subtitle, className }) {
	const { theme } = useThemeContext();

	return (
		<div
			className={cx("py-3 d-flex flex-column menu-desktop__sub")}
			style={removeEmptyKeys({ backgroundColor: theme.color.body })}
		>
			<div className={"container-fluid"} style={{ height: "100%" }}>
				{
					React.createElement(menu[component] || menu.Default, {
						sub,
						subtitle,
						subDescription,
						className,
					})
				}
			</div>
		</div>
	);
}

SubMenu.propTypes = {
	sub: PropTypes.array,
	component: PropTypes.string,
	subDescription: PropTypes.string,
	subtitle: PropTypes.string,
	className: PropTypes.string,
};
