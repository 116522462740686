import React, { useState, useEffect, Children, cloneElement } from "react";
import uuid from "uuid";
import PropTypes from "prop-types";
import { get } from "lodash";
import { isMobile } from "react-device-detect";

import { scriptLoader } from "../../../helpers";
import NoScript from "../../NoScript";

import "./TourBlock.scss";

function TourWrapper({ children, preview }) {
	const [show, setShow] = useState(false);

	return (
		!isMobile ? (
			<div
				className={"tour-3d d-noscript-none"}
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
				style={
					{
						backgroundImage: `url(${preview})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
					}
				}
			>
				{
					Children.map(
						children,
						child => {
							const { props } = child;

							return cloneElement(
								child,
								{
									...props,
									style: {
										height: 500,
										opacity: +show,
									},
								},
							);
						},
					)
				}
			</div>
		) : children
	);
}

TourWrapper.propTypes = {
	children: PropTypes.node,
	preview: PropTypes.string,
};

TourWrapper.defaultProps = {
	children: <></>,
	preview: "",
};

export function TourBlock({ settings, preview }) {
	const [id] = uuid();
	const [player, setPlayer] = useState(null);

	useEffect(() => {
		// First: Load player
		scriptLoader(`${get(settings, "params.base_path")}pano2vr_player.js`, () => {
			if (typeof pano2vrPlayer !== "undefined") {
				// eslint-disable-next-line
				setPlayer(new pano2vrPlayer(id));
			} else {
				throw new Error("pano2vrPlayer is undefined");
			}
		});
	}, []);

	useEffect(() => {
		// Second: Load skin
		const { params } = settings;

		scriptLoader(`${get(settings, "params.base_path")}skin.js`, () => {
			if (typeof pano2vrSkin !== "undefined" && player) {
				// eslint-disable-next-line
				new pano2vrSkin(player, get(params, "base_path", ""));

				try {
					player.readConfigUrl(get(params, "src", ""));
					player.openNext(get(params, "init_hotspot", ""));
				} catch {
					console.error("Error: pano.xml error");
				}
			}
		});

		scriptLoader(`${get(settings, "params.base_path")}3rdparty/leaflet/leaflet.js`,
			() => {
				console.info("Leaflet loaded!");
			},
			() => {
				console.error("Leaflet is not loaded!");
			},
		);

		scriptLoader(`${get(settings, "params.base_path")}webvr/three.min.js`,
			() => {
				console.info("Three.js loaded!");
			},
			() => {
				console.error("Three.js is not loaded!");
			},
		);

		scriptLoader(`${get(settings, "params.base_path")}webvr/webvr-polyfill.min.js`,
			() => {
				console.info("webvr-polyfill loaded!");
			},
			() => {
				console.error("webvr-polyfill is not loaded!");
			},
		);
	}, [player]);

	return (
		<>
			<NoScript>
				<p>Чтобы увидеть виртуальный тур, включите JavaScript</p>
			</NoScript>
			<TourWrapper preview={preview}>
				<div
					id={id}
					className={"tour-3d__container"}
					style={{ height: 500 }}
				/>
			</TourWrapper>
		</>

	);
}

TourBlock.propTypes = {
	settings: PropTypes.object.isRequired,
	preview: PropTypes.string.isRequired,
};

TourBlock.defaultProps = {
	settings: {},
	preview: "",
};
