import React from "react";
import { Badge } from "antd";
import PropTypes from "prop-types";

import { useThemeContext } from "../../../components/Layout/ThemeContext";

import "./Indicator.scss";
import { removeEmptyKeys } from "../../../helpers";

export default function BroadcastIndicator() {
	const { theme } = useThemeContext();

	return (
		<div
			key={"first"}
			style={{
				textAlign: "center",
				maxWidth: "100%",
			}}
		>
			<Badge color={"red"} status={"processing"} />
			<p className={"run-text"}><span style={removeEmptyKeys({ color: theme.color.text })}>Идет трансляция</span></p>
		</div>
	);
}

BroadcastIndicator.propTypes = {};

export function BroadcastIndicatorMobile({ enabled, onClick }) {
	return enabled ?
		<div
			onClick={onClick}
			className={"d-flex align-items-center justify-content-center broadcast-indicator-mobile"}>
			<div className={"d-flex align-items-center justify-content-center broadcast-indicator-mobile__border"}>
				<Badge color={"red"} status={"processing"} style={{ transform: "scale(1.5)" }}/>
			</div>
		</div> : <></>
	;
}

BroadcastIndicatorMobile.propTypes = {
	enabled: PropTypes.bool,
	onClick: PropTypes.func,
};

BroadcastIndicatorMobile.defaultProps = {
	enabled: false,
	onClick: () => Function,
};
