/* eslint-disable new-cap */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useVKContext } from "./context";

export function Post({ elementId, ownerId, postId, hash, options }) {
	const { Post } = useVKContext();

	useEffect(() => {
		Post(elementId, ownerId, postId, hash, options);
	}, [Post]);

	return (
		<div className={"vk-post"}>
			<div id={elementId} />
		</div>
	);
}

Post.propTypes = {
	elementId: PropTypes.string,
	ownerId: PropTypes.string,
	postId: PropTypes.string,
	hash: PropTypes.string,
	options: PropTypes.object,
};

Post.defaultProps = {
	elementId: "",
	ownerId: "",
	postId: "",
	hash: "",
	options: "",
};

Post.displayName = "VkPost";

export function Article({ elementId, articleUrl }) {
	const { Article } = useVKContext();

	useEffect(() => {
		Article(elementId, articleUrl);
	}, [Article]);

	return (
		<div className={"vk-article"}>
			<div id={elementId} />
		</div>
	);
}

Article.propTypes = {
	elementId: PropTypes.string,
	articleUrl: PropTypes.string,
};

Article.defaultProps = {
	elementId: "",
	articleUrl: "",
};

Article.displayName = "VkArticle";
