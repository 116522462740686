import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Modal, notification, Result, Tabs, Checkbox } from "antd";
import { get } from "lodash";

import RegisterTab from "./RegisterTab";
import { AuthContext } from "../../../widgets";
import { getPhoneNumberValidationRules, getEmailValidationRules } from "../../../utils";
import { useThemeContext } from "../../Layout/ThemeContext";
import { removeEmptyKeys } from "../../../helpers";

import "./SignUpForm.scss";

function SignUp({ visible, form, onClose }) {
	const { theme } = useThemeContext();
	const { signUp } = useContext(AuthContext);

	const { getFieldDecorator, getFieldValue, validateFields, resetFields } = form;
	const [loading, setLoading] = useState(false);
	const [agree, setAgree] = useState(false);
	const [result, setResult] = useState(false);
	const [confirmDirty, setConfirmDirty] = useState(false);
	const [variant, setVariant] = useState("email");
	const [isMount, setIsMount] = useState(false);

	const linkStyle = {
		textDecoration: "underline",
		color: theme.colorValue === "blackWhite" ? "#fff" : "#3f2512",
	};

	function handleSubmit(event) {
		event.preventDefault();

		validateFields((errors, body) => {
			setLoading(true);
			if (variant === "phone") {
				body = {
					...body,
					login: `+7${body.login}`,
				};
			}
			if (!errors) {
				signUp(
					JSON.stringify(body),
					({ status, data }) => {
						if (+status === 200) {
							resetFields();
							setLoading(false);
							notification.success({
								message: `Пользователь для ${body.login} создан`,
							});

							onClose();
						} else {
							if (get(data, "title")) {
								notification.error({
									message: `${data.title}`,
									description: status,
								});
							} else {
								notification.error({
									message: "Ошибка сервера",
									description: status,
								});
							}
							setLoading(false);
						}
					},
					error => {
						console.error(error);
						setLoading(false);
					},
				);
			} else {
				notification.error({
					message: "Некорректные данные формы",
				});
				setLoading(false);
			}
		});
	}

	function compareToFirstPassword(rule, value, callback) {
		if (value && value !== getFieldValue("password")) {
			callback("Введённые пароли не совпадают!");
		} else {
			callback();
		}
	}

	function validateToNextPassword(rule, value, callback) {
		if (value && confirmDirty) {
			validateFields(["passwordConfirmation"], { force: true });
		}
		callback();
	}

	function handleConfirmBlur({ target }) {
		const { value } = target;

		setConfirmDirty(confirmDirty || !!value);
	}

	function closeModal() {
		resetFields();
		onClose();
	}

	useEffect(() => {
		setIsMount(true);
	}, []);

	useEffect(() => {
		if (isMount) {
			validateFields(["login"], { force: true });
			resetFields(["login"]);
		}
	}, [variant]);

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={"Введите логин и пароль"}
				visible={visible}
				onCancel={onClose}
				centered
				width={400}
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				wrapClassName={`modal-wrap-${theme.color.key}`}
				footer={[
					<div key={"submit-group"} className={"container-fluid px-0"}>
						<div className={"row"}>
							<div className={"col-6"}>
								<Button
									block
									onClick={closeModal}
									disabled={loading}
									tabIndex={4}
									style={removeEmptyKeys({
										backgroundColor: theme.color.text,
										color: theme.color.body,
									})}
								>
									Отменить
								</Button>
							</div>
							<div className={"col-6"}>
								<Button
									block
									type={"primary"}
									htmlType={"submit"}
									onClick={handleSubmit}
									onSubmit={handleSubmit}
									disabled={!agree || loading}
									loading={loading}
									tabIndex={5}
									style={removeEmptyKeys({
										backgroundColor: theme.color.text,
										color: theme.color.body,
									})}
								>
									Регистрация
								</Button>
							</div>
						</div>
					</div>,
				]}
			>
				<Form layout={"vertical"} onSubmit={handleSubmit}>
					<div className={"row sign-up"}>
						<div className="col-12">
							<style>
								{`
								.ant-input-group-addon {
									color: white;
								}
							`}
							</style>
							<Tabs
								onChange={value => {
									setVariant(value);
								}}
								size={"small"}
								tabBarGutter={10}
								type={"card"}
								style={{ width: "100%" }}
							>
								<Tabs.TabPane
									tab={<RegisterTab isActive={variant !== "email"} label="E-mail" type="mail" />}
									key={"email"}
								>
									<Form.Item label={"Введите E-mail"}>
										{variant === "email" &&
										getFieldDecorator("login", {
											initialValue: "",
											rules: getEmailValidationRules(),
										})(<Input type={"email"} disabled={loading} autoFocus tabIndex={1}/>)}
									</Form.Item>
								</Tabs.TabPane>
								<Tabs.TabPane
									tab={<RegisterTab isActive={variant !== "phone"} label="Номер телефона" type="phone" />}
									key={"phone"}
								>
									<Form.Item label={"Введите номер телефона"}>
										{variant === "phone" &&
										getFieldDecorator("login", {
											initialValue: "",
											rules: getPhoneNumberValidationRules(),
										})(
											<Input
												type={"text"}
												disabled={loading}
												autoFocus
												tabIndex={1}
												addonBefore={"+7"}
											/>)}
									</Form.Item>
								</Tabs.TabPane>
							</Tabs>
						</div>
						<div className="col-12">
							<Form.Item label={"Введите пароль"}>
								{getFieldDecorator("password", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Введите пароль!",
										},
										{
											validator: validateToNextPassword,
										},
									],
								})(<Input.Password onBlur={handleConfirmBlur} disabled={loading} tabIndex={2}/>)}
							</Form.Item>
						</div>
						<div className="col-12">
							<Form.Item label={"Повторите пароль"} hasFeedback>
								{getFieldDecorator("password_confirmation", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Повторите пароль!",
										},
										{
											validator: compareToFirstPassword,
										},
									],
								})(<Input.Password disabled={loading} tabIndex={3}/>)}
							</Form.Item>
						</div>
						<div className="col-12">
							<Form.Item label={[
								<>
									<Checkbox
										checked={agree}
										onChange={event => setAgree(event.target.checked)}
										style={{ paddingTop: 1 }}
									/>
									<span> Я принимаю условия <a href="https://xn--80ajkqrey.xn--p1ai/pages/polzovatelskoe-soglashenie" target="blank" style={linkStyle}> Пользовательского соглашения</a> и даю своё согласие Серафим.рф на обработку моей персональной информации на условиях, определенных <a href="https://xn--80ajkqrey.xn--p1ai/pages/politika-konfidencialnosti" target="blank" style={linkStyle}>Политикой конфиденциальности.</a></span>
								</>,
							]}>
							</Form.Item>
						</div>
					</div>
					<Button
						block
						type={"primary"}
						htmlType={"submit"}
						onSubmit={handleSubmit}
						tabIndex={-1}
						disabled={loading}
						loading={loading}
						style={{
							opacity: "0",
							position: "absolute",
						}}
					>
						Регистрация
					</Button>
				</Form>
				<Modal visible={result} onCancel={() => setResult(false)} footer={null} centered>
					<Result
						status="success"
						title={`Спасибо! Данные для входа отправлены вам на указанный ${
							variant === "email" ? "E-mail" : "Номер телефона"
						}!`}
					/>
				</Modal>
			</Modal>
		</>
	);
}

SignUp.propTypes = {
	visible: PropTypes.bool,
	form: PropTypes.object,
	onClose: PropTypes.func,
};

SignUp.defaultProps = {
	visible: false,
	form: {},
	onClose: () => Function,
};

export const SignUpForm = Form.create({ name: "sign-up" })(SignUp);
