import { createContext } from "react";
import createClient from "../../../apollo/client";
import { restorePassword, signCookie, signOut, signIn, signUp, parseError, getToken } from "../Provider";

const AuthContext = createContext(
	{
		updateClient: () => Function,
		client: createClient(),
		user: { isLogin: false },
		restorePassword,
		signCookie,
		signOut,
		signIn,
		signUp,
		parseError,
		getToken,
	},
);

AuthContext.displayName = "AuthContext";

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
export default AuthContext;
