import { createContext } from "react";

export const siteMetadata = {};

export const SiteMetadataContext = createContext(
	{
		siteMetadata,
		globalSettings: {},
	},
);

SiteMetadataContext.displayName = "SiteMetadataContext";

export const SiteMetadataProvider = SiteMetadataContext.Provider;
export const SiteMetadataConsumer = SiteMetadataContext.Consumer;
export default SiteMetadataContext;
