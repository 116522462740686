/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Popover } from "antd";
import { OKShareButton, VKShareButton, TelegramShareButton } from "react-share";
import cx from "classnames";
import { get } from "lodash";

import { formatURL, removeEmptyKeys } from "../../helpers";
import { Icon } from "../../components/Media";
import { SiteMetadataContext } from "../index";
import { useThemeContext } from "../../components/Layout/ThemeContext";

import "./Share.scss";

export function Share({ url, pageTitleShort, pageTitleFull, imageSrc }) {
	const { theme } = useThemeContext();
	const { siteMetadata } = useContext(SiteMetadataContext);

	let share = useRef(null);

	const [active, setActive] = useState(false);
	const [opened, setOpened] = useState(false);

	function mouseLeave() {
		if (!opened) {
			setActive(false);
		}
	}

	function changeActive(event) {
		event.stopPropagation();
		if (share.current && !share.current.contains(event.target)) {
			setActive(false);
			setOpened(false);
		}
	}

	useEffect(() => {
		document.addEventListener("click", changeActive);

		return () => document.removeEventListener("click", changeActive);
	}, []);

	const cyrillicUrl = formatURL(url, get(siteMetadata, "url", process.env.REACT_APP_BASE_FRONT_URL_CYRILLIC));

	const content = (
		<div className={"share-btns d-flex"} style={removeEmptyKeys({
			backgroundColor: theme.color.bosy,
		})}>
			<VKShareButton url={cyrillicUrl} title={pageTitleShort || pageTitleFull} image={imageSrc} className={"d-flex link"}>
				<Icon id={"vk"} />
			</VKShareButton>
			<TelegramShareButton url={cyrillicUrl} title={pageTitleShort || pageTitleFull} image={imageSrc} className={"d-flex link"}>
				<Icon id={"tg"} />
			</TelegramShareButton>
			<OKShareButton url={cyrillicUrl} title={pageTitleShort || pageTitleFull} image={imageSrc} className={"d-flex link"}>
				<Icon id={"classmate"} />
			</OKShareButton>
		</div>
	);

	return (
		<>
			<div
				ref={share}
				onMouseLeave={mouseLeave}
				onMouseEnter={() => setActive(true)}
				className={cx("p-2 serafim-share", { active })}
				style={removeEmptyKeys({ textAlign: "right",
					backgroundColor: theme.color.body,
					margin: "20px 0" })}
			>
				<a
					href={"https://vk.com/serafimova_zemlya"}
					rel={"noopener noreferrer"}
					target={"_blank"}
					className={"link serafim-share__link"}
					title={"Мы в ВК"}
				>
					<Icon id={"vk"} />
				</a>
				<a
					href={"https://ok.ru/group/62379664998619"}
					rel={"noopener noreferrer"}
					target={"_blank"}
					className={"link serafim-share__link"}
					title={"Мы в Одноклассниках"}
				>
					<Icon id={"ok"} />
				</a>
				<a
					href={"https://t.me/serafimrf"}
					rel={"noopener noreferrer"}
					target={"_blank"}
					className={"link serafim-share__link"}
					title={"Наш канал в Телеграм"}
				>
					<Icon id={"tg"} />
				</a>
				<a
					href={"https://www.youtube.com/channel/UC5LJ3nRpt2fgw-qDcIRyVYA/"}
					rel={"noopener noreferrer"}
					target={"_blank"}
					className={"link serafim-share__link"}
					title={"Мы в Youtube"}
				>
					<Icon id={"youtube"} />
				</a>
				<Popover placement={"bottomRight"} content={content} trigger={"click"} overlayStyle={{ padding: 8,
					position: "fixed" }}>
					<a
						href={"#share"}
						className={"link serafim-share__link"}
						onClick={() => {
							setActive(true);
							setOpened(true);
						}}
						title={"Поделиться страницей в соц. сетях"}
					>
						<Icon id={"share"} />
					</a>
				</Popover>
			</div>
		</>
	);
}

Share.propTypes = {
	pageTitleShort: PropTypes.string,
	pageTitleFull: PropTypes.string,
	imageSrc: PropTypes.string,
	url: PropTypes.string,
};
