import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import FooterMenu from "../../Menu/FooterMenu";
import { formatURL, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../ThemeContext";
import NoScript from "../../NoScript";

import "./footer.scss";

export default function Footer({ menu, className }) {
	const { theme } = useThemeContext();

	const fontStyle = removeEmptyKeys({
		fontFamily: theme.fontFamily,
		fontSize: theme.fontSize.text,
		lineHeight: theme.lineHeight.text,
		color: theme.color.text,
	});

	return (
		<footer
			className={`footer ${className}`}
			style={removeEmptyKeys({
				backgroundColor: theme.color.body,
			})}
		>
			<FooterMenu items={menu} />
			<div className={"container"}>
				<div className={"d-flex flex-column flex-md-row footer__copyright"}>
					<Link
						to={formatURL("/pages/politika-konfidencialnosti")}
						className={"link_light footer__link"}
						style={fontStyle}
					>
						Политика конфиденциальности
					</Link>
					<Link
						to={formatURL("/pages/polzovatelskoe-soglashenie")}
						className={"link_light footer__link"}
						style={fontStyle}
					>
						Пользовательское соглашение
					</Link>
					<Link
						to={formatURL("/pages/pravila-polzovaniya-cookie")}
						className={"link_light footer__link"}
						style={fontStyle}
					>
						Правила использования Cookies
					</Link>
				</div>
			</div>
			<div
				className={"container link_light pb-3"}
				style={fontStyle}
			>
				Использовать материалы сайта без разрешения редакции запрещено.
				При полном или частичном использовании материалов сайта активная гиперссылка на серафим.рф обязательна.
			</div>
			<NoScript>
				<img
					src="https://vk.com/rtrg?p=VK-RTRG-460824-dC01e"
					style={{ position: "fixed",
						left: -999 }}
					alt=""
				/>
			</NoScript>
		</footer>
	);
}

Footer.propTypes = {
	menu: PropTypes.array,
	className: PropTypes.string,
};

Footer.defaultProps = {
	menu: [],
	className: "",
};
