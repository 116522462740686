/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { isMobile } from "react-device-detect";
import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import PropTypes from "prop-types";
import cx from "classnames";

import { Icon } from "../Media";
import * as Slides from "../Carousel/Slide";

import "./Carousel.scss";

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
}

const slideRatioByOrientation = {
	"Landscape": [2, 1],
	"Portrait": [3, 4],
	"Square": [1, 1],
	"default": [1, 0.85],
};

export function PureCarousel({ type, mediaSize, slideRatio, params, items, visibleSlides, slideRatioMobile, className }) {
	const totalSlides = items.length;

	const { width: windowWidth = 0 } = useWindowSize();
	const [visibleCount, settVisibleCount] = useState(visibleSlides);

	const breakpoints = get(params, "breakpoints", []);

	useEffect(() => {
		breakpoints.every((point, idx) => {
			if (+get(point, "width", 0) < +windowWidth && (breakpoints.length - 1 === idx || get(breakpoints, `[${idx + 1}].width`, 10000) >= +windowWidth)) {
				settVisibleCount(get(point, "slidesPerView", visibleCount));

				return false;
			}

			return true;
		});
	}, [windowWidth]);

	function handleOpen(idx) {
		const pswp = document.getElementById("pswp-element");
		const popup = get(params, "popup", false);

		if (popup && pswp && totalSlides) {
			const images = items.map(({ src, h = 0, w = 0, title }) => {
				return {
					src,
					title,
					h,
					w,
				};
			});

			const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, images, {
				mouseUsed: true,
				bgOpacity: 0.9,
				shareEl: false,
				history: false, // IMPORTANT SET TO FALSE !!!
				index: idx,
			});

			gallery.listen("gettingData", (index, item) => {
				if (item.w < 1 || item.h < 1) {
				// unknown size
					let img = new Image();
					img.onload = function () {
					// will get size after load
						item.w = this.width; // set image width
						item.h = this.height; // set image height
						gallery.invalidateCurrItems(); // reinit Items
						gallery.updateSize(true); // reinit Items
					};
					img.src = item.src; // let's download image
				}
			});

			let prevIndex;

			gallery.listen("afterChange", () => {
				const currentIndex = gallery.getCurrentIndex();
				if (prevIndex !== currentIndex) {
					prevIndex = currentIndex;

					const hash = get(items, `[${prevIndex}].id`, "");

					if (hash) {
						location.hash = hash;
					}
				}
			});

			gallery.listen("close", () => {
				location.hash = "";
			});

			gallery.init();
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			const imgHash = get(location, "hash", "").slice(1);

			const imageIndex = items.findIndex(item => item.id === imgHash);

			if (imageIndex >= 0) {
				handleOpen(imageIndex);
			}
		}
	}, []);

	const btnStyle = get(params, "btnStyle");
	const showButton = totalSlides > visibleCount;
	const infinite = get(params, "infinite", true);
	const isSwipeAble = visibleCount < totalSlides;

	let naturalSlideWidth = 1;
	let naturalSlideHeight = 1;

	if (type === "SlideImage") {
		if (Object.keys(slideRatio).length) {
			naturalSlideWidth = get(slideRatio, "naturalSlideWidth", 2);
			naturalSlideHeight = get(slideRatio, "naturalSlideHeight", 3);
		} else {
			[naturalSlideWidth, naturalSlideHeight] = slideRatioByOrientation[get(params, "orientation", "default")];
		}
	} else {
		naturalSlideWidth = isMobile ? get(slideRatioMobile, "naturalSlideWidth", 1) : get(slideRatio, "naturalSlideWidth", 2);
		naturalSlideHeight = isMobile ? get(slideRatioMobile, "naturalSlideHeight", 1) : get(slideRatio, "naturalSlideHeight", 3);
	}

	return totalSlides ? (
		<>
			<CarouselProvider
				naturalSlideWidth={naturalSlideWidth}
				naturalSlideHeight={naturalSlideHeight}
				totalSlides={totalSlides}
				visibleSlides={visibleCount}
				infinite={infinite}
				touchEnabled={isSwipeAble}
				dragEnabled={isSwipeAble}
				className={cx("carousel__previews", className)}
			>
				<div className="carousel__container">
					<CarouseSlider
						items={items}
						type={type}
						mediaSize={mediaSize}
						onClick={handleOpen}
					/>
					{
						showButton && (
							<CarouselButtons btnStyle={btnStyle} />
						)
					}
				</div>
			</CarouselProvider>
		</>
	) : null;
}

PureCarousel.propTypes = {
	items: PropTypes.array,
	visibleSlides: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	params: PropTypes.object,
	type: PropTypes.string,
	mediaSize: PropTypes.array,
	slideRatio: PropTypes.object,
	slideRatioMobile: PropTypes.object,
	className: PropTypes.string,
};

PureCarousel.defaultProps = {
	items: [],
	visibleSlides: 3,
	params: {},
	type: "SlideNews",
	mediaSize: ["100%", "100%"],
	slideRatio: {},
	slideRatioMobile: {},
	className: "",
};

function CarouseSlider({ items, onClick, type, mediaSize }) {
	return (
		<Slider>
			{
				items.map((slide, idx) => {
					const src = get(slide, "src", "");

					if (src) {
						return (
							<Slide
								index={idx}
								key={`slide-item-${idx}`}
							>
								{React.createElement(Slides[type],
									{
										...slide,
										mediaSize,
										onClick: () => onClick(idx),
									},
								)}
							</Slide>
						);
					}

					return null;
				})
			}
		</Slider>
	);
}

CarouseSlider.propTypes = {
	items: PropTypes.array,
	onClick: PropTypes.func,
	type: PropTypes.string,
	mediaSize: PropTypes.array,
};

CarouseSlider.defaultProps = {
	items: [],
	onClick: () => Function,
	type: "SlideNews",
	mediaSize: ["100%", 300],
};

function CarouselButtons({ onPrevClick, onNextClick, btnStyle }) {
	return (
		<div
			className={"tns-controls"}
			style={btnStyle}
		>
			<ButtonBack
				className={"tns-controls__arrows__prev"}
				onClick={() => onPrevClick}
			>
				<Icon
					id={"dome-arrow"}
					className={"dome-arrow"}
				/>
				<Icon
					id={"chevron"}
					className={"tns-controls__arrows__chevron tns-controls__arrows__prev__chevron"}
					style={{ transform: "rotate(180deg)" }}
				/>
			</ButtonBack>
			<ButtonNext
				className="tns-controls__arrows__next"
				onClick={() => onNextClick}
			>
				<Icon
					id={"dome-arrow"}
					className={"dome-arrow"}
				/>
				<Icon
					id={"chevron"}
					className={"tns-controls__arrows__chevron tns-controls__arrows__next__chevron"}
				/>
			</ButtonNext>
		</div>
	);
}

CarouselButtons.propTypes = {
	onPrevClick: PropTypes.func,
	onNextClick: PropTypes.func,
	btnStyle: PropTypes.object,
};

CarouselButtons.defaultProps = {
	onPrevClick: () => Function,
	onNextClick: () => Function,
	btnStyle: { top: "50%" },
};
