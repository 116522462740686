import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { formatURL } from "../../helpers";
import "./Logo.scss";

export default function Logo({ link, caption, icon, style, className }) {
	return (
		<Link to={formatURL(link)} className={`d-flex align-items-center link logo ${className}`} style={style}>
			{icon}
			{caption}
		</Link>
	);
}

Logo.propTypes = {
	link: PropTypes.string,
	caption: PropTypes.node,
	icon: PropTypes.node,
	style: PropTypes.object,
	className: PropTypes.string,
};

Logo.defaultProps = {
	link: "/",
	caption: "",
	icon: <></>,
	style: {},
	className: "",
};
