import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import jwtDecode from "jwt-decode";

import { AuthProvider } from "../Context";
import createClient, { alternateClient } from "../../../apollo/client";

export function restorePassword(body = {}, onSuccess = () => Function, onError = () => Function) {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");

	const request = new Request(`${process.env.GATSBY_API_ENDPOINT}/api/passwords/reset`, {
		method: "POST",
		headers,
		body,
		mode: "cors",
		credentials: "include",
		redirect: "follow",
	});

	fetch(request)
		.then(onSuccess)
		.catch(onError);
}

export function getToken(onSuccess = () => Function, onError = () => Function) {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");

	const request = new Request(`${process.env.GATSBY_API_ENDPOINT}/api/auth`, {
		method: "GET",
		headers,
		mode: "cors",
		credentials: "include",
		redirect: "follow",
	});

	fetch(request)
		.then(response => response.json())
		.then(data => onSuccess(data))
		.catch(error => onError(error));
}

export function signCookie(onSuccess = () => Function, onError = () => Function) {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");

	const request = new Request(`${process.env.GATSBY_API_ENDPOINT}/api/auth/signin`, {
		method: "GET",
		headers,
		mode: "cors",
		credentials: "include",
		redirect: "follow",
	});

	fetch(request)
		.then(onSuccess)
		.catch(error => onError(error));
}

export function signOut(onSuccess = () => Function, onError = () => Function) {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	// 10.0.1.32:3000
	const request = new Request(`${process.env.GATSBY_API_ENDPOINT}/api/auth`, {
		method: "DELETE",
		headers,
		mode: "cors",
		credentials: "include",
		redirect: "follow",
	});

	fetch(request)
		.then(onSuccess)
		.catch(error => onError(error));
}

export function signIn(body = {}, onSuccess = () => Function, onError = () => Function) {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");

	const request = new Request(`${process.env.GATSBY_API_ENDPOINT}/api/auth/signin`, {
		method: "POST",
		headers,
		body,
		mode: "cors",
		credentials: "include",
		redirect: "follow",
	});

	fetch(request)
		.then(response => response.json())
		.then(data => onSuccess(data))
		.catch(error => onError(error));
}

export function signUp(body = {}, onSuccess = () => Function, onError = () => Function) {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");

	const request = new Request(`${process.env.GATSBY_API_ENDPOINT}/api/auth/signup`, {
		method: "POST",
		headers,
		body,
		mode: "cors",
		credentials: "include",
		redirect: "follow",
	});

	fetch(request)
		.then(response => response.json())
		.then(data => onSuccess(data))
		.catch(error => onError(error));
}

export function parseError(error = {}) {
	return Object.entries(error).reduce((result, item) => {
		return result = `${result} \n ${get(item, "[1]", []).join("")}`;
	}, "");
}

export default function Provider({ ssrClient, children }) {
	function makeUserObject() {
		if (typeof window !== "undefined" && localStorage.getItem("serafim_token")) {
			return Object.assign(
				{},
				{ isLogin: true },
				get(jwtDecode(localStorage.getItem("serafim_token")), "user", {}),
			);
		}

		return { isLogin: false };
	}

	const [client, setClient] = useState(ssrClient ? alternateClient() : createClient());
	const [user, setUser] = useState(makeUserObject());

	function updateClient() {
		setClient(createClient());
		setUser(makeUserObject());
	}

	return (
		<AuthProvider
			value={
				{
					client,
					updateClient,
					user,
					restorePassword,
					signCookie,
					signOut,
					signIn,
					signUp,
					parseError,
					getToken,
				}
			}
		>
			{children}
		</AuthProvider>
	);
}

Provider.propTypes = {
	ssrClient: PropTypes.bool,
	children: PropTypes.array,
};

Provider.defaultProps = {
	ssrClient: false,
	children: [],
};
