import { createContext } from "react";

export const AB = {};

export const ABContext = createContext(
	{
		AB,
	},
);

ABContext.displayName = "ABContext";

export const ABProvider = ABContext.Provider;
export const ABConsumer = ABContext.Consumer;
export default ABContext;
