import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import Swiper from "./swiper";
import * as Slides from "./Slide";
import { Icon } from "../Media";
import { translit } from "../../helpers";

import "swiper/swiper.scss";

import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

// doc: https://codesandbox.io/s/reactidswiper-demo-d80gg
export function Carousel({ type, className, mediaSize, popup, items, params, children, getCarousel, displayArrow, clickElement, date }) {
	const [swiper, updateSwiper] = useState(null);
	const [, updateCurrentIndex] = useState(0);
	let prev = useRef(null);
	let next = useRef(null);

	function goNext() {
		if (swiper !== null) {
			swiper.slideNext();
		}
	}

	function goPrev() {
		if (swiper !== null) {
			swiper.slidePrev();
		}
	}
	function handleOpen(idx) {
		const pswp = document.getElementById("pswp-element");

		if (popup && pswp && items.length) {
			const images = items.map(({ src, h = 0, w = 0, title }) => {
				return {
					src,
					title,
					h,
					w,
				};
			});

			const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, images, {
				mouseUsed: true,
				bgOpacity: 0.9,
				shareEl: false,
				history: false, // IMPORTANT SET TO FALSE !!!
				index: idx,
			});

			gallery.listen("gettingData", (index, item) => {
				if (item.w < 1 || item.h < 1) {
					// unknown size
					let img = new Image();
					img.onload = function () {
						// will get size after load
						item.w = this.width; // set image width
						item.h = this.height; // set image height
						gallery.invalidateCurrItems(); // reinit Items
						gallery.updateSize(true); // reinit Items
					};
					img.src = item.src; // let's download image
				}
			});

			gallery.init();
		}
	}

	const renderItem = useCallback(
		({ src, alt, title, path, eventUrl, date, place, name, desc, responsive, actualDate }, idx, mediaSize, type, published_at) =>
			React.createElement(Slides[type] || Slides.Slide, {
				key: `carousel__slide-${idx}-${type}-${translit(title)}`,
				src,
				alt,
				title,
				path,
				eventUrl,
				date,
				place,
				name,
				desc,
				mediaSize,
				idx,
				onClick: () => handleOpen(idx),
				responsive,
				clickElement,
				actualDate,
				published_at,
			}),
		[],
	);

	const updateIndex = useCallback(() => {
		updateCurrentIndex(swiper.realIndex);
	}, [swiper]);

	useEffect(() => {
		if (swiper !== null) {
			swiper.on("slideChange", updateIndex);
			if (getCarousel) {
				getCarousel(swiper);
			}
		}

		return () => {
			if (swiper !== null) {
				swiper.off("slideChange", updateIndex);
			}
		};
	}, [swiper, updateIndex]);

	const [, height] = mediaSize;

	return (
		<div className={`${className}`} style={{ position: "relative" }}>
			<Swiper
				params={Object.assign(
					{},
					{
						spaceBetween: 30,
						loop: params.slidesPerView ? items.length > +params.slidesPerView : true,
						allowTouchMove: false,
						getSwiper: updateSwiper,
						preloadImages: true,
						preventClicksPropagation: false,
						lazy: true,
						breakpoints: {
							0: {
								allowTouchMove: true,
							},
							768: {
								allowTouchMove: false,
							},
						},
					},
					params,
				)}
			>
				{children ? children : items.map((item, idx) => renderItem(item, idx, mediaSize, type, date))}
			</Swiper>
			{!!items.length &&
				items.length > 1 &&
				(params.slidesPerView ?
					!isMobile ? items.length > +params.slidesPerView : params.breakpoints && items.length > +params.breakpoints[0].slidesPerView :
					true) &&
			(
				<div className={"tns-controls"} style={{ top: "50%" }}>
					<button
						className={cx("tns-controls__arrows__prev", { "arrow-display-none": displayArrow })}
						onClick={goPrev} ref={prev}>
						<Icon id={"dome-arrow"} className={"dome-arrow"} />
						<Icon
							id={"chevron"}
							className={"tns-controls__arrows__chevron tns-controls__arrows__prev__chevron"}
							style={{ transform: "rotate(180deg)" }}
						/>
					</button>
					<button className="tns-controls__arrows__next" onClick={goNext} ref={next}>
						<Icon id={"dome-arrow"} className={"dome-arrow"}/>
						<Icon
							id={"chevron"}
							className={"tns-controls__arrows__chevron tns-controls__arrows__next__chevron"}
						/>
					</button>
				</div>
			)}
		</div>
	);
}

Carousel.propTypes = {
	items: PropTypes.array,
	type: PropTypes.string,
	className: PropTypes.string,
	oneHeight: PropTypes.bool,
	isGallery: PropTypes.bool,
	config: PropTypes.object,
	notAuto: PropTypes.bool,
	mediaSize: PropTypes.array,
	noUpdate: PropTypes.bool,
	popup: PropTypes.bool,
	params: PropTypes.object,
	children: PropTypes.array,
	getCarousel: PropTypes.func,
	displayArrow: PropTypes.bool,
	clickElement: PropTypes.func,
	date: PropTypes.string,
};

Carousel.defaultProps = {
	items: [],
	type: "Slide",
	className: "",
	oneHeight: false,
	isGallery: false,
	config: {},
	mediaSize: [300, 400],
	noUpdate: false,
	popup: false,
	params: {},
	chilldren: [],
	getCarousel: () => Function,
	displayArrow: false,
	clickElement: () => Function,
	date: "",
};
