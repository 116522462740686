import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useThemeContext } from "../../Layout/ThemeContext";

import "./Burger.scss";
import { removeEmptyKeys } from "../../../helpers";

export function Burger({ onClick, isOpen }) {
	const { theme } = useThemeContext();

	return (
		<a
			href={"/"}
			className={cx("d-flex align-items-center justify-content-center burger", { "burger_is-opened": isOpen })}
			onClick={onClick}
			style={removeEmptyKeys({
				backgroundColor: theme.color.body,
			})}
		>
			<div className={"d-flex flex-column burger__block"}>
				<span className={`burger__item burger__item_${theme.color.key}`} />
				<span className={`burger__item burger__item_${theme.color.key}`} />
				<span className={`burger__item burger__item_${theme.color.key}`} />
			</div>
		</a>
	);
}

Burger.propTypes = {
	onClick: PropTypes.func,
	isOpen: PropTypes.bool,
};

Burger.defaultProps = {
	onClick: () => {},
	isOpen: false,
};

export function DotBurger({ onClick, isOpen }) {
	const { theme } = useThemeContext();

	return (
		<a
			href={"/"}
			className={cx("d-flex align-items-center justify-content-center dot-burger", { "dot-burger_is-opened": isOpen })}
			onClick={onClick}
			style={removeEmptyKeys({
				backgroundColor: theme.color.body,
			})}
		>
			<div className={"d-flex flex-column"}>
				<span className={`dot-burger__item dot-burger__item-first dot-burger_${theme.color.key}`} />
				<span className={`dot-burger__item dot-burger__item-second dot-burger_${theme.color.key}`} />
				<span className={`dot-burger__item dot-burger__item-third dot-burger_${theme.color.key}`} />
			</div>
		</a>
	);
}

DotBurger.propTypes = {
	onClick: PropTypes.func,
	isOpen: PropTypes.bool,
};

DotBurger.defaultProps = {
	onClick: () => {},
	isOpen: false,
};

