import React from "react";
import PropTypes from "prop-types";
import { Location } from "@reach/router";

import { MobileMenu, DesktopMenu, TopMenu } from "../../Menu";

import "./Header.scss";

export default function Header({ menu, slug, abTest }) {
	return (
		<Location>
			{({ location }) => (
				<header className={"header"} itemScope itemType="http://schema.org/WPHeader">
					<DesktopMenu links={menu} location={location} />
					<TopMenu items={menu} slug={slug} abTest={abTest} location={location}/>
					<MobileMenu links={menu} location={location} />
				</header>
			)}
		</Location>
	);
}

Header.propTypes = {
	menu: PropTypes.array,
	slug: PropTypes.string,
	abTest: PropTypes.array,
};

Header.defaultProps = {
	menu: [],
};
