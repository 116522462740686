import React, { useState, useEffect, useRef } from "react";
import { Popover, Input } from "antd";
import { navigate } from "gatsby";
import { useThrottleFn, useToggle, useDebounceFn } from "@umijs/hooks";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { get, trim, has } from "lodash";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";
import cx from "classnames";

import { useThemeContext } from "../../components/Layout/ThemeContext";
import { Icon } from "../../components/Media";
import { makeUrl, removeEmptyKeys } from "../../helpers";
import { GoBack } from "../../components/Buttons";
import { MetaPosition } from "../../components/Meta";

import "./Search.scss";

const SEARCH_QUERY = gql`
	query searchData( $queryString: String!) {
		searcheableContents(query: $queryString) {
			slug
			type
			pageTitleShort
			teaser
			pageTitleFull
			mainImage
			mainImagePreview
			categorySlug
			issuedAt
		}
	}
`;

export function SearchWidget({ mobileComponent, onClick, goBack }) {
	const { theme } = useThemeContext();
	const input = useRef();

	const { state: isOpen, toggle: setIsOpen } = useToggle();
	const [value, setValue] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [results, setResults] = useState([]);

	const [loadSearchResults, { called, loading, error, data: searchedData }] = useLazyQuery(
		SEARCH_QUERY,
		{
			variables: {
				queryString: searchValue,
				types: ["news, media_article"],
			},
		},
	);

	const { run: setFocus } = useDebounceFn(() => {
		if (input.current) {
			input.current.input.focus();
		}
	}, [isOpen], 500);

	function sortBy() {
		return (a, b) => {
			return a.issuedAt > b.issuedAt ? -1 : 1;
		};
	}

	const contentTypes = {
		news: "Новости",
		media_article: "Статьи",
	};

	useThrottleFn(() => {
		setSearchValue(value);
	}, [value], 100);

	useEffect(() => {
		if (searchValue.length > 2) {
			loadSearchResults();
		}
	}, [searchValue]);

	useEffect(() => {
		if (called && searchedData && !error) {
			const { news, media_article } = get(searchedData, "searcheableContents", []).reduce((result, item) => {
				const { type } = item;

				if (has(result, type)) {
					result[type].push(item);
				}

				return result;
			}, {
				news: [],
				media_article: [],
			});

			const sorted_content = [...news.sort(sortBy()), ...media_article.sort(sortBy())];
			setResults(sorted_content);
		}
	}, [loading]);

	function makeUrlByType(item) {
		const slug = get(item, "slug", "");
		const type = get(item, "type", "");
		const category = { slug: get(item, "categorySlug", "events") };

		const makeURI = {
			news: makeUrl.news(
				{
					slug,
					category,
				},
			),
			media_article: makeUrl.mediaArticle({ slug }),
		};

		return makeURI[type];
	}

	function handleVisibleChange(visible) {
		setFocus();
		setIsOpen(visible);
	}

	function onHandleCLick(item) {
		onClick();
		setIsOpen(false);
		navigate(makeUrlByType(item));
	}

	const searchWords = value.split(" ");

	function searchBlock() {
		return (
			<>
				<Input
					ref={input}
					placeholder={"Введите запрос"}
					className={cx(`search__input search__input_${theme.color.key}`, { search__input_mobile: mobileComponent })}
					onChange={event => setValue(event.target.value)}
				/>

				{value.length > 0 &&
						<div style={Object.assign({}, {
							overflow: "hidden",
							padding: "16px 0",
							backgroundColor: theme.color.body || "#fff",
							width: "100%",
							position: mobileComponent ? "absolute" : "inherit",
							top: mobileComponent ? 61 : "inherit",
						}, removeEmptyKeys({
							fontFamily: theme.fontFamily,
							color: theme.color.text,
						}))}
						>
							<div className={`search-popover-result search-popover-result_${theme.color.key}`}>
								{results.length ?
									results.map((item, idx) => {
										const showType = get(item, "type", "") !== get(results, `[${idx - 1}].type`, "");
										const imgSrc = get(JSON.parse(get(item, "mainImage", "")), "src", JSON.parse(get(item, "mainImagePreview"), "src", ""));

										return (
											<React.Fragment key={`result-${idx}`}>
												{ showType && <div className={"search-popover-result__type"}>
													{contentTypes[get(item, "type", "")]}
												</div>
												}
												<div className={"search-popover-result__item"}>
													<div
														onClick={() => onHandleCLick(item) }
														style={{ cursor: "pointer" }}
														className={"d-flex justify-content-between"}
													>
														{
															imgSrc &&
															<div className={`${mobileComponent ? "col-xs-4 col-sm-4" : "col-2"}`}>
																<img src={imgSrc} className={"search-popover-result__image"}/>
															</div>
														}
														<div className={`${mobileComponent ? "col-xs-8 col-sm-8" : "col-4"} search-popover-result__title`}>
															<Highlighter
																highlightClassName="search-popover-result__hightlight"
																searchWords={searchWords}
																autoEscape={true}
																textToHighlight={get(item, "pageTitleShort", "")}
															/>
														</div>
														{
															!mobileComponent && <div className={"col-6 search-popover-result__teaser"}>
																<Highlighter
																	highlightClassName="search-popover-result__hightlight"
																	searchWords={searchWords}
																	autoEscape={true}
																	textToHighlight={`${trim(get(item, "teaser", "").substring(0, 150))} ...`}
																/>
															</div>
														}
													</div>
												</div>
											</React.Fragment>
										);
									})
									:
									<div className={"p-3"}>
										{`Не найдено результатов по запросу: ${searchValue}`}
									</div>
								}
							</div>
						</div>
				}
			</>
		);
	}

	return (
		mobileComponent ?
			<div className={"search_mobile"}>
				<div className={"d-flex align-items-center search__header"}>
					<div onClick={goBack} className={"search__btn-back"}>
						<GoBack />
					</div>
					{ searchBlock() }
				</div>
			</div>
			:

			<Popover
				trigger={"click"}
				placement={"bottomRight"}
				overlayClassName={"search"}
				overlayStyle={
					{
						width: "800px",
						position: "fixed",
					}
				}
				visible={isOpen}
				onVisibleChange={handleVisibleChange}
				content={ searchBlock()}
			>
				<span className={"d-flex align-items-center justify-content-center link link_ugly link_main-light"} style={{ width: 60 }}>
					<Icon
						id="iconmonstr-search-thin"
						className={"search-popover__icon"}
					/>
					<MetaPosition content={0} />
				</span>
			</Popover>
	);
}

SearchWidget.propTypes = {
	mobileComponent: PropTypes.bool,
	onClick: PropTypes.func,
	goBack: PropTypes.func,
};

SearchWidget.defaultProps = {
	mobileComponent: false,
	onClick: () => Function,
	goBack: () => Function,
};
