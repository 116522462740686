export const theme = {
	fontFamily: "",
	fontFamilyValue: "",
	image: {
		filter: "none",
		opacity: 1,
	},
	imageValue: "",
	fontSize: {
		h1: "",
		h2: "",
		h3: "",
		h4: "",
		text: "",
	},
	fontSizeValue: "default",
	lineHeight: {
		h1: "",
		h2: "",
		h3: "",
		h4: "",
		text: "",
	},
	lineHeightValue: "default",
	color: {
		textBlock: "",
		body: "",
		text: "",
		button: "",
	},
	colorValue: "",
};

export const color = {
	"blackWhite": {
		textBlock: "#fff",
		body: "#000",
		text: "#fff",
		button: "#000",
		key: "blackWhite",
	},
	"whiteBlack": {
		textBlock: "#000",
		body: "#fff",
		text: "#000",
		button: "#fff",
		key: "whiteBlack",
	},
	"blue": {
		textBlock: "#9dd1ff",
		body: "#9dd1ff",
		text: "#063462",
		button: "#9dd1ff",
		key: "blue",
	},
	"default": {
		textBlock: "",
		body: "",
		text: "",
		button: "",
	},
	get(type) {
		return this[type] || this.default;
	},
};

export const fontSize = {
	"middle": {
		h1: "3.5rem",
		h2: "3.05rem",
		h3: "2.55rem",
		h4: "2.05rem",
		text: "1.55rem",
	},
	"large": {
		h1: "4rem",
		h2: "3.55rem",
		h3: "3rem",
		h4: "2.55rem",
		text: "2.05rem",
	},
	"default": {
		h1: "",
		h2: "",
		h3: "",
		h4: "",
		text: "",
	},
	get(type) {
		return this[type] || this.default;
	},
};

export const lineHeight = {
	"middle": {
		h1: "5.05rem",
		h2: "3.55rem",
		h3: "3.05rem",
		h4: "2.55rem",
		text: "2rem",
	},
	"large": {
		h1: "5.55rem",
		h2: "4.05rem",
		h3: "3.55rem",
		h4: "3.05rem",
		text: "2.55rem",
	},
	"default": {
		h1: "",
		h2: "",
		h3: "",
		h4: "",
		text: "",
	},
	get(type) {
		return this[type] || this.default;
	},
};

export const imageFilter = {
	"gray": { filter: "grayscale(100%)",
		opacity: "" },
	"off": { filter: "",
		opacity: 0 },
	"default": { filter: "",
		opacity: "" },
	get(type) {
		return this[type] || this.default;
	},
};
