import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";
import { Typography, Radio, Popover, Dropdown, Menu, Icon as AntIcon, notification } from "antd";
import PropTypes from "prop-types";
import { get } from "lodash";

import Logo from "../../Logo";
import Caption from "../../Caption";
import NoScript from "../../NoScript";
import NoScriptTopMenu from "../NoScriptTopMenu";
import { Icon } from "../../Media";
import { RadioWidget, BroadcastWidget, SearchWidget, AuthContext } from "../../../widgets";
import { AuthForm, SignUpForm } from "../../auth";
import { MetaPosition } from "../../Meta";
import { useThemeContext } from "../../Layout/ThemeContext";
import { ymNumber } from "../../../constants";

import "./TopMenu.scss";
import { removeEmptyKeys } from "../../../helpers";

const { Title } = Typography;

export function TopMenu({ slug, items, location }) {
	// NOTE: uncomment when there will be such a task

	const { theme, changeFontFamily, changeImage, changeFontSize, changeLineHeight, changeBgColor } = useThemeContext();
	const { updateClient, user, signOut } = useContext(AuthContext);

	const [auth, setAuth] = useState(false);
	const [signUp, setSignUp] = useState(false);
	const isLogin = get(user, "isLogin", false);
	const avatar = get(user, "avatar", null);

	function logOut() {
		signOut(
			() => {
				localStorage.removeItem("serafim_token");
				updateClient();
				notification.info({
					message: "Выход успешно выполнен",
				});
			},
			error => console.error(error),
		);
	}

	function menu() {
		return (
			<Menu style={removeEmptyKeys({ backgroundColor: theme.color.body })}>
				{ !isLogin &&
					[
						<Menu.Item key={"donation"}>
							<Link
								to={"/profile"}
								style={removeEmptyKeys({ color: theme.color.text })}
							>
								<AntIcon type={"wallet"} style={{ marginRight: 8 }} />
								Мои действия
							</Link>
						</Menu.Item>,
						<Menu.Divider key={"divider"} />,
					]
				}
				{/* TODO: Remove process.env.GATSBY_SHOP_ENABLE for enable shop */}
				{process.env.GATSBY_SHOP_ENABLE && <Menu.Item>
					<Link to={"/store/#!/~/cart"} style={removeEmptyKeys({ color: theme.color.text })}>Корзина</Link>
				</Menu.Item>}
				{process.env.GATSBY_SHOP_ENABLE && isLogin && <Menu.Item>
					<Link to={"/store/#!/~/accountSettings"} style={removeEmptyKeys({ color: theme.color.text })}>Мои заказы</Link>
				</Menu.Item>}
				{process.env.GATSBY_SHOP_ENABLE && isLogin && <Menu.Divider />}
				{!isLogin ?
					[
						<Menu.Item key={"log-in"} onClick={() => setAuth(true)} style={removeEmptyKeys({ color: theme.color.text })}>
							<AntIcon type={"login"} />
							Войти
						</Menu.Item>,
						<Menu.Item key={"sign-in"} onClick={() => setSignUp(true)} style={removeEmptyKeys({ color: theme.color.text })}>
							<AntIcon type={"user-add"} />
							Зарегистрироваться
						</Menu.Item>,
					]
					:
					[
						<Menu.Item key={"profile"} onClick={() => navigate("/profile")} style={removeEmptyKeys({ color: theme.color.text })}>
							<AntIcon type="user" /> Профиль
						</Menu.Item>,
						<Menu.Item key={"log-out"} onClick={logOut} style={removeEmptyKeys({ color: theme.color.text })}>
							<AntIcon type="logout" /> Выйти
						</Menu.Item>,
					]
				}
			</Menu>
		);
	}

	function handleTopLinkClick(url) {
		if (typeof window !== "undefined" && window.ym && process.env.NODE_ENV === "production") {
		// eslint-disable-next-line
			ym(
				ymNumber,
				"reachGoal",
				"top-menu",
				{
					AB: {
						"top-menu": {
							[localStorage.getItem("PUSHTELL-top-menu-sort")]: {
								url,
							},
						},
					},
				},
			);
		}
	}

	function makeMenuItems(menuItems = []) {
		return (
			<>
				{
					menuItems.map(({ title, icon, url }, idx) => (
						<Link
							key={`top-menu-item-${idx}`}
							to={url}
							onClick={() => handleTopLinkClick(url)}
							className={"d-flex link link_ugly link_main-light top-menu__item d-noscript-none"}
						>
							<Icon
								id={icon}
								style={{
									width: 28,
									height: 28,
									marginRight: "1rem",
								}}
							/>
							<div
								className={"link_text-light"}
								itemProp="name"
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
								})}
							>
								{title}
							</div>
							<MetaPosition content={idx} />
						</Link>
					))
				}
			</>
		);
	}

	return (
		<>
			<nav
				className={"d-none d-lg-flex justify-content-between top-menu"}
				itemScope
				itemType="https://schema.org/SiteNavigationElement"
				style={removeEmptyKeys({
					backgroundColor: theme.color.body,
				})}
			>
				<Logo
					link={"/"}
					className={"top-menu__item"}
					style={{ textDecoration: "none",
						height: 80,
						maxWidth: 265 }}
					caption={
						<Caption
							slug={slug}
							text={"Серафимова земля"}
							style={removeEmptyKeys({
								marginLeft: ".1rem",
								width: "130px",
								fontFamily: "Tenor Sans",
								color: theme.color.text,
							})}
						/>
					}
					icon={
						<Icon id={"logo"} className={"Logo"} style={{ width: 80,
							margin: "0",
							paddingRight: "10px" }} />
					}
				/>

				{makeMenuItems(items.filter(item => item.kind === "topMenu"))}

				<NoScript>
					<NoScriptTopMenu items={items.filter(item => item.kind === "topMenu")}/>
				</NoScript>

				<SearchWidget />

				<BroadcastWidget location={location}/>

				<RadioWidget/>

				<Popover
					trigger={"click"}
					placement={"bottomRight"}
					overlayStyle={{ width: "100%",
						position: "fixed" }}
					overlayClassName={"crippled-dropdown"}
					content={
						<div
							className="container-fluid"
							style={removeEmptyKeys({
								backgroundColor: theme.color.body,
								color: theme.color.text,
							})}
						>
							<div className={"row justify-content-between"}>
								<div className={"col-2"}>
									<Title
										level={4}
										style={removeEmptyKeys({
											textAlign: "center",
											color: theme.color.text,
										})}
									>
										Шрифт
									</Title>
									<Radio.Group
										buttonStyle={"solid"}
										style={{
											display: "flex",
											marginBottom: 30,
										}}
										defaultValue={""}
										onChange={changeFontFamily}
									>
										<Radio.Button value={""} style={{ flex: 1,
											textAlign: "center" }}>
											Обычный
										</Radio.Button>
										<Radio.Button
											value={"Times New Roman, Times, serif"}
											style={{ flex: 1,
												textAlign: "center" }}
										>
											Засечки
										</Radio.Button>
									</Radio.Group>
								</div>
								<div className={"col-2"}>
									<Title
										level={4}
										style={removeEmptyKeys({
											textAlign: "center",
											color: theme.color.text,
										})}
									>
										Размер
									</Title>
									<Radio.Group
										buttonStyle={"solid"}
										style={{
											display: "flex",
											marginBottom: 30,
										}}
										defaultValue={"default"}
										onChange={changeFontSize}
									>
										<Radio.Button value={"default"} style={{ flex: 1,
											textAlign: "center" }}>
											A
										</Radio.Button>
										<Radio.Button value={"middle"} style={{ flex: 1,
											textAlign: "center" }}>
											A+
										</Radio.Button>
										<Radio.Button value={"large"} style={{ flex: 1,
											textAlign: "center" }}>
											A++
										</Radio.Button>
									</Radio.Group>
								</div>
								<div className={"col-2"}>
									<Title
										level={4}
										style={removeEmptyKeys({
											textAlign: "center",
											color: theme.color.text,
										})}
									>
										Интервал
									</Title>
									<Radio.Group
										buttonStyle={"solid"}
										style={{
											display: "flex",
											textAlign: "center",
											marginBottom: 30,
										}}
										defaultValue={"default"}
										onChange={changeLineHeight}
									>
										<Radio.Button value={"default"} style={{ flex: 1,
											textAlign: "center" }}>
											1.0
										</Radio.Button>
										<Radio.Button value={"middle"} style={{ flex: 1,
											textAlign: "center" }}>
											1.5
										</Radio.Button>
										<Radio.Button value={"large"} style={{ flex: 1,
											textAlign: "center" }}>
											2.0
										</Radio.Button>
									</Radio.Group>
								</div>
								<div className={"col-2"}>
									<Title
										level={4}
										style={removeEmptyKeys({
											textAlign: "center",
											color: theme.color.text,
										})}
									>
										Цвет
									</Title>
									<Radio.Group
										buttonStyle={"solid"}
										style={{
											display: "flex",
											textAlign: "center",
											marginBottom: 30,
										}}
										defaultValue={""}
										onChange={changeBgColor}
									>
										<Radio.Button
											value={"blackWhite"}
											style={{
												flex: 1,
												textAlign: "center",
												backgroundColor: "#000",
												color: "#fff",
											}}
										>
											А
										</Radio.Button>
										<Radio.Button value={"whiteBlack"} style={{ flex: 1,
											textAlign: "center" }}>
											А
										</Radio.Button>
										<Radio.Button
											value={"blue"}
											style={{
												flex: 1,
												textAlign: "center",
												backgroundColor: "#9dd1ff",
												color: "#000",
											}}
										>
											A
										</Radio.Button>
										<Radio.Button value={""} style={{ flex: 1,
											textAlign: "center" }}>
											Выкл
										</Radio.Button>
									</Radio.Group>
								</div>
								<div className={"col-2"}>
									<Title level={4} style={removeEmptyKeys({ textAlign: "center",
										color: theme.color.text })}>
										Изображения
									</Title>
									<Radio.Group
										buttonStyle={"solid"}
										style={{ display: "flex",
											textAlign: "center" }}
										defaultValue={""}
										onChange={changeImage}
									>
										<Radio.Button value={""} style={{ flex: 1,
											textAlign: "center" }}>
											Цвет
										</Radio.Button>
										<Radio.Button value={"gray"} style={{ flex: 1,
											textAlign: "center",
											whiteSpace: "nowrap" }}>
											Ч/Б
										</Radio.Button>
										<Radio.Button value={"off"} style={{ flex: 1,
											textAlign: "center" }}>
											Выкл
										</Radio.Button>
									</Radio.Group>
								</div>
							</div>
						</div>
					}
				>
					<a
						href={"/#cripple"}
						className={"d-flex top-menu__item link link_ugly link_main-light d-noscript-none"}
						style={{ maxWidth: 60 }}
					>
						<Icon
							id={"eye"}
							style={{
								width: 28,
								height: 28,
							}}
						/>
						<MetaPosition content={7} />
					</a>
				</Popover>
				<Dropdown overlay={menu()} trigger={["click"]} overlayClassName={"auth-dropdown"}>
					<span className={"icon-overlay d-flex top-menu__item link link_ugly link_main-light"} style={{ maxWidth: 60 }}>
						{isLogin && avatar ?
							<img
								src={avatar}
								style={{
									borderRadius: "50%",
									width: 40,
									height: 40,
								}}
							/>
							:
							<Icon
								id={"user"}
								style={{
									width: 28,
									height: 28,
								}}
							/>
						}
					</span>
				</Dropdown>
			</nav>
			<AuthForm onClose={() => setAuth(false)} visible={auth} />
			<SignUpForm onClose={() => setSignUp(false)} visible={signUp} />
		</>
	);
}

TopMenu.propTypes = {
	slug: PropTypes.string,
	items: PropTypes.array,
	location: PropTypes.object,
};

TopMenu.defaultProps = {
	slug: "",
	items: [],
	location: {},
};
