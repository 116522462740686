import { createContext } from "react";

export const state = {};

export const AudioguideContext = createContext(
	{
		state: {
			plays: false,
			loading: true,
			disabled: true,
			slug: "",
		},
	},
);

AudioguideContext.displayName = "AudioguideContext";

export const AudioguideContextProvider = AudioguideContext.Provider;
export const AudioguideContextConsumer = AudioguideContext.Consumer;
export default AudioguideContext;
