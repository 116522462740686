import React from "react";
import PropTypes from "prop-types";

import { PureCarousel } from "../../components/PureReactCarousel";

export function NewsSlider({ items, orientation, date, left }) {
	const images = items
		.filter(item => !!item.src)
		.map(item => {
			return {
				src: item.src,
				alt: item.alt,
				title: item.title,
				w: 0,
				h: 0,
				id: item.id,
			};
		});

	return (
		<div
			className={`text-block__image text-block__image__${orientation} `}
			style={{
				"float": left ? "left" : "right",
				"margin": left ? ".5rem 2rem 1rem 0" : ".5rem 0 1rem 2rem",
			}}
		>
			<PureCarousel
				type={"SlideImage"}
				items={images}
				params={
					{
						infinite: false,
						popup: true,
						orientation,
					}
				}
				date={date}
				visibleSlides={1}
			/>
		</div>
	);
}

NewsSlider.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	orientation: PropTypes.string,
	date: PropTypes.string,
	left: PropTypes.bool,
};

NewsSlider.defaultProps = {
	items: [],
	orientation: "default",
	date: "",
	left: false,
};
