import React from "react";
import { Post, Article } from "../../../widgets/VK";
import PropTypes from "prop-types";
import get from "lodash/get";

import "./vkPost.scss";

export default function VKEntrie({ article, post }) {
	const elementId = get(post, "elementId", "");
	const ownerId = get(post, "ownerId", "");
	const postId = get(post, "postId", "");
	const hash = get(post, "hash", "");
	const options = get(post, "options", {});

	const articleUrl = get(article, "article_url", "");
	const articleElementId = get(article, "elementId", "");

	return (
		<>
			{
				post && (
					<Post
						elementId={elementId}
						ownerId={ownerId}
						postId={postId}
						hash={hash}
						options={options}
					/>

				)
			}
			{
				article && (
					<Article
						elementId={articleElementId}
						articleUrl={articleUrl}
					/>
				)
			}
		</>
	);
}

VKEntrie.propTypes = {
	article: PropTypes.object,
	post: PropTypes.object,
};

VKEntrie.defaultProps = {
	article: {},
	post: {},
};

VKEntrie.displayName = "VKEntrie";
