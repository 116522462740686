import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Image } from "../../Media";
import { useThemeContext } from "../../Layout/ThemeContext";

import "./Appeal.scss";
import { removeEmptyKeys } from "../../../helpers";

export function AppealBlock({ alt, img, children, fullWidth }) {
	const { theme } = useThemeContext();

	return (
		<div className="appeal">
			<div
				className={"row appeal__desc"}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					color: theme.color.text,
					backgroundColor: theme.color.body,
				})}
			>
				{img && (
					<div className={cx("mb-3 mb-md-0 col-12 col-md-4", { "col-12": fullWidth })}>
						{img && <Image src={img} style={{ maxWidth: "100%" }} alt={alt} autoHeight height={300} />}
					</div>
				)}
				<div
					className={cx("col-12 col-md-8", { "col-12": !img.src })}
				>
					{children}
				</div>
			</div>
		</div>
	);
}

AppealBlock.propTypes = {
	alt: PropTypes.string,
	img: PropTypes.string,
	children: PropTypes.node,
	fullWidth: PropTypes.bool,
};

AppealBlock.defaultProps = {
	alt: "",
	img: "",
	children: <></>,
	fullWidth: false,
};
