import React from "react";

import { Icon } from "../Media";

import StylishPreloader from "./Stylish";

import bear from "./gif/bear.gif";
import serafim from "./gif/serafim.gif";

import "./Preloader.scss";

export default function Default() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-folding-cube">
				<div className="sk-cube sk-cube-1"></div>
				<div className="sk-cube sk-cube-2"></div>
				<div className="sk-cube sk-cube-4"></div>
				<div className="sk-cube sk-cube-3"></div>
			</div>
		</section>
	);
}

export function PreloaderCubeGrid() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-cube-grid">
				<div className="sk-cube sk-cube-1"></div>
				<div className="sk-cube sk-cube-2"></div>
				<div className="sk-cube sk-cube-3"></div>
				<div className="sk-cube sk-cube-4"></div>
				<div className="sk-cube sk-cube-5"></div>
				<div className="sk-cube sk-cube-6"></div>
				<div className="sk-cube sk-cube-7"></div>
				<div className="sk-cube sk-cube-8"></div>
				<div className="sk-cube sk-cube-9"></div>
			</div>
		</section>
	);
}

export function PreloaderRotatingPlane() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-rotating-plane"></div>
		</section>
	);
}

export function PreloaderDoubleBounce() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-double-bounce">
				<div className="sk-child sk-double-bounce-1"></div>
				<div className="sk-child sk-double-bounce-2"></div>
			</div>
		</section>
	);
}

export function PreloaderWave() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-wave">
				<div className="sk-rect sk-rect-1"></div>
				<div className="sk-rect sk-rect-2"></div>
				<div className="sk-rect sk-rect-3"></div>
				<div className="sk-rect sk-rect-4"></div>
				<div className="sk-rect sk-rect-5"></div>
			</div>
		</section>
	);
}

export function PreloaderWanderingCubes() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-wandering-cubes">
				<div className="sk-cube sk-cube-1"></div>
				<div className="sk-cube sk-cube-2"></div>
			</div>
		</section>
	);
}

export function PreloaderSpinnerPulse() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-spinner sk-spinner-pulse"></div>
		</section>
	);
}

export function PreloaderChasingDots() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-chasing-dots">
				<div className="sk-child sk-dot-1"></div>
				<div className="sk-child sk-dot-2"></div>
			</div>
		</section>
	);
}

export function PreloaderThreeBounce() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-three-bounce">
				<div className="sk-bounce-1 sk-child"></div>
				<div className="sk-bounce-2 sk-child"></div>
				<div className="sk-bounce-3 sk-child"></div>
			</div>
		</section>
	);
}

export function PreloaderCircleBounce() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-circle-bounce">
				<div className="sk-child sk-circle-1"></div>
				<div className="sk-child sk-circle-2"></div>
				<div className="sk-child sk-circle-3"></div>
				<div className="sk-child sk-circle-4"></div>
				<div className="sk-child sk-circle-5"></div>
				<div className="sk-child sk-circle-6"></div>
				<div className="sk-child sk-circle-7"></div>
				<div className="sk-child sk-circle-8"></div>
				<div className="sk-child sk-circle-9"></div>
				<div className="sk-child sk-circle-10"></div>
				<div className="sk-child sk-circle-11"></div>
				<div className="sk-child sk-circle-12"></div>
			</div>
		</section>
	);
}

export function PreloaderFadingCircle() {
	return (
		<section className={"sk-wrapper"}>
			<div className="sk-fading-circle">
				<div className="sk-circle sk-circle-1"></div>
				<div className="sk-circle sk-circle-2"></div>
				<div className="sk-circle sk-circle-3"></div>
				<div className="sk-circle sk-circle-4"></div>
				<div className="sk-circle sk-circle-5"></div>
				<div className="sk-circle sk-circle-6"></div>
				<div className="sk-circle sk-circle-7"></div>
				<div className="sk-circle sk-circle-8"></div>
				<div className="sk-circle sk-circle-9"></div>
				<div className="sk-circle sk-circle-10"></div>
				<div className="sk-circle sk-circle-11"></div>
				<div className="sk-circle sk-circle-12"></div>
			</div>
		</section>
	);
}

export function PreloaderLogo() {
	return (
		<section className={"sk-wrapper"}>
			<Icon id={"logo"} className={"sk-logo"} style={{ width: 160,
				height: 160 }} />
		</section>
	);
}

export function SitePreloader() {
	return (
		<section id={"site-preloader"} className={"sk-preloader d-noscript-none"}>
			<style>
				{`
					* {
						padding: 0;
						margin: 0;
						box-sizing: border-box;
					}

					.sk-preloader {
						position: fixed;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
						width: 100%;
						top: 0;
						background-color: #fff;
						z-index: 9999;
					}

					.sk-fading-image {
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
					}

					.sk-fading-image .sk-fading-image__serafim {
						max-width: 300px;
					}

					.sk-fading-image .sk-fading-image__bear {
						max-width: 450px;
						transform: translateX(-100px) scale(1);
						transition: transform 1.5s linear;
						animation: 4s linear steps;
					}

					@keyframes steps {
						0% {
							transform: translateX(200px) scale(.4);
						}

						100% {
							transform: translateX(-100px) scale(1);
						}
					}

					@media (max-width: 1000px) {
						.sk-fading-image {
							transform: scale(.5);
						}
					}

					@media (max-width: 564px) {
						.sk-fading-image {
							transform: scale(.3);
						}
					}
				`}
			</style>
			<div className={"sk-fading-image"}>
				<img src={serafim} className={"sk-fading-image__serafim"} alt={"Серафим"} />
				<img src={bear} className={"sk-fading-image__bear"} alt={"Медведь"} />
			</div>
		</section>
	);
}

export { StylishPreloader };
