import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import cx from "classnames";
import { useScroll } from "@umijs/hooks";
import { Button } from "antd";
import { get } from "lodash";

import { NewsSlider } from "../../../components/NewsSlider";
import VKEntrie from "../../../components/Blocks/VkPost";
import InstaPost from "../../../components/Blocks/InstaPost";
import FaceBookPost from "../../../components/Blocks/FaceBookPost";
import TwitterPost from "../../../components/Blocks/TwitterPost";
import { formatURL, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";
import NoScript from "../../NoScript";
import { translit } from "../../../helpers";

import "./textblock.scss";
import { Video as VideoComponent } from "../../Media/Video";

export function TextBlock({ title, desc, link, className, children, list, allowBtn, listLink, id, titleClassName, clickElement }) {
	const { theme } = useThemeContext();

	return (
		<div className={"row"}>
			<div
				className={`col text-block ${className}`}
				id={id}
				style={removeEmptyKeys({
					backgroundColor: theme.color.body,
				})}
			>
				{title && (
					<h2
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h2,
							lineHeight: theme.lineHeight.h2,
							color: theme.color.text,
						})}
						className={cx("h1 text-block__title", titleClassName)}
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				)}
				{list && allowBtn &&
				<Link to={listLink} onClick={clickElement}>
					<div
						className={"text text-block__list"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{list}
					</div>
				</Link>
				}
				<div
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
					className={"text text-block__description"}
				>
					<p
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h2,
							lineHeight: theme.lineHeight.h2,
							color: theme.color.text,
						})}
						className={`desc-color-${theme.color.key}`}
					>
						{desc}
					</p>
				</div>
				{children}
				{link && (
					<div className={"d-flex justify-content-center text-block__link"}>
						<Button
							to={formatURL(link.url)}
							className={"button-accent"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h2,
								lineHeight: theme.lineHeight.h2,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{link.title}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

TextBlock.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	className: PropTypes.string,
	link: PropTypes.object,
	titleClassName: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	items: PropTypes.array,
	type: PropTypes.string,
	list: PropTypes.string,
	listLink: PropTypes.string,
	id: PropTypes.string,
	allowBtn: PropTypes.bool,
	clickElement: PropTypes.func,
};

TextBlock.defaultProps = {
	title: "",
	desc: "",
	className: "",
	link: null,
	titleClassName: "",
	children: [],
	list: "",
	listLink: "",
};

export function TextBlockMedia({ title, desc, link, className, items, files, left, id, orientation, children, videoSrc, date, button, articleBody, style, postSrc, postType }) {
	const blockRef = useRef();
	const descContainer = useRef();

	const [scroll] = useScroll(
		typeof window !== "undefined" ? document : null,
	);

	const images = items
		.filter(item => !!item.src)
		.map(item => {
			return {
				src: item.src,
				alt: item.alt,
				title: item.title,
				w: 0,
				h: 0,
				id: item.id,
			};
		});
	const { theme } = useThemeContext();

	const fileItems = files.map((file, idx) => {
		return (
			<li key={`file-${idx}`}>
				<a
					href={get(file, "src", "")}
					target={"_blank"}
					rel="noopener noreferrer"
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h2,
						lineHeight: theme.lineHeight.h2,
						color: theme.color.text,
					})}
				>
					{file.title}
				</a>
			</li>
		);
	});

	const displayBtn = get(button, "display", false);
	const slugBtn = get(button, "slug", "/");
	const textBtn = get(button, "text", "");

	useEffect(() => {
		if (desc) {
			let links = [...descContainer.current.getElementsByTagName("a")];

			links.forEach(node => {
				const href = node.getAttribute("href");

				if (/calendar/.test(href)) {
					const sym = href.slice(-3);
					const splitedHrefref = href.split("#");

					if (splitedHrefref[1]) {
						node.setAttribute("id", `${splitedHrefref[1].slice(0, -3)}${sym === "sym" ? "anc" : "sym"}`);
						node.style.color = "red";
					}
				}

				node.addEventListener("click", event => {
					if (!/^(http|https|tel|mailto):/i.test(href)) {
						event.preventDefault();

						navigate(href);
					} else if (typeof window !== "undefined") {
						window.location(href);
					}
				});
			});
		}
	}, [desc]);

	useEffect(() => {
		const block = blockRef.current;
		// eslint-disable-next-line no-use-before-define
		if (isVisible(block)) {
			block.classList.add("fadein_active");
		}
	}, [scroll]);

	function isVisible(block) {
		let coords = block.getBoundingClientRect();

		let windowHeight = document.documentElement.clientHeight;
		let topVisible = coords.top < windowHeight - 200;
		let bottomVisible = coords.bottom < windowHeight - 200 && coords.bottom > 0;

		return topVisible || bottomVisible;
	}

	const paddingTop = `${get(style, "padding.top", null)}rem`;
	const paddingBottom = `${get(style, "padding.bottom", null)}rem`;

	function returnContentAssets(postType) {
		switch (postType) {
			case "vkPost":
				return <VKEntrie post={postSrc} />;
			case "instaPost":
				return <InstaPost url={postSrc} />;
			case "fbPost":
				return <FaceBookPost url={postSrc} />;
			case "twitter":
				return <TwitterPost url={postSrc} />;
			default:
				return !!images.length && <NewsSlider
					items={images}
					orientation={orientation}
					date={date}
					left={left}
				/>;
		}
	}

	return (
		<div className={"row"}>
			<div
				className={`col text-block_visible text-block ${className} fadein ${left ? "fadein__left" : "fadein__right"}`}
				id={id}
				style={
					{
						backgroundColor: theme.color.body,
						paddingTop,
						paddingBottom,
					}
				}
				ref={blockRef}>
				{title && (
					<h3
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h3,
							lineHeight: theme.lineHeight.h3,
							color: theme.color.text,
						})}
						className={"h3 text-block__title"}
						id={translit(title)}
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				)}
				<div className={"text-block__body"}>
					{returnContentAssets(postType)}
					<>
						<div
							ref={descContainer}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.text,
							})}
							className={`text text-block__description desc-color-${theme.color.key} d-noscript-none`}
							itemProp={articleBody}
						>
							<style>
								{`
									h3 {
										color: ${theme.color.text}
									}
								`}
							</style>
							{desc}
						</div>
						<NoScript>
							<div
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: `${theme.color.text}`,
								})}
								className={"text text-block__description"}
							>
								<p
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.h2,
										lineHeight: theme.lineHeight.h2,
										color: theme.color.text,
									})}
									className={`desc-color-${theme.color.key}`}
								>
									{desc}
								</p>
							</div>
						</NoScript>
					</>
					{fileItems &&
					<ul
						className={"text"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{fileItems}
					</ul>
					}
					{displayBtn &&
						<Link to={slugBtn} className={"text-block__btn"}>
							<Button >
								{textBtn}
							</Button>
						</Link>
					}
					{children}
				</div>

				{videoSrc && (
					<div className="text-block__video mt-5">
						<VideoComponent
							videoSrcURL={videoSrc}
						/>
					</div>
				)}

				{link && (
					<Link
						to={formatURL(link.url)}
						className={"text-block__link"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{link.title}
					</Link>
				)}
			</div>
		</div>
	);
}

TextBlockMedia.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	link: PropTypes.object,
	titleClassName: PropTypes.string,
	children: PropTypes.arrayOf(PropTypes.node),
	items: PropTypes.arrayOf(PropTypes.object),
	left: PropTypes.bool,
	id: PropTypes.string,
	orientation: PropTypes.string,
	files: PropTypes.arrayOf(PropTypes.object),
	videoSrc: PropTypes.string,
	date: PropTypes.string,
	articleBody: PropTypes.string,
	button: PropTypes.object,
	style: PropTypes.object,
	postSrc: PropTypes.object,
	postType: PropTypes.string,
};

TextBlockMedia.defaultProps = { title: "",
	className: "",
	desc: "",
	link: null,
	titleClassName: "",
	children: [],
	items: [],
	left: false,
	id: "",
	orientation: "default",
	files: [],
	videoSrc: "",
	date: "",
	articleBody: "",
	button: {
		display: false,
		text: "",
		slug: "/",
	},
	style: {},
	postSrc: {},
	postType: "" };

export function TextBlockImportantToKnow({ title, desc, children, style }) {
	const { theme } = useThemeContext();

	const paddingTop = `${get(style, "padding.top", null)}rem`;
	const paddingBottom = `${get(style, "padding.bottom", null)}rem`;

	return !!title && !!desc && (
		<div className={"row"}>
			<div
				className={"col text-block text-block__important"}
				style={
					{
						backgroundColor: theme.color.body,
						paddingTop,
						paddingBottom,
					}
				}
			>
				<h5
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h4,
						lineHeight: theme.lineHeight.h4,
						color: theme.color.body,
						backgroundColor: theme.color.text,
					})}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				<div
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
					className={"text text-block__description"}
				>
					<p
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h2,
							lineHeight: theme.lineHeight.h2,
							color: theme.color.text,
						})}
						className={`desc-color-${theme.color.key}`}
					>
						{desc}
					</p>
				</div>
				{children}
			</div>
		</div>
	);
}

TextBlockImportantToKnow.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	children: PropTypes.arrayOf(PropTypes.node),
	style: PropTypes.object,
};

TextBlockImportantToKnow.defaultProps = {
	title: "",
	desc: "",
	children: [],
	style: {},
};
