import React, { useRef } from "react";
import { Button, notification } from "antd";

import { useThemeContext } from "../../Layout/ThemeContext";
import { ymNumber } from "../../../constants";
import { removeEmptyKeys } from "../../../helpers";

export default function CalendarButtons() {
	const { theme } = useThemeContext();

	const outlook = useRef();

	function hit() {
		if (typeof window !== "undefined" && window.ym && process.env.NODE_ENV === "production") {
			// eslint-disable-next-line
			ym(
				ymNumber,
				"reachGoal",
				"calendar-blocks-sort",
				{
					"А/Б Тесты": {
						"Расположение кнопок на странице календаря": localStorage.getItem("PUSHTELL-calendar-blocks-sort").slice(21),
					},
				},
			);
		}
	}

	function handleCopy() {
		outlook.current.select();
		outlook.current.setSelectionRange(0, 99999);
		document.execCommand("copy");

		notification.success({
			message: "Ссылка скопирована",
		});
	}

	const clipBoardPlace = (
		<input
			type={"text"}
			ref={outlook}
			id={"outlook-link"}
			value={"https://calendar.google.com/calendar/embed?src=6c2tgqt5k8fcm97bgiokcvrkb0%40group.calendar.google.com&ctz=Europe%2FMoscow"}
			style={
				{
					position: "absolute",
					opacity: 0,
					border: 0,
					left: 0,
					width: "1px",
				}
			}
		/>
	);

	const items = [
		{
			path: "webcal://calendar.google.com/calendar/ical/6c2tgqt5k8fcm97bgiokcvrkb0%40group.calendar.google.com/public/basic.ics",
			title: "Добавить в календарь Apple",
			onClick() {
				hit();
			},
		},
		{
			path: "https://calendar.google.com/calendar/embed?src=6c2tgqt5k8fcm97bgiokcvrkb0%40group.calendar.google.com&ctz=Europe%2FMoscow",
			title: "Добавить в календарь Google",
			onClick() {
				hit();
			},
		},
		{
			title: "Скопировать ссылку для Outlook",
			onClick() {
				handleCopy();
				hit();
			},
			children: clipBoardPlace,
		},
	];

	return (
		<div
			className={"row justify-content-between align-items-center flex-column flex-md-row mt-5 mb-3"}
		>
			{
				items.map(({ path, title, onClick, children }, idx) => (
					<div
						key={`${title}-${idx}`}
						className="col-12 col-md-4"
					>
						<Button
							block
							href={path}
							target={"_blank"}
							className={"mb-3 m-md-0 button-accent"}
							onClick={onClick}
							style={removeEmptyKeys({
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{title}
						</Button>
						<div>
							{children}
						</div>
					</div>
				))
			}
		</div>
	);
}
