import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Modal, notification, Result } from "antd";

import { withPageDataParams } from "../../containers/withPageData";
import { CREATE_STORY } from "../../queries/queries.graphql";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { getEmailValidationRules } from "../../utils";
import { removeEmptyKeys } from "../../helpers";

const { TextArea } = Input;

function Story({ visible, form, onClose, createStory }) {
	const { getFieldDecorator, validateFields, resetFields } = form;
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(false);
	const { theme } = useThemeContext();

	function handleSubmit() {
		validateFields((errors, variables) => {
			setLoading(true);
			if (!errors) {
				createStory({ variables })
					.then(() => {
						setLoading(false);
						setResult(true);
						resetFields();
					})
					.catch(error => {
						notification.error({
							message: "Произошла непредвиденная ошибка!",
						});
						console.error(error);
						setLoading(false);
					});
			} else {
				notification.error({
					message: "Некорректные данные формы",
				});
				setLoading(false);
			}
		});
	}

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={"Дорогие братия и сёстры!"}
				visible={visible}
				onCancel={onClose}
				centered
				width={1020}
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				wrapClassName={`modal-wrap-${theme.color.key}`}
				footer={[
					<div key={"submit-group"} className={"container-fluid px-0"}>
						<div className={"row"}>
							<div className={"col-6"}>
								<Button
									block
									onClick={onClose}
									disabled={loading}
									style={removeEmptyKeys({
										backgroundColor: theme.color.text,
										color: theme.color.body,
									})}
									className={"button-white"}
								>
								Отменить
								</Button>
							</div>
							<div className={"col-6"}>
								<Button
									block
									type={"primary"}
									onClick={handleSubmit}
									disabled={loading}
									loading={loading}
									style={removeEmptyKeys({
										backgroundColor: theme.color.text,
										color: theme.color.body,
									})}
								>
								Отправить
								</Button>
							</div>
						</div>
					</div>,
				]}
			>
				<Form layout={"vertical"}>
					<div className={"row"}>
						<div className="col-12">
							<Form.Item style={removeEmptyKeys({ color: theme.color.text })}>
							Дорогие братия и сестры! Здесь вы можете поделиться чудесным событием из своей жизни,
							связанным с именем преподобного Серафима Саровского. Можно написать о помощи святого старца
							после молитвенного обращения к нему или о чуде, которое свершилось в ходе или по итогам
							посещения святых мест Дивеевской земли (святые мощи батюшки Серафима, источники).
								<br />
								<br />
							Редакция портала с благодарностью примет личные истории помощи Божией конкретным людям по
							молитвам к преподобному Серафиму Саpовскому.
								<br />
								<br />
							Коротко расскажите о себе. Напишите свою фамилию, имя, отчество. Оставьте данные для
							обратной связи с Вами: адрес места жительства/пребывания, адрес электронной почты, мобильный
							и стационарный номер телефона. После получения ваша история будет отправлена в
							Свято-Троицкий Серафимо-Дивеевский монастырь для соответствующей проверки. После
							подтверждения чудесного явления оно может быть опубликовано на портале «Серафимова земля».
							</Form.Item>
						</div>
						<div className="col-12 col-md-6">
							<Form.Item label={"Введите ФИО"}>
								{getFieldDecorator("fio", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Введите ФИО!",
										},
									],
								})(<Input type={"text"} disabled={loading} />)}
							</Form.Item>
						</div>
						<div className="col-12 col-md-6">
							<Form.Item label={"Ваш адрес места жительства"}>
								{getFieldDecorator("address", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Введите ваш адрес места жительства!",
										},
									],
								})(<Input type={"text"} disabled={loading} />)}
							</Form.Item>
						</div>
						<div className="col-12 col-md-6">
							<Form.Item label={"Ваш контактный телефон"}>
								{getFieldDecorator("phone", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Ваш контактный телефон!",
										},
									],
									onChange: ({ target }) => target.value.replace(/^8/, "7"),
								})(<Input type={"text"} disabled={loading} />)}
							</Form.Item>
						</div>
						<div className="col-12 col-md-6">
							<Form.Item label={"Ваш E-mail"}>
								{getFieldDecorator("email", {
									initialValue: "",
									rules: getEmailValidationRules(),
								})(<Input type={"email"} disabled={loading} />)}
							</Form.Item>
						</div>
						<div className="col-12">
							<Form.Item label={"Ваш рассказ"}>
								{getFieldDecorator("story", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Введите ваш рассказ!",
										},
									],
								})(<TextArea rows={5} disabled={loading} />)}
							</Form.Item>
						</div>
					</div>
				</Form>
				<Modal visible={result} onCancel={() => setResult(false)} footer={null} centered>
					<Result status="success" title="Спасибо! Мы получили Вашу историю." />
				</Modal>
			</Modal>
		</>
	);
}

Story.propTypes = {
	visible: PropTypes.bool,
	form: PropTypes.object,
	onClose: PropTypes.func,
	createStory: PropTypes.func,
};

Story.defaultProps = {
	visible: false,
	form: {},
	onClose: () => Function,
	createStory: () => Function,
};

export const StoryForm = Form.create({ name: "stories" })(
	withPageDataParams(Story, { body: CREATE_STORY,
		name: "createStory" }),
);
