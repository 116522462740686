import React from "react";
import PropTypes from "prop-types";
import { BackTop } from "antd";
import WebFont from "webfontloader";

import Layout from "./src/components/Layout";
import { AuthProvider } from "./src/widgets";

import "./src/styles/antd.less";
import "./src/styles/common.scss";

async function onClientEntry() {
	if (typeof window !== "undefined") {
		const portal = document.createElement("div");
		portal.id = "map-detail";

		document.body.appendChild(portal);

		if ("serviceWorker" in navigator) { // TODO: turn it off, while development
			// if (window.location.pathname === "/") {
			// 	navigator.serviceWorker.register("sw.js", { scope: "./" })
			// 		.then(reg => {
			// 			if (reg.installing) {
			// 				console.log("Service worker installing");
			// 			} else if (reg.waiting) {
			// 				console.log("Service worker installed");
			// 			} else if (reg.active) {
			// 				console.log("Service worker active");
			// 			}
			// 		}).catch(error => {
			// 		// registration failed
			// 			console.log(`Registration failed with ${error}`);
			// 		});
			// }

			navigator.serviceWorker.getRegistrations()
				.then(registrations => {
					registrations.forEach(registration => {
						registration.unregister()
							.then(result => console.info("Unregister Service Worker successful", result))
							.catch(error => console.error("Service Worker unregistered failed:", error));
					});
				}).catch(error => {
					console.error("Service Worker getRegistrations failed: ", error);
				});
		}
	}
}

function onInitialClientRender() {
	const WebFontConfig = {
		google: {
			families: ["Lato:100,300,400,700,900", "Noto Serif:400,700", "Tenor Sans"],
		},
	};

	WebFont.load(WebFontConfig);
}

function onPreRouteUpdate() {
	if (typeof window !== "undefined") {
		const preloader = document.getElementById("site-preloader");

		if (preloader) {
			preloader.style.display = "flex";
		}
	}
}

function onRouteUpdate({ location, prevLocation }) {
	if (typeof window !== "undefined") {
		if ((prevLocation ? prevLocation.pathname : null) !== location) {
			document.body.scrollTop = 0;
		}
	}
}

function wrapPageElement({ element }) {
	if (typeof window !== "undefined") {
		window.ecwid_sso_profile = "";
		window.ecwid_script_defer = true;
	}

	return (
		<AuthProvider>
			<Layout>{element}</Layout>
			<BackTop />
		</AuthProvider>
	);
}

wrapPageElement.propTypes = {
	element: PropTypes.node,
};

export { onClientEntry, onInitialClientRender, onPreRouteUpdate, onRouteUpdate, wrapPageElement };
