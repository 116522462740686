import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";

import { useThemeContext } from "../../Layout/ThemeContext";
import { removeEmptyKeys } from "../../../helpers";

export default function Parables({ items, details }) {
	const { theme } = useThemeContext();

	const parables = items.map((parable, idx) => {
		return (
			<div className={"text parables"} style={details && details.style || {}} key={`calendar-parable-${idx}`}>
				<p
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h2,
						lineHeight: theme.lineHeight.h2,
						color: theme.color.text,
						backgroundColor: theme.color.body,
					})}
				>
					{get(parable, "title", "")}
				</p>
				<p
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h2,
						lineHeight: theme.lineHeight.h2,
						color: theme.color.text,
						backgroundColor: theme.color.body,
					})}
				>
					{get(parable, "text", "")}
				</p>
			</div>
		);
	});

	if (Object.keys(parables).length) {
		return (
			<div className={"calendar__desc-block_paragraph"}>
				<h4 className={"my-3 calendar__desc-block_title"}
					style={Object.assign({}, get(details, "style", {}), removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h2,
						lineHeight: theme.lineHeight.h2,
						color: theme.color.text,
						backgroundColor: theme.color.body,
					}))}
				>
					Святитель Феофан Затворник. Мысли на каждый день года
				</h4>
				{parables}
			</div>
		);
	}

	return null;
}

Parables.propTypes = {
	items: PropTypes.array,
	details: PropTypes.object,
};

Parables.defaultProps = {
	items: [],
	details: {},
};
