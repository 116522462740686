/* eslint-disable */
import { experimentDebugger } from "@marvelapp/react-ab-test";
import { ymNumber } from "../constants";

export function vkPixel() {
	let t = document.createElement("script");

	t.type = "text/javascript";
	t.async = !0;
	t.src = "https://vk.com/js/api/openapi.js?168";
	t.onload = function () {
		VK.Retargeting.Init("VK-RTRG-526159-f7dxD"), VK.Retargeting.Hit();
	};
	document.head.appendChild(t);
}

export function fbPixel() {
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) {
			return;
		}
		n = f.fbq = function () {
			n.callMethod ?
				n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) {
			f._fbq = n;
		}
		n.push = n;
		n.loaded = !0;
		n.version = "2.0";
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
	fbq("init", "319394052453637");
	fbq("track", "PageView");
}

export function yaMetrika() {
	experimentDebugger.enable();
	(function(m,e,t,r,i,k,a){
			m[i] = m[i] || function(){
				(m[i].a = m[i].a || []).push(arguments)
			};
			m[i].l = 1 * new Date();
			k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k,a)
		}
	) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
	ym(ymNumber, "init", {
		clickmap:true,
		trackLinks:true,
		accurateTrackBounce:true
		, webvisor:true,
		trackHash:true
	});
}

export function googleAnalitycs() {
	const ga = document.createElement("script");

	ga.type = "text/javascript";
	ga.async = !0;
	ga.src = "https://www.googletagmanager.com/gtag/js?id=UA-155871122-1";
	ga.onload = function () {
		window.dataLayer = window.dataLayer || [];

		function gtag() {
			dataLayer.push(arguments);
		}

		gtag("js", new Date());

		gtag("config", "UA-155871122-1");
	};

	document.head.appendChild(ga);
}
