const JulianDate = require("julian-date");

function makeJulianDate(separator = "", offset = 0) {
	const j = new JulianDate();
	const currentDate = new Date(j.julian(j.julian() + offset).d);
	const month = currentDate.getMonth() + 1;
	const monthStr = month < 10 ? `0${month}` : month;
	const day = currentDate.getDate();
	const dayStr = day < 10 ? `0${day}` : day;

	return [currentDate.getFullYear(), monthStr, dayStr].join(separator);
}

exports.makeJulianDate = makeJulianDate;
