import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { PreloaderLogo } from "../../Preloader";
import { useThemeContext } from "../../Layout/ThemeContext";

export function Image({
	alt,
	className,
	wrappedClassName,
	style,
	autoHeight,
	width,
	height,
	minHeight,
	minWidth,
	maxHeight,
	maxWidth,
	responsive,
	objectFit,
	src,
}) {
	const picture = useRef(null);
	const { theme } = useThemeContext();

	const [loading, setLoading] = useState(true);
	const [imageHeight, setImageHeight] = useState(height);

	useEffect(() => {
		return () => picture.current.remove();
	}, []);

	return (
		<>
			<picture
				ref={picture}
				key={src}
				className={`${wrappedClassName} d-noscript-none`}
				style={{
					maxHeight,
					maxWidth,
					minHeight,
					minWidth,
					objectFit,
					height: imageHeight,
					width,
					display: "block",
					position: "relative",
					transition: "height .3s ease .5s",
					overflow: "hidden",
				}}
			>
				<>
					{loading && <PreloaderLogo style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%)",
					}} />}
					{responsive.map(({ src, media }, idx) => <source key={`source-img-${idx}`} srcSet={src} media={media} />)}
					<img
						className={className}
						src={src}
						alt={alt}
						style={
							Object.assign(
								{},
								style, {
									objectFit,
									...theme.image,
								},
							)
						}
						onLoadStart={() => console.log("load start")}
						onLoad={
							event => {
								setLoading(false);

								if (autoHeight) {
									setImageHeight(event.target.height / (event.target.width / picture.current.offsetWidth));
								}
							}
						}
						onError={
							error => {
								console.warn(error);
								setLoading(false);
							}
						}
					/>
				</>
			</picture>
			<picture className={"d-none d-noscript-block"}>
				<img className={className} src={src} alt={alt} style={style} />
			</picture>
		</>
	);
}

Image.propTypes = {
	minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	src: PropTypes.string,
	alt: PropTypes.string,
	options: PropTypes.array,
	className: PropTypes.string,
	wrappedClassName: PropTypes.string,
	style: PropTypes.object,
	autoHeight: PropTypes.bool,
	responsive: PropTypes.array,
	objectFit: PropTypes.string,
};

Image.defaultProps = {
	minHeight: 160,
	minWidth: 160,
	maxHeight: "100%",
	maxWidth: "100%",
	width: "100%",
	height: 160, // TODO: Need to create image wrap with relative position
	src: "",
	alt: "no image",
	options: ["fit"],
	className: "",
	wrappedClassName: "",
	responsive: [],
	objectFit: "cover",
	style: {},
	autoHeight: false,
};
