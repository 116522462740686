import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Form, Input, Button, Modal, notification, Result } from "antd";
import { get } from "lodash";

import { AuthContext } from "../../../widgets";
import { useThemeContext } from "../../Layout/ThemeContext";
import { removeEmptyKeys } from "../../../helpers";

function Auth({ visible, form, onClose }) {
	const { getFieldDecorator, validateFields, resetFields } = form;

	const { theme } = useThemeContext();
	const { updateClient, signIn } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(false);

	function handleSubmit(event) {
		event.preventDefault();

		validateFields((errors, body) => {
			setLoading(true);

			if (!errors) {
				signIn(
					JSON.stringify(body),
					({ status, data }) => {
						if (+status === 200) {
							localStorage.setItem("serafim_token", data.token);
							resetFields();
							setLoading(false);
							updateClient();
							onClose();
							notification.success({
								message: "Авторизация выполнена успешно",
							});
						} else {
							if (get(data, "title")) {
								notification.error({
									message: `${data.title}`,
									description: status,
								});
							} else {
								notification.error({
									message: "Ошибка сервера",
									description: status,
								});
							}
							setLoading(false);
						}
					},
					error => {
						console.error(error);
						notification.error({
							message: "Ошибка соединения",
							description: error,
						});
						setLoading(false);
					},
				);
			} else {
				notification.error({
					message: "Некорректные данные формы",
					description: "Поля отмеченные * обязательны для заполнения",
				});
				setLoading(false);
			}
		});
	}

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={"Введите логин и пароль"}
				visible={visible}
				onCancel={onClose}
				centered
				width={400}
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				wrapClassName={`modal-wrap-${theme.color.key}`}
				footer={[
					<div key={"submit-group"} className={"container-fluid px-0"}>
						<div className={"row"}>
							<div className={"col-6"}>
								<Button
									block
									onClick={onClose}
									disabled={loading}
									style={removeEmptyKeys({
										backgroundColor: theme.color.text,
										color: theme.color.body,
									})}
								>
									Отменить
								</Button>
							</div>
							<div className={"col-6"}>
								<Button
									block
									type={"primary"}
									htmlType={"submit"}
									onSubmit={handleSubmit}
									onClick={handleSubmit}
									disabled={loading}
									loading={loading}
									style={removeEmptyKeys({
										backgroundColor: theme.color.text,
										color: theme.color.body,
									})}
								>
									Войти
								</Button>
							</div>
						</div>
					</div>,
				]}
			>
				<Form layout={"vertical"} onSubmit={handleSubmit}>
					<div className={"row"}>
						<div className="col-12">
							<Form.Item label={"Введите логин"}>
								{getFieldDecorator("login", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Введите логин!",
										},
									],
								})(<Input disabled={loading} autoFocus/>)}
							</Form.Item>
						</div>
						<div className="col-12">
							<Form.Item label={"Введите пароль"}>
								{getFieldDecorator("password", {
									initialValue: "",
									rules: [
										{
											required: true,
											message: "Введите пароль!",
										},
									],
								})(<Input.Password disabled={loading} />)}
							</Form.Item>
						</div>
						<div className="col-12">
							<Link
								to={"/restore-password"}
								onClick={onClose}
								style={removeEmptyKeys({ color: theme.color.text })}
							>
								Восстановить пароль
							</Link>
						</div>
					</div>
					<Button
						htmlType={"submit"}
						onSubmit={handleSubmit}
						tabIndex={-1}
						className={"d-none"}
					/>
				</Form>
				<Modal visible={result} onCancel={() => setResult(false)} footer={null} centered>
					<Result status="success" title="Спасибо! Ваша история будет опубликована!" />
				</Modal>
			</Modal>
		</>
	);
}

Auth.propTypes = {
	visible: PropTypes.bool,
	form: PropTypes.object,
	onClose: PropTypes.func,
};

Auth.defaultProps = {
	visible: false,
	form: {},
	onClose: () => Function,
};

export const AuthForm = Form.create({ name: "auth" })(Auth);
