import React from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

export default function RutubePlayer({ src }) {
	if (!src) {
		return null;
	}

	return (
		<iframe
			width="100%"
			height={isMobile ? 200 : 650}
			src={src}
			frameBorder="0"
			allow="clipboard-write"
			webkitAllowFullScreen
			mozallowfullscreen
			allowFullScreen></iframe>
	);
}

RutubePlayer.propTypes = {
	src: PropTypes.string,
};
