import cyrillicToTranslit from "cyrillic-to-translit-js";
import moment from "moment";
import { get, trim, compact, pickBy, isEmpty } from "lodash";

import { formatURL, makeUrl } from "./makeUrl";

export function scriptLoader(src = null, onLoad = () => Function, onError = () => Function) {
	if (src) {
		const script = document.createElement("script");
		const node = document.getElementsByTagName("head")[0];

		script.type = "text/javascript";
		script.async = true;
		script.src = src;
		script.onload = onLoad;
		script.onerror = onError;

		node.appendChild(script, node);

		return script;
	}
	console.error(`Invalid resource address: ${src}`);

	return null;
}

export function translit(string = "") {
	return cyrillicToTranslit({ preset: "ru" }).transform(string, "-");
}

export function makeMediaData(data = [], type) {
	return data.map(({ title_short, title_full, slug, main_image_preview, main_image }) => {
		return {
			path: formatURL(`media/${type}/${slug}`),
			src: get(main_image_preview, "src", get(main_image, "src", "")),
			title: title_short || title_full,
		};
	});
}

function makeNewAdapter(news) {
	const { slug, main_image, main_image_mobile, main_image_preview, issued_at, page_title_full, page_title_short, category, updated_at, settings } = news;

	return {
		date: !get(settings, "hide_published_at", false) && issued_at ? new Date(issued_at).toLocaleDateString() : null,
		path: makeUrl.news({ slug,
			category }),
		src: get(main_image_preview, "src", "") || get(main_image, "src", ""),
		title: page_title_short || page_title_full,
		responsive: [
			{
				src: get(main_image_mobile, "src", ""),
				media: "(max-width: 930px)",
			},
		],
		actualDate: updated_at || new Date(),
	};
}

export function makeNew(news) {
	return makeNewAdapter(news);
}

export function makeNews(data = []) {
	return data.map(makeNewAdapter);
}

function makeArticleAdapter(article) {
	const { title_short, page_title_short, page_title_full, title_full, slug, main_image_preview, main_image, main_image_mobile, updated_at, issued_at, settings } = article;

	return {
		path: makeUrl.mediaArticle({ slug }),
		src: get(main_image_preview, "src", "") || get(main_image, "src", ""),
		title: title_short || title_full || page_title_short || page_title_full,
		responsive: [
			{
				src: get(main_image_mobile, "src", ""),
				media: "(max-width: 930px)",
			},
		],
		actualDate: updated_at || new Date(),
		date: !get(settings, "hide_published_at", false) && issued_at ? new Date(issued_at).toLocaleDateString() : null,
	};
}

export function makeArticle(article) {
	return makeArticleAdapter(article);
}

export function makeArticles(data = []) {
	return data.map(makeArticleAdapter);
}

export function makeBooks(data = []) {
	return data.map(({ title_short, title_full, slug, main_image, main_image_preview }) => {
		return {
			path: makeUrl.book({ slug }),
			src: get(main_image_preview, "src", "") || get(main_image, "src", ""),
			title: title_short || title_full,
		};
	});
}

export function makeHolyPlaces(data = []) {
	return data.map(({ title, path, image }) => {
		return {
			path,
			src: get(image, "src", ""),
			title,
		};
	});
}

export function makeShrines(data = []) {
	return data.map(({ title_short, title_full, slug, main_image_preview, main_image, main_image_mobile, city, monastery }) => {
		const path = compact([get(city, "slug", ""), get(monastery, "slug", ""), slug]).join("/");

		return {
			path,
			src: get(main_image_preview, "src", get(main_image, "src", "")),
			title: title_short || title_full,
			responsive: [
				{
					src: get(main_image_mobile, "src", ""),
					media: "(max-width: 930px)",
				},
			],
		};
	});
}

export function sorter(first, second) {
	// TODO: Need to implement sort direction
	return first - second;
}

export function makeDate(str = "") {
	moment.locale("ru");

	return moment.utc(trim(str.replace(/ \+/g, "+").replace("UTC", ""))).format("L");
}

export function removePreloader(needRemove = true, timeout = 0) {
	setTimeout(() => {
		if (typeof window !== "undefined") {
			const preloader = document.getElementById("site-preloader");

			if (preloader) {
				if (needRemove) {
					preloader.style.display = "none";
				}
			}
		}
	}, timeout);
}

export * from "./makeJulianDate";
export * from "./makeUrl";
export * from "./jss";
export * from "./mdConverter";

moment.updateLocale("en", {
	months: [
		"Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль",
		"Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь",
	],
});

moment.updateLocale("en", {
	monthsShort: [
		"Янв", "Фев", "Мар", "Апр", "Май", "Июн",
		"Июл", "Авг", "Сен", "Окт", "Ноя", "Дек",
	],
});

moment.updateLocale("en", {
	weekdaysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
});

export function makeSpeachSponsor(sponsorsCount) {
	const count = sponsorsCount.toString();

	// TODO: избавиться
	if (count === 0) {
		return "0 жертвователей";
	} else if (count === 1) {
		return `${count} жертвователь`;
	} else if (count > 1 && count < 5 || count[count.length - 1] > 1 && count[count.length - 1] < 5) {
		return `${count} жертвователя`;
	} else if (
		count >= 5 && count <= 20 ||
		count[count.length - 1] >= 5 && count[count.length - 1] <= 9 ||
		count > 0 && count[count.length - 1] === 0
	) {
		return `${count} жертвователей`;
	}

	return "0 жертвователей";
}

export function makeSpeachDay(days) {
	days = days.toString();

	// TODO: избавиться
	if (days <= 0) {
		return "Завершено";
	} else if (days === 1) {
		return `${days} день до завершения`;
	} else if (days > 1 && days < 5 || days[days.length - 1] > 1 && days[days.length - 1] < 5) {
		return `${days} дня до завершения`;
	} else if (
		days >= 5 && days <= 20 ||
		days[days.length - 1] >= 5 && days[days.length - 1] <= 9 ||
		days > 0 && days[days.length - 1] === 0
	) {
		return `${days} дней до завершения`;
	}

	return "Нет информации о времени завершения";
}

export * from "./converter";

export function getPoint(entity) {
	return get(entity, "location_object.features", []).filter(
		feature => get(feature, "geometry.type", "") === "Point",
	);
}

export function makeVirtualTourSlides(virtualTours) {
	return virtualTours.map(({ virtual_tour }) => {
		const img_preview = get(virtual_tour, "main_image_preview.src", "");
		const main_img = get(virtual_tour, "main_image.src", "");
		const title_short = get(virtual_tour, "title_short", "");
		const title_full = get(virtual_tour, "title_full", "");
		const mobile_img = get(virtual_tour, "main_image_mobile.src", "");

		return {
			path: makeUrl.virtualTour(virtual_tour),
			src: img_preview || main_img,
			title: title_short || title_full,
			responsive: [
				{
					src: mobile_img,
					media: "(max-width: 930px)",
				},
			],
		};
	});
}

/**
 * Remove keys with empty values
 * @param {*} object
 * @returns object
 */
export function removeEmptyKeys(object) {
	return pickBy(object, key => !isEmpty(key));
}
