import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import cx from "classnames";
import { get } from "lodash";

import { Icon, Image } from "../../../Media";
import { formatURL, removeEmptyKeys } from "../../../../helpers";
import { useThemeContext } from "../../../Layout/ThemeContext";
import { MetaPosition } from "../../../../components/Meta";

function SubMenu({ items, onHandleClick }) {
	const { theme } = useThemeContext();

	return items.map(({ url, title, path }, idx) => {
		const linkTo = formatURL(url || path);

		return (
			<Link
				key={`menu-main__sub-item-${idx}`}
				to={linkTo}
				onClick={() => onHandleClick(linkTo)}
				className={"d-flex justify-content-between align-items-center col-12 py-3 link menu-main__sub-item"}
			>
				<span
					className={"d-flex justify-content-between align-items-center menu-main__title"}
					style={removeEmptyKeys({
						color: theme.color.text,
					})}
				>
					{title}
				</span>
			</Link>
		);
	});
}

SubMenu.propTypes = {
	items: PropTypes.array,
	onHandleClick: PropTypes.func,
};

SubMenu.defaultProps = {
	items: [],
	onHandleClick: () => Function,
};

function ImageMenu({ items, onHandleClick }) {
	const { theme } = useThemeContext();

	return items.map(({ url, title, path, img, mobileImg, classNameSub }, idx) => {
		if (classNameSub !== "col-1") {
			const linkTo = formatURL(url || path);

			return (
				<Link
					key={`menu-main__sub-item-${idx}`}
					to={linkTo}
					onClick={() => onHandleClick(linkTo)}
					className={"d-flex flex-column col-4 col-sm-4 col-md-2 mb-3 link menu-main__sub-item"}
					itemProp="url"
				>
					<Image
						className={"mb-3 menu-main__image"}
						src={get(img, "src", "")}
						alt={title}
						minWidth={"auto"}
						height={"auto"}
						minHeight={"53px"}
						objectFit={""}
						style={{ width: "100%" }}
						responsive={[
							{
								src: mobileImg,
								media: "(max-width: 1024px)",
							},
						]}
					/>
					<span
						className={"menu-main__title"}
						style={removeEmptyKeys({
							fontSize: 14,
							color: theme.color.text,
						})}
						itemProp="name">
						{title}
					</span>
					<MetaPosition content={idx} />
				</Link>
			);
		}

		return null;
	});
}

ImageMenu.propTypes = {
	items: PropTypes.array,
	onHandleClick: PropTypes.func,
};

ImageMenu.defaultProps = {
	items: [],
	onHandleClick: () => Function,
};

const menu = {
	SubMenu,
	ImageMenu,
};

export default function SubLevel({ sub, handleBack, icon, headerTitle, component, style, onHandleClick }) {
	const { theme } = useThemeContext();

	return (
		<div
			className={"container-fluid pt-3 menu-main__sub"}
			style={Object.assign({}, removeEmptyKeys({
				backgroundColor: theme.color.body,
			}), style)}
		>
			<div className={"row flex-column flex-nowrap"} style={{ height: "100%" }}>
				<div className={"container-fluid pb-4 menu-main__sub-header"} style={{ flex: 0 }}>
					<a
						onClick={handleBack}
						className={"d-flex align-items-center mb-4 menu-main__sub-back"}
						style={removeEmptyKeys({
							color: theme.color.text,
						})}>
						<Icon id={"arrow"} className={"mr-3"} />
						Назад
					</a>
					<a className={"d-flex align-items-center menu-main__sub-title"}
						style={removeEmptyKeys({
							color: theme.color.text,
						})}>
						<Icon id={icon} className={"mr-3"} />
						{headerTitle}
					</a>
				</div>
				<div className={"container-fluid"} style={{ flex: 1,
					overflow: "scroll" }}>
					<nav className={cx("py-5 row", { "justify-content-center": /ImageMenu/gi.test(component) })}>
						{React.createElement(menu[component] || menu.SubMenu, { items: sub,
							onHandleClick })}
					</nav>
				</div>
			</div>
		</div>
	);
}

SubLevel.propTypes = {
	sub: PropTypes.array,
	isActive: PropTypes.bool,
	handleBack: PropTypes.func,
	icon: PropTypes.string,
	headerTitle: PropTypes.string,
	component: PropTypes.string,
	style: PropTypes.object,
	onHandleClick: PropTypes.func,
};

SubLevel.defaultProps = {
	sub: [],
	isActive: false,
	handleBack: () => Function,
	icon: "",
	headerTitle: "",
	component: "SubMenu",
	style: {},
	onHandleClick: () => Function,
};
