export const fundValidationRules = [
	{
		required: true,
		message: "Введите сумму!",
	},
	{

		pattern: /^[1-9]+[0-9]*$/g,
		message: "Некорректная сумма!",
	},
];

export function getEmailValidationRules(required = true) {
	return [
		{
			required,
			message: "Введите E-mail!",
		},
		{
			type: "email",
			message: "Введите валидный E-mail!",
		},
	];
}

export function getFirstNameValidationRules(required = true) {
	return [
		{
			required,
			message: "Введите имя!",
		},
		{
			pattern: /^[а-яА-Я|a-z]+$/ig,
			message: "Введите корректное имя!",
		},
	];
}

export function getAddressValidationRules(required = true) {
	return [
		{
			required,
			message: "Введите адрес доставки!",
		},
	];
}

export function getPhoneNumberValidationRules(required = true) {
	return [
		{
			required,
			message: "Введите номер телефона!",
		}, {
			len: 10,
			message: "Номер телефона должен состоять из 10 цифр.",
		}, {
			transform: value => +value,
			type: "number",
			message: "Введите только цифры.",
		}];
}
