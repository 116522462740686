import React, { Component } from "react";
import { Link } from "gatsby";
import { Popover } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import { get } from "lodash";

import { Icon } from "../../Media";
import NoScript from "../../NoScript";
import NoScriptMenu from "../NoScriptMenu";
import { SubMenu } from "./SubMenu";
import { formatURL, removeEmptyKeys } from "../../../helpers";
import { StoryForm } from "../../../widgets";
import { MetaPosition } from "../../../components/Meta";
import { ThemeContext } from "../../Layout/ThemeContext";

import "./DesktopMenu.scss";

export class DesktopMenu extends Component {
	static propTypes = {
		links: PropTypes.array,
		location: PropTypes.object,
	};

	static defaultProps = {
		links: [],
		location: {
			pathname: "",
		},
	};

	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			isSecondOpen: false,
			selected: null,
			swap: {},
			position: { top: 0 },
			width: {
				opened: 300,
				closed: 80,
			},
		};

		this.handleSelect = this.handleSelect.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.checkSibling = this.checkSibling.bind(this);
		this.siblingChecker = this.siblingChecker.bind(this);
	}

	siblingChecker(event) {
		if (this.checkSibling(event.target)) {
			this.getSnapshotBeforeUpdate();
		} else {
			this.setState({ isOpen: true });
		}
	}

	componentDidMount() {
		if (!this.state.position.top) {
			const position = this.line.getBoundingClientRect();

			this.setState({
				position,
			});
		}
		document.addEventListener("click", this.siblingChecker);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.siblingChecker);
	}

	getSnapshotBeforeUpdate(prevProps) {
		const { location } = this.props;

		if (get(prevProps, "location.pathname", get(location, "pathname", "")) !== get(location, "pathname", "")) {
			this.setState({
				selected: null,
				isOpen: false,
			});

			if (typeof window !== "undefined") {
				document.querySelector("html").classList.remove("menu-open");
			}
		}

		return null;
	}

	handleSelect(event, selected) {
		event.preventDefault();

		this.setState({ selected });
	}

	handleClose() {
		if (`${this.state.selected}`) {
			this.setState({ selected: null });
		} else {
			this.setState({ isOpen: false });
		}
	}

	handleMouseEnter() {
		this.setState({
			isOpen: true,
		});

		if (typeof window !== "undefined") {
			document.querySelector("html").classList.add("menu-open");
		}
	}

	handleMouseLeave() {
		if (!this.state.selected) {
			this.setState({
				isOpen: false,
			});

			if (typeof window !== "undefined") {
				document.querySelector("html").classList.remove("menu-open");
			}
		}
	}

	handleClickClose(value) {
		if (!value && this.state.selected) {
			this.setState({
				isOpen: false,
				selected: null,
			});
		}

		if (typeof window !== "undefined" && !value) {
			document.querySelector("html").classList.remove("menu-open");
		}
	}

	checkSibling(target) {
		return this.desktop && !this.desktop.contains(target);
	}

	render() {
		const { links } = this.props;
		const { theme } = this.context;

		return (
			<div
				ref={desktop => this.desktop = desktop}
				itemScope
				itemType="https://schema.org/SiteNavigationElement"
			>
				<NoScript>
					<style>
						{`
							.menu-desktop {
								width: 300px !important;
							}
						`}
					</style>
				</NoScript>
				<div
					className={"d-none d-lg-flex flex-column menu-desktop"}
					onMouseEnter={this.handleMouseEnter}
					onMouseLeave={this.handleMouseLeave}
					style={Object.assign(
						{},
						{
							width:
								this.state.isOpen || this.state.selected
									? this.state.width.opened
									: this.state.width.closed,
							overflow: "hidden",
						},
						removeEmptyKeys({ backgroundColor: theme.color.body }),
					)}
				>
					<nav
						ref={line => this.line = line}
						className={"d-flex flex-column justify-content-center menu-desktop__line"}
						style={{
							width: this.state.width.opened,
						}}
					>
						{links.filter(item => item.kind === "sidebBar").map(
							(
								{
									sub = [],
									url = "",
									title = "",
									subtitle,
									subDescription,
									icon,
									component,
									classname,
									mobileOnly = "off",
								},
								idx,
							) => {
								sub = sub ? sub : [];

								if (mobileOnly) {
									return null;
								}

								return (
									<div key={`menu-desktop__item-${idx}`} className={"menu-desktop__item d-noscript-none"}>
										{!url ? (
											<Popover
												placement="right"
												content={
													<SubMenu
														sub={sub}
														subtitle={subtitle}
														subDescription={subDescription}
														component={component}
														className={classname}
													/>
												}
												trigger={"click"}
												overlayClassName={"menu-popover"}
												overlayStyle={{
													padding: 0,
													maxWidth: "calc(100% - 300px)",
													width: 1200,
													top: this.state.position.top,
													transformOrigin: "0 0",
												}}
												top={this.state.position.top}
												onClick={event => this.handleSelect(event, `${idx}`)}
												visible={this.state.selected === `${idx}`}
												onVisibleChange={value => {
													this.handleClickClose(value);
												}}
												destroyTooltipOnHide={true}
											>
												<a
													className={cx(
														"d-flex align-items-center justify-content-start link link_ugly link_main-light menu-desktop__link d-noscript-none",
														{ link_selected: this.state.selected === `${idx}` },
													)}
													itemProp="url"
												>
													<div
														className={"d-flex justify-content-center"}
														style={{ width: 28 }}
													>
														<Icon id={icon} />
													</div>
													<span
														className={"menu-desktop__text"}
														style={Object.assign({}, {
															marginLeft: 12,
															opacity: +this.state.isOpen || +this.state.isSecondOpen,
														}, removeEmptyKeys({
															fontFamily: theme.fontFamily,
															fontSize: theme.fontSize.text,
															color: theme.color.text,
														}))}
														itemProp="name"
													>
														{title}
													</span>
													<Icon
														id={"chevron"}
														className={cx({ "d-none": !!url })}
														style={{
															position: "absolute",
															marginRight: 24,
															right: 0,
															top: "40%",
														}}
													/>
													<MetaPosition content={idx} />
												</a>
											</Popover>
										) : (
											<Link
												to={formatURL(url)}
												className={cx(
													"d-flex align-items-center justify-content-start link link_ugly link_main-light menu-desktop__link",
												)}
												itemProp="url"
											>
												<div className={"d-flex justify-content-center"} style={{ width: 28 }}>
													<Icon id={icon} />
												</div>
												<span
													className={"menu-desktop__text"}
													style={Object.assign({}, {
														marginLeft: 12,
														opacity: +this.state.isOpen || +this.state.isSecondOpen,
													}, removeEmptyKeys({
														fontFamily: theme.fontFamily,
														fontSize: theme.fontSize.text,
														color: theme.color.text,
													}))}
													itemProp="name"
												>
													{title}
												</span>
												<MetaPosition content={idx} />
											</Link>
										)}
									</div>
								);
							},
						)}
						<NoScript>
							<NoScriptMenu links={links} />
						</NoScript>
						<div key={"menu-desktop__item-shop"} className={"menu-desktop__item d-noscript-none"}>
							{process.env.GATSBY_SHOP_ENABLE && <Link
								to={"/store"}
								className={cx(
									"d-flex align-items-center justify-content-start link link_ugly link_main-light menu-desktop__link",
								)}
								itemProp="url"
							>
								<div className={"d-flex justify-content-center"} style={{ width: 28 }}>
									<Icon id={"store"} />
								</div>
								<span
									className={"menu-desktop__text"}
									style={{
										marginLeft: 12,
										opacity: +this.state.isOpen || +this.state.isSecondOpen,
									}}
								>
									Магазин
								</span>
							</Link>}
						</div>
						<div
							onClick={() => this.setState({ visible: true })}
							className={
								"d-flex align-items-center justify-content-start link link_ugly link_main-light menu-desktop__link"
							}
							style={{ opacity: 0 }}
						>
							<div className={"d-flex justify-content-center"} style={{ width: 28 }}>
								<Icon id={"story"} />
							</div>
							<span
								className={"menu-desktop__text"}
								style={Object.assign({}, {
									marginLeft: 12,
									opacity: +this.state.isOpen,
								}, removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									color: theme.color.text,
								}))}
							>
								Рассказать о чуде
							</span>
						</div>
					</nav>
					<StoryForm onClose={() => this.setState({ visible: false })} visible={this.state.visible} />
				</div>
			</div>
		);
	}
}

DesktopMenu.contextType = ThemeContext;
