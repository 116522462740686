import React from "react";
import { EmbeddedPost } from "react-facebook";
import PropTypes from "prop-types";

import "./index.scss";

export default function FaceBookPost({ url }) {
	return (
		<div className={"fb__post"}>
			<EmbeddedPost href={url} />
		</div>
	);
}

FaceBookPost.displayName = "FaceBookPost";

FaceBookPost.propTypes = {
	url: PropTypes.string,
};

FaceBookPost.defaultProps = {
	url: "",
};
