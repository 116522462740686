import { createContext, useContext } from "react";
import { theme } from "../Constants";

export const ThemeContext = createContext({
	theme,
	changeFontFamily: () => Function,
	changeImage: () => Function,
	changeFontSize: () => Function,
	changeLineHeight: () => Function,
	changeBgColor: () => Function,
});

export function useThemeContext() {
	return useContext(ThemeContext);
}
export const ThemeContextProvider = ThemeContext.Provider;
