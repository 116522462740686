import React from "react";
import { Link } from "gatsby";
import { Button } from "antd";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import { Icon } from "../../Media";
import { formatURL, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";
import ImageSplit from "../../ImageSplit";

import "./Slide.scss";

export function SlideNews({ src, path, title, date, place, name, desc, className = "", mediaSize, responsive, idx, clickElement }) {
	const [width, height] = mediaSize;
	const { theme } = useThemeContext();

	return (
		<div className={"swiper-slide slide slide_news"} onClick={clickElement}>
			<div className={`slide__content slide__content_news ${className}`}>
				<Link to={formatURL(`/${path}`)} className={"slide__content-news-image-wrapper"}>
					<picture>
						{responsive.map(({ src, media }, idx) => <source key={`source-img-${idx}`} srcSet={src} media={media} />)}
						<img
							src={src}
							data-src={src}
							width={width}
							height={isMobile ? "100%" : height}
							style={{
								objectFit: "contain",
								...theme.image,
							}}
							alt={title}
							data-slide={idx}
							className={"carousel__image"}
							orientation={"horizontal"}
						/>
					</picture>
				</Link>
				<div
					className={"slide__date"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
				>
					{
						date && (
							<div style={removeEmptyKeys({ color: theme.color.text })}>{date}</div>
						)
					}
					{
						place && (
							<div style={removeEmptyKeys({ color: theme.color.text })}>{place}</div>
						)
					}
				</div>
				{name ||
					title && (
						<div className={"slide__title slide__title_sub"}>
							<Link to={formatURL(`/${path}`)}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
								})}
							>
								{name}
								{title}
							</Link>
						</div>
					)}
				{desc && (
					<div
						className={"slide__description"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						<Link to={formatURL(`/${path}`)}>
							{desc}
						</Link>
					</div>
				)}
			</div>
		</div>
	);
}

SlideNews.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	path: PropTypes.string,
	title: PropTypes.string,
	date: PropTypes.string,
	place: PropTypes.string,
	name: PropTypes.string,
	desc: PropTypes.string,
	className: PropTypes.string,
	mediaSize: PropTypes.array,
	idx: PropTypes.number,
	responsive: PropTypes.array,
	clickElement: PropTypes.func,
	actualDate: PropTypes.string,
};

SlideNews.defaultProps = {
	asrc: "",
	alt: "",
	path: "",
	title: "",
	date: "",
	place: "",
	name: "",
	desc: "",
	className: "",
	mediaSize: ["100%", "100%"],
	idx: 13,
	responsive: [],
	clickElement: () => Function,
	actualDate: new Date(),
};

export function SlideContent({ src, path, title, desc, className = "", mediaSize, responsive }) {
	const [, height] = mediaSize;
	const { theme } = useThemeContext();

	return (
		<div className={"slide slide_indent"}>
			<div className={`d-flex flex-column slide__content slide__content_indent ${className}`}>
				<Link
					to={formatURL(`/${path}`)}
					className={"d-flex align-items-center justify-content-center slide__image"}
					style={removeEmptyKeys({
						height,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
				>
					{/* <Image src={img} width={width} height={height} options={["sc", "fit"]} />*/}
					<picture>
						{responsive.map(({ src, media }, idx) => <source key={`source-img-${idx}`} srcSet={src} media={media} />)}
						<img src={src} alt={title} style={{
							objectFit: "cover",
							...theme.image,
						}} />
					</picture>
				</Link>
				{title && (
					<span
						className={"slide__title"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{title}
					</span>
				)}
				{desc && (
					<div
						className={"slide__sub-title"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{desc}
					</div>
				)}
				<Button
					className={"button_bottom button-accent"}
					to={formatURL(`/${path}`)}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.body,
						backgroundColor: theme.color.text,
					})}
				>
					Подробнее
				</Button>
			</div>
		</div>
	);
}

SlideContent.propTypes = {
	src: PropTypes.string,
	path: PropTypes.string,
	title: PropTypes.string,
	desc: PropTypes.string,
	className: PropTypes.string,
	mediaSize: PropTypes.array,
	responsive: PropTypes.array,
};

SlideContent.defaultProps = {
	responsive: [],
	mediaSize: ["100%", "100%"],
};

export function SlideLinks({ src, className = "", imageClassName = "", title, path, mediaSize, responsive, idx, clickElement }) {
	const [width, height] = mediaSize;
	const { theme } = useThemeContext();

	return (
		<div className={"swiper-slide slide"} onClick={clickElement}>
			<div className={`slide__content ${className}`}>
				<Link to={formatURL(`/${path}`)}>
					<div className={`slide__image ${imageClassName}`} style={{ height }}>
						<picture>
							{responsive.map(({ src, media }, idx) => <source key={`source-img-${idx}`} srcSet={src} media={media} />)}
							{src &&
								(<img
									src={src}
									alt={title}
									data-src={src}
									width={width}
									height={height}
									style={Object.assign({}, { objectFit: "cover" }, { ...theme.image })}
									data-slide={idx}
									className={"carousel__image"}
									orientation={"horizontal"}

								/>)
							}
						</picture>
					</div>
				</Link>
				<Link
					to={formatURL(`/${path}`)}
					className={"d-flex align-items-center slide__link"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
						backgroundColor: theme.color.body,
					})}
				>
					{title}
					<Icon
						id={"arrow"}
						style={{
							transform: "rotate(180deg)",
							marginLeft: 6,
							position: "absolute",
							right: 6,
						}}
					/>
				</Link>
			</div>
		</div>
	);
}

SlideLinks.propTypes = {
	src: PropTypes.string,
	path: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string,
	imageClassName: PropTypes.string,
	mediaSize: PropTypes.array,
	idx: PropTypes.number,
	responsive: PropTypes.array,
	clickElement: PropTypes.func,
};

SlideLinks.defaultProps = {
	responsive: [],
	mediaSize: ["100%", "100%"],
};
export function SlideImage({ src, mediaSize, idx, onClick, title, alt, responsive }) {
	const { theme } = useThemeContext();

	const isSplit = src instanceof Array;

	return (
		<div
			className={"swiper-slide slide"}
			onClick={!isSplit ? onClick : () => Function}
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				flexWrap: "wrap",
			}}
		>
			<ImageSplit
				{...{
					src,
					mediaSize,
					idx,
					alt,
					responsive,
					title,
				}}
			/>
			{
				title && (
					<div
						className={"slide__title slide__title_sub"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: `${isMobile ? "12px" : theme.fontSize.text}`,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{title}
					</div>
				)
			}
		</div>
	);
}

SlideImage.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	idx: PropTypes.number,
	onClick: PropTypes.func,
	className: PropTypes.string,
	title: PropTypes.string,
	mediaSize: PropTypes.array,
	style: PropTypes.object,
	responsive: PropTypes.array,
};

SlideImage.defaultProps = {
	style: { objectFit: "cover" },
	responsive: [],
};

export function SlideIkon({ src, mediaSize, idx, onClick, style, alt, path, responsive }) {
	const { theme } = useThemeContext();
	const [width, height] = mediaSize;

	return (
		<div className={"swiper-slide slide"} onClick={onClick} style={{ alignItems: "center" }}>
			<Link to={path} className={"d-flex justify-content-center"}>
				<picture>
					{responsive.map(({ src, media }, idx) => <source key={`source-img-${idx}`} srcSet={src} media={media} />)}
					<img src={src} alt={alt} data-src={src} width={width} height={height} style={Object.assign({}, style, { ...theme.image })} data-slide={idx} />
				</picture>
			</Link>
		</div>
	);
}

SlideIkon.propTypes = {
	src: PropTypes.string,
	mediaSize: PropTypes.array,
	idx: PropTypes.number,
	onClick: PropTypes.func,
	style: PropTypes.object,
	alt: PropTypes.string,
	path: PropTypes.string,
	responsive: PropTypes.array,
};

SlideIkon.defaultProps = {
	style: { objectFit: "cover" },
	responsive: [],
};

export function Slide({ src, mediaSize = [400, 300], idx, alt, responsive }) {
	const [width, height] = mediaSize;
	const { theme } = useThemeContext();

	return (
		<div className={"swiper-slide slide"}>
			<div className={"slide__image"} style={{ height }}>
				<picture>
					{responsive.map(({ src, media }, idx) => <source key={`source-img-${idx}`} srcSet={src} media={media} />)}
					<img
						src={src}
						alt={alt}
						data-src={src}
						width={width}
						height={height}
						style={{
							objectFit: "cover",
							...theme.image,
						}}
						data-slide={idx}
					/>
				</picture>
			</div>
		</div>
	);
}

Slide.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	idx: PropTypes.number,
	handleOpen: PropTypes.func,
	className: PropTypes.string,
	mediaSize: PropTypes.array,
	responsive: PropTypes.array,
};

Slide.defaultProps = {
	responsive: [],
};
