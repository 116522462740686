import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { message } from "antd";

import { scriptLoader } from "../../../../helpers";
import { ContentBlock, TourBlock } from "../../../Blocks";
import NoScript from "../../../NoScript";

import "./TourBlock.scss";

export function TourVirtualBlock({ settings, preview }) {
	const virtualTourBlock = useMemo(
		() => (
			<ContentBlock key={"virtualTours"}>
				<TourBlock
					settings={settings}
					preview={preview}
				/>
			</ContentBlock>
		),
		[],
	);

	return (
		virtualTourBlock
	);
}

export function VirtualBlock({ settings }) {
	const [isMount, setIsmount] = useState(false);
	const [player, setPlayer] = useState(false);

	const basePath = get(settings, "params.base_path", "");

	let currentPanotourPlayer = null;
	let startscene;
	let starthlookat;
	let startvlookat;
	let startfov;
	let hash;

	function readDeviceOrientation() {
		if (typeof window !== "undefined") {
			// window.innerHeight is not supported by IE
			let winH = window.innerHeight ? window.innerHeight : $(window).height();
			let winW = window.innerWidth ? window.innerWidth : $(window).width();
			// force height for iframe usage
			if (!winH || winH === 0) {
				winH = "100%";
			}
			// set the height of the document
			$("html").css("height", winH);
			// scroll to top
			window.scrollTo(0, 0);
		}
	}

	function loadPlayer(isWebVr, curScene, curTime) {
		if (isWebVr) {
			embedpano({
				id: "krpanoSWFObject",
				xml: `${basePath}arzamasdata/arzamas_vr.xml`,
				target: "panoDIV",
				passQueryParameters: true,
				bgcolor: "#000000",
				html5: "only+webgl",
				focus: false,
				onerror: e => console.log(e),
				vars: { skipintro: true,
					norotation: true,
					startscene: curScene,
					starttime: curTime },
			});
		} else {
			let isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

			embedpano({
				id: "krpanoSWFObject",
				xml: `${basePath}arzamasdata/arzamas.xml`,
				target: "panoDIV",
				passQueryParameters: true,
				bgcolor: "#000000",
				focus: false,
				html5: isBot ? "always" : "only",
				vars: { startscene: curScene,
					starttime: curTime },

			});
		}
		// apply focus on the visit if not embedded into an iframe
		if (top.location === self.location) {
			kpanotour.Focus.applyFocus();
		}
	}

	function unloadPlayer() {
		if ($("#krpanoSWFObject")) {
			removepano("krpanoSWFObject");
		}
	}

	function accessWebVr(curScene, curTime) {
		unloadPlayer();
		loadPlayer(true, curScene, curTime);
	}

	function accessStdVr(curScene, curTime) {
		unloadPlayer();
		loadPlayer(false, curScene, curTime);
	}

	function isVRModeRequested() {
		if (typeof window !== "undefined") {
			const params = window.location.search.substring(1).split("&");

			for (let i = 0; i < params.length; i++) {
				if (params[i].toLowerCase() === "vr") {
					return true;
				}
			}
		}

		return false;
	}

	function readhash(force) {
		if (typeof window !== "undefined") {
			hash = window.location.href.split("#")[1];
			if (typeof hash === "undefined") {
				hash = window.top.location.href.split("#")[1];
			}
			if (typeof hash !== "undefined" || force === "1") {
				startscene = hash.split("/")[0];
				starthlookat = hash.split("/")[1];
				startvlookat = hash.split("/")[2];
				startfov = hash.split("/")[3];
			}
		}
	}

	readhash();

	function myHashchangeHandler() {
		const krpano = document.getElementById("krpanoSWFObject");

		krpano.call("stopdelayedcall(set_current_pano_view_hash); if(garrows_settings.scenes_wobbling_global, set(garrows_settings.scenes_wobbling, false); stopdelayedcall(panomovement);); stoptween(view.vlookat, view.hlookat);");
		readhash(1);
		krpano.call("glinks_startup(); delayedcall(calc(glinks_settings.glinks_transitiontime + 1), if(garrows_settings.scenes_wobbling_global, set(garrows_settings.scenes_wobbling, true); panomovement();););");
	}

	useEffect(() => {
		// First: Load $
		scriptLoader("https://code.jquery.com/jquery-3.4.1.min.js", () => {
			setIsmount(true);
		});

		if (typeof window !== "undefined") {
			// eslint-disable-next-line func-names
			window.sethash = function (h) {
				window.top.history.replaceState(undefined, undefined, `#${h}`);
			};

			// eslint-disable-next-line func-names
			window.createvars = function () {
				let krpano = document.getElementById("krpanoSWFObject");

				if (startscene && starthlookat && startvlookat && startfov) {
					krpano.call(`set(startscene,${startscene});`);
					krpano.call(`set(starthlookat,${starthlookat});`);
					krpano.call(`set(startvlookat,${startvlookat});`);
					krpano.call(`set(startfov,${startfov});`);
				}
			};

			// eslint-disable-next-line func-names
			window.visitedSpot = function (spotName, spotImage) {
				$(`#${spotName} p img`).attr("src", spotImage);
			};

			// eslint-disable-next-line func-names
			window.copyURLToClipboard = function () {
				if (typeof window !== "undefined") {
					message.info("Copy URL to clipboard", window.top.location);
				}
			};

			// eslint-disable-next-line func-names
			window.getCurrentTourPlayer = function () {
				if (currentPanotourPlayer == null) {
					currentPanotourPlayer = document.getElementById("krpanoSWFObject");
				}

				return currentPanotourPlayer;
			};

			if ("addEventListener" in window) {
				window.addEventListener("hashchange", myHashchangeHandler, false);
			} else if ("attachEvent" in window) {
				window.attachEvent("onhashchange", myHashchangeHandler);
			}
		}
	}, []);

	useEffect(() => {
		if (isMount) {
			if (/(iphone|ipod|ipad|android|iemobile|webos|fennec|blackberry|kindle|series60|playbook|opera\smini|opera\smobi|opera\stablet|symbianos|palmsource|palmos|blazer|windows\sce|windows\sphone|wp7|bolt|doris|dorothy|gobrowser|iris|maemo|minimo|netfront|semc-browser|skyfire|teashark|teleca|uzardweb|avantgo|docomo|kddi|ddipocket|polaris|eudoraweb|opwv|plink|plucker|pie|xiino|benq|playbook|bb|cricket|dell|bb10|nintendo|up.browser|playstation|tear|mib|obigo|midp|mobile|tablet)/.test(navigator.userAgent.toLowerCase())) {
				if (typeof window !== "undefined" && /iphone/.test(navigator.userAgent.toLowerCase()) && window.self === window.top) {
					$("body").css("height", "100.18%");
				}
				// add event listener on resize event (for orientation change)
				if (typeof window !== "undefined" && window.addEventListener) {
					window.addEventListener("load", readDeviceOrientation);
					window.addEventListener("resize", readDeviceOrientation);
					window.addEventListener("orientationchange", readDeviceOrientation);
				}
				// initial execution
				setTimeout(readDeviceOrientation, 10);
			}

			scriptLoader(`${basePath}arzamasdata/arzamas.js`, () => setPlayer(true));
		}

		return () => {
			window.removeEventListener("load", readDeviceOrientation);
			window.removeEventListener("resize", readDeviceOrientation);
			window.removeEventListener("orientationchange", readDeviceOrientation);
		}
	}, [isMount]);

	useEffect(() => {
		if (player) {
			if (isVRModeRequested()) {
				accessWebVr();
			} else {
				accessStdVr();
			}
		}
	}, [player]);

	return (
		<div id="container" className={"mb-5"}>
			<div id="tourDIV">
				<div id="panoDIV">
					<NoScript>
						<p>Чтобы увидеть виртуальный тур, включите JavaScript</p>
					</NoScript>
				</div>
			</div>
			{/* FIXME: Set false keylog in main xml */}
			<style>
				{`
					// #krpanoSWFObject div:nth-child(2) div:first-child {
					// 		display: none !important;
					// 		opacity: 0 !important;
					// 		visibility: hidden !important;
					// 	}
					// #krpanoSWFObject div:nth-child(2) pre {
					// 	display: none !important;
					// 		opacity: 0 !important;
					// 		visibility: hidden !important;
					// }
					#krpanoSWFObject > div:nth-child(2) {
						display: none !important;
						opacity: 0 !important;
						visibility: hidden !important;
					}
					`}
			</style>
		</div>
	);
}

TourVirtualBlock.propTypes = {
	settings: PropTypes.object.isRequired,
	preview: PropTypes.string.isRequired,
};

TourVirtualBlock.defaultProps = {
	settings: {},
	preview: "",
};

VirtualBlock.propTypes = {
	settings: PropTypes.object.isRequired,
};

VirtualBlock.defaultProps = {
	settings: {},
};
