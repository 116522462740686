/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { get } from "lodash";
import { useQuery, ApolloProvider } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FacebookProvider } from "react-facebook";

import "moment/locale/ru";
import "pure-react-carousel/dist/react-carousel.es.css";

import ErrorBoundary from "../ErrorBoundary";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumbs from "./Breadcrumbs";
import PhotoSwipe from "./PhotoSwipe";
import NoScript from "../NoScript";
import {
	ABProvider,
	AuthContext,
	BroadcastProvider,
	RadioProvider,
	SiteMetadataProvider,
	AudioguideProvider,
} from "../../widgets";
import { vkPixel, fbPixel, yaMetrika } from "../../metrics";
import VKProvider from "../../widgets/VK";
import { ThemeProvider } from "./ThemeContext";

const MENU = gql`
	query menuData {
		menus(order_by: {sort_order: asc}) {
			classname
			component
			icon
			kind
			mobile
			mobileComponent: mobile_component
			mobileOnly: mobile_only
			sort_order
			subDescription: sub_desciption
			sub: sub_json
			subtitle: sub_title
			title
			url
		}
	}
`;

export { Breadcrumbs };
export default function Layout({ children }) {
	const { hasura, site } = useStaticQuery(graphql`
		query LayoutQuery {
			site {
				...SiteInfo
			}
			hasura {
				...Menu
				...AbTest

				main_page(where: { type: { _eq: "main" } }) {
					settings
				}
			}
		}
	`);

	const { client } = useContext(AuthContext);

	const menus = get(hasura, "menus", []);
	const abTest = get(hasura, "abTest", []);
	const globalSettings = get(hasura, "main_page[0].settings");
	const siteMetadata = get(site, "siteMetadata", []);
	const timestampForUpdate = get(site, "siteMetadata.timestampForUpdate", "NotFound");

	const [menu, setMenu] = useState(menus);

	useEffect(() => {
		if (process.env.NODE_ENV !== "development") {
			yaMetrika();
			vkPixel();
			fbPixel();
		}
	}, []);

	const { data: menuData, error: menuError } = useQuery(MENU, {
		fetchPolicy: "cache-and-network",
		ssr: false,
		client,
	});

	useEffect(() => {
		if (menuData && !menuError) {
			setMenu(get(menuData, "menus", []));
		}
	}, [menuData]);

	return (
		<ErrorBoundary>
			<ApolloProvider client={client}>
				<SiteMetadataProvider
					value={{
						siteMetadata,
						globalSettings,
					}}>
					<ThemeProvider>
						<FacebookProvider appId={get(globalSettings, "socials.facebookApId", "")}>
							<VKProvider>
								<RadioProvider>
									<AudioguideProvider>
										<ABProvider
											value={{ abTest }}>
											<BroadcastProvider>
												<Header menu={menu} slug={children.props.path} abTest={abTest} />
												<NoScript>
													<style>
														{`
															.d-noscript-none{
																display: none !important;
															}
															.d-noscript-block {
																display: block !important;
															}
															.top-menu {
																z-index: 500;
															}
															.text-block_visible {
																transform: translate3d(0, 0, 0) !important;
																opacity: 1 !important;
															}
														`}
													</style>
												</NoScript>
												<main
													id={"root-main"}
													data-timestamp={timestampForUpdate}
												>
													{children}
												</main>
												<Footer className={"justify-self-end mt-auto"} menu={menu} />
												<PhotoSwipe />
											</BroadcastProvider>
										</ABProvider>
									</AudioguideProvider>
								</RadioProvider>
							</VKProvider>
						</FacebookProvider>
					</ThemeProvider>
				</SiteMetadataProvider>
			</ApolloProvider>
		</ErrorBoundary>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
	children: <></>,
};
