import { useState, useEffect } from "react";
import { useThrottleFn } from "@umijs/hooks";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import PropTypes from "prop-types";

import { AUDIOGUDES_SEARCH_QUERY } from "../../queries/queries.graphql";

import "./Search.scss";

export function useSearchAudioWidget() {
	const [value, setValue] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [results, setResults] = useState([]);

	const [loadSearchResults, { called, loading, error, data: searchedData }] = useLazyQuery(
		AUDIOGUDES_SEARCH_QUERY,
		{
			variables: {
				queryString: searchValue,
				types: ["audio_guides"],
			},
		},
	);

	useThrottleFn(() => {
		setSearchValue(value);
	}, [value], 100);

	useEffect(() => {
		if (searchValue.length > 2) {
			loadSearchResults();
		} else {
			setResults([]);
		}
	}, [searchValue]);

	useEffect(() => {
		if (called && searchedData && !error) {
			const data = get(searchedData, "audiogudes", []).map(item => {
				try {
					return {
						...item,
						main_image_preview: JSON.parse(get(item, "main_image_preview", "{}")),
						audio: JSON.parse(get(item, "audio", "{}")),
					};
				} catch (error) {
					console.error(error);

					return item;
				}
			});
			setResults(data);
		}
	}, [loading]);

	return {
		loading,
		value,
		results,
		onChnage: setValue,
	};
}

useSearchAudioWidget.propTypes = {
	categoryList: PropTypes.array,
};

useSearchAudioWidget.defaultProps = {
	categoryList: [],
};
