import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ThemeContextProvider } from "../Context";
import {
	theme,
	imageFilter,
	fontSize,
	lineHeight,
	color,
} from "../Constants";

export default function ThemeProvider({ children }) {
	const [updatedTheme, setUpdatedTheme] = useState(theme);

	const changeFontFamily = useCallback(({ target: { value } }) => {
		setUpdatedTheme(Object.assign({}, updatedTheme, { fontFamily: value,
			fontFamilyValue: value }));
	}, [updatedTheme]);

	const changeImage = useCallback(({ target: { value } }) => {
		setUpdatedTheme(Object.assign({}, updatedTheme, { image: imageFilter.get(value),
			imageValue: value }));
	}, [updatedTheme]);

	const changeFontSize = useCallback(({ target: { value } }) => {
		setUpdatedTheme(
			Object.assign({}, updatedTheme, {
				fontSize: fontSize.get(value),
				fontSizeValue: value,
			}),
		);
	}, [updatedTheme]);

	const changeLineHeight = useCallback(({ target: { value } }) => {
		setUpdatedTheme(
			Object.assign({}, updatedTheme, {
				lineHeight: lineHeight.get(value),
				lineHeightValue: value,
			}),
		);
	}, [updatedTheme]);

	const changeBgColor = useCallback(({ target: { value } }) => {
		localStorage.setItem("theme", value);
		setUpdatedTheme(
			Object.assign({}, updatedTheme, {
				color: color.get(value),
				colorValue: value,
			}),
		);
	}, [updatedTheme]);

	useEffect(() => {
		const themeInLocalStorage = localStorage.getItem("theme");

		if (themeInLocalStorage) {
			changeBgColor({ target: { value: themeInLocalStorage } });
		}
	}, []);

	return (
		<ThemeContextProvider
			value={{
				theme: updatedTheme,
				changeFontFamily,
				changeImage,
				changeFontSize,
				changeLineHeight,
				changeBgColor,
			}}
		>
			{children}
		</ThemeContextProvider>
	);
}

ThemeProvider.propTypes = {
	children: PropTypes.node,
};
