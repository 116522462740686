import { createContext, useContext } from "react";

export const VKContext = createContext({
	isInit: false,
	Post: () => Function,
	Article: () => Function,
});

VKContext.displayName = "VKContext";

export const VKContextProvider = VKContext.Provider;
export function useVKContext() {
	return useContext(VKContext);
}
export default VKContext;
