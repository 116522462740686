import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { get, capitalize, trim, lowerCase } from "lodash";
import cx from "classnames";

import { formatURL, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../ThemeContext";
import { MetaPosition } from "../../../components/Meta";

import "./Breadcrumb.scss";

export default function Breadcrumbs({ currentLabel, pageContext, classNames }) {
	const { theme } = useThemeContext();

	const themeStyles = removeEmptyKeys({
		fontFamily: theme.fontFamily,
		fontSize: theme.fontSize.text,
		lineHeight: theme.lineHeight.text,
		color: theme.color.text,
	});

	let breadcrumbItem = [];

	breadcrumbItem.push(
		<li
			key={"breadcrumb-/"}
			itemProp="itemListElement"
			itemScope
			itemType="http://schema.org/ListItem"
		>
			<Link
				itemProp="item"
				to={formatURL("/")}
				style={themeStyles}
			>
				<MetaPosition content={1} />
				<span itemProp="name">Главная</span>
			</Link>
			<span style={themeStyles}> / </span>
		</li>,
	);

	const path = [];
	let position = 1;

	const filteredItems = Object.entries(pageContext)
		.filter(([key]) => /item|slug|title_full|monastery|city/.test(key));

	filteredItems.forEach(([, value], idx) => {
		const slug = get(value, "slug", "");
		const title = get(value, "title_full", "");

		position++;

		if (slug && title) {
			path.push(slug);
			breadcrumbItem.push(
				<li
					key={`breadcrumb-${slug}-${idx}`}
					itemProp="itemListElement"
					itemScope
					itemType="http://schema.org/ListItem"
				>
					<Link
						itemProp="item"
						to={formatURL(path.join("/"))}
						style={themeStyles}
					>
						<MetaPosition content={position} />
						<span itemProp="name">{lowerCase(title) === "нижний новгород" ? title : capitalize(title)}</span>
					</Link>
					<span style={themeStyles}> / </span>
				</li>,
			);
		} else if (title) {
			breadcrumbItem.push(
				<li
					key={`breadcrumb-${slug}-${idx}`}
					itemProp="itemListElement"
					itemScope
					itemType="http://schema.org/ListItem"
				>
					<a itemProp="item" style={themeStyles}>
						<MetaPosition content={position} />
						<span itemProp="name">{capitalize(title)}</span>
						<span> / </span>
					</a>
				</li>,
			);
		}
	});

	breadcrumbItem.push(
		<li
			key={`breadcrumb-${currentLabel}`}
			itemProp="itemListElement"
			itemScope
			itemType="http://schema.org/ListItem"
		>
			<a itemProp="item" className={"my-breadcrumb__current-label"}>
				<span style={themeStyles} itemProp="name">
					<MetaPosition content={2 + filteredItems.length} />
					{
						currentLabel
							.split("/")
							.map(
								caption =>
									trim(caption)
										.charAt(0)
										.toUpperCase() + trim(caption).slice(1),
							)
							.join(" / ")
					}
				</span>
			</a>
		</li>,
	);

	return (
		<ul
			className={cx("my-breadcrumb", classNames)}
			itemScope
			itemType="http://schema.org/BreadcrumbList"
		>
			{breadcrumbItem}
		</ul>
	);
}

Breadcrumbs.propTypes = {
	currentLabel: PropTypes.string,
	pageContext: PropTypes.object,
	classNames: PropTypes.string,
};

Breadcrumbs.defaultProps = {
	currentLabel: "",
	pageContext: {},
	classNames: "",
};
