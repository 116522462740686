import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse, Checkbox } from "antd";
import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";
import { Link } from "gatsby";
import { get } from "lodash";

import { makeUrl, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";

export default function SaintBooks({ items, details }) {
	const { theme } = useThemeContext();

	const saintBooks = <Collapse accordion>
		{ items.map((saint_text, idx) => <CollapsePanell key={`panel-${idx}`} idx={idx} data={saint_text} details={details} />)}
	</Collapse>;

	if (Object.keys(saintBooks).length) {
		return (
			<div className={"calendar__desc-block_paragraph"}>
				<h4 className={"mb-3 calendar__desc-block_title"}
					style={Object.assign({}, get(details, "style", {}), removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h2,
						lineHeight: theme.lineHeight.h2,
						color: theme.color.text,
						backgroundColor: theme.color.body,
					}))}
				>
					Евангелие дня
				</h4>
				{saintBooks}
			</div>
		);
	}

	return null;
}

SaintBooks.propTypes = {
	items: PropTypes.array,
	details: PropTypes.object,
};

SaintBooks.defaultProps = {
	items: [],
	details: {},
};

function CollapsePanell({ data, details, ...rest }) {
	const { Panel } = Collapse;
	const { theme } = useThemeContext();
	const [rusText, setRusText] = useState(["rus_text_r"]);

	const audios = get(data, "saint_text.holy_audios", []);
	const bookName = get(data, "saint_text.saint_book.name", "");

	function handleLangChange(value) {
		setRusText(value);
	}

	function makeRedText(text) {
		const reg = /\[(Зач.*?)\]/;
		const match = text.match(reg);
		const redText = get(match, "[1]", "");
		const [a, b] = text.split(redText);

		return redText ?
			<>
				<span>{a}</span>
				<span style={{ color: "red" }}>{redText}</span>
				<span>{b}</span>
			</> :
			<>
				{text}
			</>;
	}

	const audioBLock = <div>
		{audios.length ? audios.map(({ title, url }, idx) => {
			return (
				<div key={`saint-audio-${idx}`} className={"mt-3 mb-3"}>
					<span>{title}</span>
					<ReactPlayer
						className={"audio-saints"}
						url={get(url, "src", "")}
						controls
						height={45}
						width={"100%"}
					/>
				</div>
			);
		}) : null
		}
	</div>;

	return (
		<Panel {...rest} header={`${get(data, "saint_text.gospel_kinde.title", "")} ${bookName}`}>
			{isMobile && audioBLock}
			<Checkbox.Group
				options={[
					{ label: "Синодальный",
						value: "rus_text_r" },
					{ label: "Церковнославянский (рус)",
						value: "church_rus_text_c" },
				]}
				defaultValue={["rus_text_r"]}
				onChange={handleLangChange}
			/>
			<div
				className={"d-flex align-space-around"}
				style={removeEmptyKeys({
					backgroundColor: theme.color.body,
					color: theme.color.text,
				})}
			>
				{
					rusText.includes("rus_text_r") &&
					<p style={removeEmptyKeys({ marginTop: 10,
						textAlign: "justify",
						paddingRight: 10,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text || get(details, "style.color", "") })}
					>
						{makeRedText((get(data, "saint_text.rus_text_r", "") ?? "").replace(/<(.*?)>/mg, ""))}
					</p>
				}
				{
					rusText.includes("church_rus_text_c") &&
					<p style={removeEmptyKeys({ marginTop: 10,
						textAlign: "justify",
						borderLeft: "1px solid #988347",
						paddingLeft: 10,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text || get(details, "style.color", "") })}
					>
						{makeRedText((get(data, "saint_text.church_rus_text_c", "") ?? "").replace(/<(.*?)>/mg, ""))}
					</p>
				}
			</div>
			{!isMobile && audioBLock}
			<p
				style={removeEmptyKeys({
					marginTop: 10,
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					color: theme.color.text,
					backgroundColor: theme.color.body,
				})}
			>
				Читать всю книгу <Link
					className={"linked calendar__gospels"}
					to={makeUrl.saint_book(get(data, "saint_text.saint_book", {}))}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
						backgroundColor: theme.color.body,
					})}
				>
					{bookName}
				</Link>
			</p>
		</Panel>
	);
}

CollapsePanell.propTypes = {
	data: PropTypes.object,
	details: PropTypes.object,
};

CollapsePanell.defaultProps = {
	data: {},
	details: {},
};

