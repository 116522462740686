import React from "react";
import PropTypes from "prop-types";

export default function NoScript({ children }) {
	return process.env.GATSBY_HASURA_ENV !== "test" ? (
		<noscript>
			{children}
		</noscript>
	) : null;
}

NoScript.propTypes = {
	children: PropTypes.element.isRequired,
};

NoScript.defaultProps = {
	children: null,
};

export function NoIndex({ children }) {
	return (
		<noindex>
			{children}
		</noindex>
	);
}

NoIndex.propTypes = {
	children: PropTypes.element.isRequired,
};

NoIndex.defaultProps = {
	children: null,
};
