import React, { Fragment } from "react";
import { get, uniqWith, isEqual } from "lodash";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import locale from "antd/lib/date-picker/locale/ru_RU";
import moment from "moment";

import { Carousel } from "../Carousel";
import "../../styles/common.scss";
import corner from "../../images/corner.svg";
import { useThemeContext } from "../Layout/ThemeContext";
import { makeUrl, removeEmptyKeys } from "../../helpers";
import CalendarButtons from "./CalendarButtons";
import SaintBooks from "./SaintBooks";
import Parables from "./Parables";
import LiturgicalDirections from "./LiturgicalDirections";
import { Icon } from "../Media";

import "./Calendar.scss";

export default function Calendar({ data, details, isCalendarPage, className, menu }) {
	const { theme } = useThemeContext();

	const dayName = get(data, "name", "");
	const dayTitle = get(data, "day", "");
	const dayOldStyle = get(data, "old_date_style", "");
	const dayNewStyle = get(data, "new_date_style", "");
	const weekTitle = get(data, "week.title", "");
	const foodName = get(data, "food.name", "");
	const glasName = get(data, "glas_name", "");
	const memorialDays = get(data, "memorial_days", []);
	const days_holidays = get(data, "days_holidays", []);

	let ikons;
	// Иконы дня
	const uniqIcons = memorialDays.map(item => get(item, "saint.ikons_saints[0]", [])).flat();
	if (menu) {
		uniqIcons.length = 2;
		ikons = uniqIcons.map((ikon, idx) => {
			return (
				<Link to={makeUrl.ikon(get(ikon, "ikon", {}))} key={`calendar-img-${idx}`} style={{ margin: "0 15px" }}>
					<img
						src={get(ikon, "ikon.main_image.src", "")}
						alt={get(ikon, "ikon.title_full", "")}
						data-src={get(ikon, "ikon.main_image.src", "")}
						width={"auto"}
						height={200}
						style={Object.assign({}, { objectFit: "cover" }, { ...theme.image })}
						data-slide={idx}
					/>
				</Link>
			);
		});
	} else {
		ikons = uniqIcons.map(ikon => ({
			path: makeUrl.ikon(get(ikon, "ikon", {})),
			src: get(ikon, "ikon.main_image.src", ""),
			alt: get(ikon, "ikon.title_full", ""),
		}));
	}
	let holidaysImage;
	// Праздники дня
	const uniqHolidays = days_holidays.map(item => get(item, "holiday.images", [])).flat();

	if (menu) {
		uniqHolidays.length = 2;
		holidaysImage = uniqHolidays.map((holiday, idx) => {
			return (
				<Link to={makeUrl.holiday(holiday)} key={`calendar-img-${idx}`} style={{ margin: "0 15px" }}>
					<img
						src={get(holiday, "src", "")}
						width={"auto"}
						height={200}
						style={Object.assign({}, { objectFit: "cover" }, { ...theme.image })}
						data-slide={idx}
					/>
				</Link>
			);
		});
	} else {
		holidaysImage = uniqHolidays.map(holiday => ({
			path: makeUrl.holiday(get(days_holidays, "[0].holiday")),
			src: get(holiday, "src", ""),
		}));
	}

	// Пост дня
	const uniqPosts = uniqWith(get(data, "days_posts", []), isEqual);
	const days_posts = uniqPosts.map((post, idx) => {
		return (
			<Fragment key={`calendar-post-${idx}`}>
				<div className="col-12 text post__post">
					<Link
						to={makeUrl.post(get(post, "post", {}))}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h2,
							lineHeight: theme.lineHeight.h2,
							color: theme.color.text || get(details, "style.color", ""),
							backgroundColor: theme.color.body,
						})}
					>
						{get(post, "post.title", "")}
					</Link>
				</div>
			</Fragment>
		);
	});

	// Holidays
	const holidays = days_holidays.map((holiday, idx) => {
		return (
			<Link
				to={makeUrl.holiday(get(holiday, "holiday", {}))}
				key={`holiday-${idx}`}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.h2,
					lineHeight: theme.lineHeight.h2,
					color: theme.color.text || get(details, "style.color", ""),
					backgroundColor: theme.color.body,
				})}
			>
				{get(holiday, "holiday.title_full", "")}
			</Link>
		);
	});

	// Святые дня
	const uniqSaints = uniqWith(memorialDays, isEqual);
	const saints = uniqSaints.map((saint, idx) => {
		const titleName = get(saint, "saint.rank.short_name", "");

		return (
			<Link
				key={`calendar-saint-${idx}`}
				to={makeUrl.saint(saint.saint)}
				className={`calendar__saint ${className}__saint`}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.h2,
					lineHeight: theme.lineHeight.h2,
					color: theme.color.text || get(details, "style.color", ""),
					backgroundColor: theme.color.body,
				})}
			>
				<span>
					{`${titleName ? titleName : ""} ${get(saint, "saint.title", "")}${idx === uniqSaints.length - 1 ? ". " : ", "}`}
				</span>
			</Link>
		);
	});

	// Евангельские чтения дня
	const uniqSaintBooks = uniqWith(get(data, "gospels", []), isEqual);

	// Притча
	const uniqParables = uniqWith(get(data, "parables", []), isEqual);
	// Богослужебные указания
	const liturgicalDirections = get(data, "liturgical_directions", []);

	return (
		<div className={`mb-3 calendar ${className}`}>
			{data && (
				<div
					className={"calendar__main-block"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h2,
						lineHeight: theme.lineHeight.h2,
						color: theme.color.text,
					})}
				>
					<div className={"corner corner__left-top"}>
						<img src={corner} />
					</div>
					<div className={"corner corner__left-btm"}>
						<img src={corner} />
					</div>
					<div className={"corner corner__right-top"}>
						<img src={corner} />
					</div>
					<div className={"corner corner__right-btm"}>
						<img src={corner} />
					</div>
					<div
						className={"calendar__main-block_title"}
						style={removeEmptyKeys({
							display: "flex",
							justifyContent: "space-between",
							color: theme.color.text,
						})}
					>
						{dayTitle &&
							<div>
								<h3 className="h3 h3_light text-block__title">
									<span
										className="DD_DATE"
										dangerouslySetInnerHTML={{ __html: dayTitle }}
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.h2,
											lineHeight: theme.lineHeight.h2,
											color: theme.color.text,
										})}
									/>
								</h3>
							</div>
						}
						{details && details.moreDetails && details.moreDetails}
					</div>
					{
						menu ? (
							<div className={"d-flex justify-content-center"}>{ikons}</div>
						) : (
							<div className={"calendar__main-block_icons"} style={{ margin: "30px -33px 0 -33px" }}>
								<Carousel
									type={"SlideIkon"}
									items={[...ikons, ...holidaysImage]}
									mediaSize={["auto", 200]}
									noUpdate
									params={{
										slidesPerView: 2,
										breakpoints: {
											0: {
												slidesPerView: 1,
											},
											768: {
												slidesPerView: ikons.length < 5 ? ikons.length : 4,
											},
											950: {
												slidesPerView: ikons.length < 5 ? ikons.length : 5,
											},
										},
									}}
								/>
							</div>
						)
					}
					{details && details.nextPrevLinks && details.nextPrevLinks}
					<div className={"calendar__main-block_day-calendar"}>
						<div className={"d-flex d-lg-none justify-content-center day-calendar__today"}>
							<span className={"day-calendar__today_title h3"}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.h3,
									lineHeight: theme.lineHeight.h3,
									color: theme.color.text,
									backgroundColor: theme.color.body,
								})}
							>
								{dayName}
							</span>
						</div>
						<div className={"d-flex justify-content-center"}>
							<div className={"day-calendar__date"}>
								<Link
									className={"day-calendar__date_desc"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text,
										backgroundColor: theme.color.body,
									})}
									to={makeUrl.textPage({ slug: "julian-calendar" })}
								>
									Старый стиль
								</Link>
								<span
									className={"day-calendar__date_title"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text,
										backgroundColor: theme.color.body,
									})}
								>
									{dayOldStyle}
								</span>
							</div>
							<div
								className={"d-none d-lg-flex day-calendar__today"}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
									backgroundColor: theme.color.body,
								})}
							>
								<span
									className={"day-calendar__today_title h2"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text,
										backgroundColor: theme.color.body,
									})}
								>
									{dayName}
								</span>
							</div>
							<div
								className={"day-calendar__date"}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
									backgroundColor: theme.color.body,
								})}
							>
								<Link
									className={"day-calendar__date_desc"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text,
										backgroundColor: theme.color.body,
									})}
									to={makeUrl.textPage({ slug: "gregorian-calendar" })}
								>
									Новый стиль
								</Link>
								<span
									className={"day-calendar__date_title"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text,
										backgroundColor: theme.color.body,
									})}
								>
									{dayNewStyle}
								</span>
							</div>
						</div>
					</div>
					<DatePicker
						onChange={(moment, dateString) => navigate(makeUrl.day({
							currentDay: dateString,
							type: "calendar",
						}))}
						defaultPickerValue={moment()}
						suffixIcon={
							<Icon
								id={"datapicker"}
								style={{
									width: 38,
									height: 38,
								}}
								className={"pulse-icon"}
							/>
						}
						locale={locale}
						className={"calendar-data-picker"}
						dropdownClassName={"calendar-data-picker__popup"}
						allowClear={false}
					/>
					<div className="row flex-wrap-xs" style={details && details.style || {}}>
						<div className={"col-12"}>
							<div className={"row post"}>
								{weekTitle && (
									<div
										className="col-8 col-md-6 h4 post__ned"
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.text,
											lineHeight: theme.lineHeight.text,
											color: theme.color.text || get(details, "style.color", ""),
											backgroundColor: theme.color.body,
										})}
									>
										{weekTitle}
									</div>
								)}
								{holidays && (
									<div className={"col-6 mt-3 text post__post"}>
										{holidays}
									</div>
								)}
								{
									foodName && (
										<Link
											to={"/pages/opisanie-postov-i-trapez"}
											style={removeEmptyKeys({
												fontFamily: theme.fontFamily,
												fontSize: theme.fontSize.h2,
												lineHeight: theme.lineHeight.h2,
												color: theme.color.text,
												backgroundColor: theme.color.body,
												textAlign: "center",
											})}
											className="col-6 text post__txt mb-3 mt-3"
										>
											{foodName}
										</Link>
									)
								}
								{days_posts}
								{
									glasName && (
										<div className="col-6 text post__post glas">
											<Link
												to={"/pages/glas"}
												style={removeEmptyKeys({
													fontFamily: theme.fontFamily,
													fontSize: theme.fontSize.text,
													lineHeight: theme.lineHeight.text,
													color: theme.color.text || get(details, "style.color", ""),
												})}
											>
												{glasName}
											</Link>
										</div>
									)
								}
							</div>
							{saints && !!saints.length && <div className={"calendar__para text saints"}>{saints}</div>}
						</div>
					</div>
				</div>
			)}
			{isCalendarPage && data && (
				<>
					<CalendarButtons />
					<SaintBooks items={uniqSaintBooks} details={details} />
					<Parables items={uniqParables} details={details} />
					<LiturgicalDirections items={liturgicalDirections} />
				</>
			)}
		</div>
	);
}

Calendar.propTypes = {
	data: PropTypes.object,
	details: PropTypes.object,
	isCalendarPage: PropTypes.bool,
	className: PropTypes.string,
	menu: PropTypes.bool,
};

Calendar.defaultProps = {
	data: {},
	details: {},
	isCalendarPage: false,
	className: "",
	menu: false,
};
