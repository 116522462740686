import React from "react";
import PropTypes from "prop-types";

import { useThemeContext } from "../../Layout/ThemeContext";
import "./ContentBlock.scss";
import { removeEmptyKeys } from "../../../helpers";

export function ContentBlock({ children, className, id, style, mount }) {
	const { theme } = useThemeContext();

	return mount ? (
		<section id={id} className={`content-block ${className}`} style={Object.assign({}, style, removeEmptyKeys({ backgroundColor: theme.color.body }))}>
			{children}
		</section>
	) : null;
}

ContentBlock.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.object,
	mount: PropTypes.bool,
};

ContentBlock.defaultProps = {
	children: <></>,
	className: "",
	id: null,
	style: {},
	mount: true,
};
