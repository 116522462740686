import React from "react";

import Logo from "../../../Logo";
import { Icon } from "../../../Media";
import Caption from "../../../Caption";
import { useThemeContext } from "../../../Layout/ThemeContext";
import { removeEmptyKeys } from "../../../../helpers";

export default function MobileLogo() {
	const { theme } = useThemeContext();

	return (<Logo
		link={"/"}
		style={{ textDecoration: "none" }}
		caption={<Caption text={"Серафимова земля"} style={removeEmptyKeys({ marginLeft: "0.5rem",
			fontSize: "1rem",
			color: theme.color.text,
			backgroundColor: theme.color.body })} />}
		icon={<Icon id={"logo"} style={removeEmptyKeys({ width: 48,
			height: 48,
			color: theme.color.text,
			backgroundColor: theme.color.body })} />}
	/>);
}
