import React from "react";
import { PureCarousel } from "../../PureReactCarousel";
import { ContentBlock, TextBlock } from "../";
import PropTypes from "prop-types";

export function MoreTopics({ items }) {
	return (
		<ContentBlock key={"linkedItems"} mount={Array.isArray(items) && !!items.length}>
			<div className={"container"}>
				<TextBlock title={"Еще по этой теме"}>
					<PureCarousel
						type={"SlideNews"}
						items={items}
						mediaSize={["100%", 400]}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						params={{
							slidesPerView: 3,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 953,
									slidesPerView: 3,
								},
							],
							btnStyle: {
								top: "40%",
							},
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>
	);
}

MoreTopics.propTypes = {
	items: PropTypes.array,
};

MoreTopics.defaultProps = {
	items: [],
};

