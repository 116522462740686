import postcss from "postcss";
import postcssJs from "postcss-js";
import transform from "css-to-react-native";

export function toJSSObject(cssText) {
	return postcssJs.objectify(postcss.parse(cssText));
}

export function toJSS(cssText) {
	try {
		return toJSSObject(cssText);
	} catch (e) {
		return "Error translating CSS to JSS";
	}
}

export function toRN(cssText) {
	try {
		const output = toJSSObject(cssText);
		const result = Object.keys(output).map(rules => [rules, output[rules]]);

		return JSON.stringify(transform(result), null, 2);
	} catch (e) {
		return "Error translating CSS to RN";
	}
}
