import React from "react";
import { graphql } from "@apollo/react-hoc";
import { flowRight as compose } from "lodash";
import get from "lodash/get";

export default function withPageData(Component, ...QUERIES) {
	class PageData extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	PageData.displayName = `Page(${Component.displayName || Component.name || "Component"})`;

	return compose(
		...QUERIES.map(({ body, name }) =>
			graphql(body, {
				options: ({ pageContext }) => ({
					fetchPolicy: "no-cache",
					variables: {
						slug: get(pageContext, "slug", ""),
					},
				}),
				name,
			}),
		),
	)(PageData);
}

export function withPageDataParams(Component, ...QUERIES) {
	class PageData extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	PageData.displayName = `Page(${Component.displayName || Component.name || "Component"})`;

	return compose(
		...QUERIES.map(({ body, name, variables }) =>
			graphql(body, {
				options: () => ({
					fetchPolicy: "no-cache",
					...variables,
				}),
				name,
			}),
		),
	)(PageData);
}

export function withPageDataSlugParams(Component, ...QUERIES) {
	class PageData extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	PageData.displayName = `Page(${Component.displayName || Component.name || "Component"})`;

	return compose(
		...QUERIES.map(({ body, name, variables }) =>
			graphql(body, {
				options: ({ pageContext = {} }) => ({
					fetchPolicy: "no-cache",
					variables: {
						slug: pageContext.slug || "",
						...variables,
					},
				}),
				name,
			}),
		),
	)(PageData);
}
