import React, { useContext, useState, useEffect } from "react";
import { Button, Icon as AntIcon, Popover, Slider } from "antd";
import { get } from "lodash";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "gatsby";
import { CarouselProvider, Slider as ReactSlider, Slide, ButtonBack, ButtonNext, CarouselContext } from "pure-react-carousel";
import { notification } from "antd";
import ReactPlayer from "react-player";
import { isIOS } from "react-device-detect";

import RadioContext, { RadioContextProvider, RadioConsumer } from "./Context";
import RadioProvider from "./Provider";
import { Icon } from "../../components/Media/Icon";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { GoBack } from "../../components/Buttons";
import { MetaPosition } from "../../components/Meta";
import { makeUrl, removeEmptyKeys } from "../../helpers";

import "./Radio.scss";

function RadioWrapper({ items }) {
	const { theme } = useThemeContext();
	const carouselContext = useContext(CarouselContext);
	const { play, playPause, prevStation, nextStation, currentStationIndex, radio, volume, muted } = useContext(RadioContext);

	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		carouselContext.setStoreState({ currentSlide: currentStationIndex });
	}, [currentStationIndex]);

	useEffect(() => {
		function onChange() {
			setCurrentSlide(carouselContext.state.currentSlide);
		}
		carouselContext.subscribe(onChange);

		return () => carouselContext.unsubscribe(onChange);
	}, [carouselContext]);

	return (
		<>

			<ReactSlider>
				{
					items.map((item, idx) => {
						const main_image = get(item, "main_image.src", "");
						const Image = main_image && <img src={main_image} style={{ width: "100%" }} />;

						return (
							<Slide
								key={`radio-slider-${idx}`}
								index={idx}
								classNameHidden={"radio__slide_hidden"}
								classNameVisible={"radio__slide_visible"}
								className={"radio__slide"}
								innerClassName={"d-flex align-items-center"}
							>
								{
									idx > +currentSlide && (
										<ButtonNext
											onClick={nextStation}
											className={"d-flex align-items-center justify-content-center"}
											style={theme.color.text ? {
												outline: "none",
												backgroundColor: "transparent",
												color: "black",
											} : {
												outline: "none",
												backgroundColor: "transparent",
											}}
										>
											{Image}
											<AntIcon
												type={"step-forward"}
												style={{
													display: "flex",
													position: "absolute",
													fontSize: 50,
												}}
											/>
										</ButtonNext>
									)
								}
								{
									idx === +currentSlide && (
										<div
											className={"d-flex align-items-center justify-content-center"}
											style={{ cursor: "pointer" }}
											onClick={playPause}
										>
											{Image}
											<AntIcon
												className={{ "is-play": play }}
												type={play ? "pause-circle" : "play-circle"}
												style={theme.color.text ? {
													color: "black",
												} : {}}
											/>
										</div>
									)
								}
								{
									idx < +currentSlide && (
										<ButtonBack
											onClick={prevStation}
											className={"d-flex align-items-center justify-content-center"}
											style={theme.color.text ? {
												outline: "none",
												backgroundColor: "transparent",
												color: "black",
											} : {
												outline: "none",
												backgroundColor: "transparent",
											}}
										>
											{Image}
											<AntIcon
												type={"step-backward"}
											/>
										</ButtonBack>
									)
								}
							</Slide>
						);
					},
					)
				}
			</ReactSlider>
			<div className="player-wrapper">
				<ReactPlayer
					key={radio.source}
					playing={play}
					url={radio.source}
					height={0}
					width={0}
					volume={volume}
					muted={muted}
					playsinline
					config={{
						file: { forceAudio: true },
					}}
					className={"react-pizda-player"}
					onError={
						error => {
							console.error("Ошибка воспроизведения потока", error);

							notification.error({
								message: radio.title_full,
								description: "Ошибка воспроизведения потока",
							});
						}
					}
				/>
			</div>
		</>
	);
}

RadioWrapper.propTypes = {
	items: PropTypes.array,
};

RadioWrapper.defaultProps = {
	items: [],
};

RadioWrapper.displayName = "RadioWrapper";

export function RadioWidget({ mobileComponent, goBack }) {
	const { theme } = useThemeContext();

	const { soundOff, muted, volume, setVolume, radioStations, radio, play } = useContext(RadioContext);

	function radioBlock() {
		const title_full = get(radio, "title_full", "");

		return (
			<div
				className={cx(
					"mb-0 radio",
					{ "p-3": !mobileComponent },
					{ "p-0": mobileComponent },
				)}
				style={removeEmptyKeys({
					backgroundColor: theme.color.body,
					color: theme.color.text,
				})}
			>
				<div className={"d-flex justify-content-center"}>
					<div
						className={"mr-3 d-flex justify-content-center flex-column align-items-center"}
						style={{
							flex: 1,
							overflow: "hidden",
						}}
					>
						<div
							style={{
								width: "33%",
							}}
						>
							<CarouselProvider
								naturalSlideWidth={100}
								naturalSlideHeight={125}
								totalSlides={radioStations && radioStations.length}
								visibleSlides={1}
								dragEnabled={false}
								touchEnabled={false}
							>
								<RadioWrapper items={radioStations && radioStations} />
							</CarouselProvider>
						</div>
						<div className={"radio__title"}>
							<Link to={makeUrl.radio(radio)} style={removeEmptyKeys({ color: theme.color.text })}>
								{title_full}
							</Link>
						</div>
					</div>
					<div className={"d-flex justify-content-between align-items-center flex-column"}>
						<Button
							onClick={soundOff}
							type={"link"}
							size={"small"}
							style={
								{
									padding: 0,
									height: "auto",
								}
							}
						>
							<AntIcon
								type={"sound"}
								style={removeEmptyKeys({
									display: "flex",
									color: theme.color.text,
									fontSize: 20,
								})}
								theme={muted ? "" : "filled"}
							/>
						</Button>
						{
							!isIOS && (
								<Slider
									min={0}
									max={1}
									step={0.05}
									vertical
									onChange={setVolume}
									value={volume}
									disabled={muted}
									tooltipVisible={false}
									style={{
										flex: 1,
										margin: "16px 0",
									}}
								/>
							)
						}
						{
							!mobileComponent && (
								<Popover
									placement={"bottomRight"}
									overlayClassName={"radio-dropdown"}
									content={
										<div className={"p-3"}>
											<h5>Элементы управления радио</h5>
											<div>
												<AntIcon
													type={"step-forward"}
													style={removeEmptyKeys({
														color: theme.color.text,
													})} /> Переключение станций вперёд.
											</div>
											<div>
												<AntIcon
													type={"step-backward"}
													style={removeEmptyKeys({
														color: theme.color.text,
													})} /> Переключение станций назад.
											</div>
											<div>
												<AntIcon
													type={"play-circle"}
													style={theme?.color?.text ? {
														color: theme?.color?.text,
													} : {}} /> Воспроизведение.
											</div>
											<div>
												<AntIcon
													type={"pause-circle"}
													style={removeEmptyKeys({
														color: theme.color.text,
													})} /> Пауза.
											</div>
										</div>
									}
									trigger={["click", "hover"]}
									overlayStyle={
										{
											width: 400,
											position: "fixed",
										}
									}
								>
									<Button
										type={"link"}
										size={"small"}
										style={
											{
												padding: 0,
												height: "auto",
											}
										}
									>
										<AntIcon
											type={"question"}
											style={removeEmptyKeys({
												display: "flex",
												color: theme.color.text,
												fontSize: 20,
											})}
										/>
									</Button>
								</Popover>
							)
						}
					</div>
				</div>
			</div>
		);
	}

	return (
		mobileComponent ?
			<div className={"radio-dropdown_mobile"}>
				<div onClick={goBack} className={"d-flex justify-content-between align-items-center radio-dropdown__header"}>
					<GoBack />
					<div
						style={removeEmptyKeys({
							color: theme.color.text,
							fontFamily: theme.fontFamily,
						})}
					>
						Радиовещание
					</div>
				</div>
				{ radioBlock() }
			</div>
			:
			<Popover
				trigger={"click"}
				placement={"bottomRight"}
				overlayClassName={"radio-dropdown"}
				overlayStyle={
					{
						width: 450,
						position: "fixed",
					}
				}
				content={radioBlock()}
			>
				<span
					className={"d-flex top-menu__item link link_ugly link_main-light d-noscript-none"}
					style={{ maxWidth: 60 }}
				>
					{
						!play ? (
							<Icon
								key={"radio"}
								id={"radio"}
								style={{
									width: 28,
									height: 28,
								}}
							/>
						) : (
							<img
								src={get(radio, "main_image.src", "")}
								alt=""
								style={{ width: "100%" }}
							/>
						)
					}
					<MetaPosition content={6} />
				</span>
			</Popover>
	);
}

RadioWidget.propTypes = {
	mobileComponent: PropTypes.bool,
	goBack: PropTypes.func,
};

RadioWidget.defaultProps = {
	mobileComponent: false,
	goBack: () => Function,
};

RadioWidget.displayName = "RadioWidget";

export { RadioContext, RadioContextProvider, RadioConsumer, RadioProvider };
