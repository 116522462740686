import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

import { useThemeContext } from "../../../Layout/ThemeContext";

function RegisterTab({ isActive, type, label }) {
	const { theme } = useThemeContext();

	return (
		<div
			style={{
				color: isActive && theme.color.text,
			}}
		>
			<Icon {...{ type }} />
			{label}
		</div>
	);
}

RegisterTab.propTypes = {
	isActive: PropTypes.bool,
	type: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

export default RegisterTab;
