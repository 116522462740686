import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, navigate } from "gatsby";
import { Menu, Icon as AntIcon, notification } from "antd";
import PropTypes from "prop-types";
import { get, last } from "lodash";
import { useToggle } from "@umijs/hooks";

import SubLevel from "./SubMenu";
import { Icon } from "../../Media";
import Logo from "./Logo";
import { Burger, DotBurger } from "../Burger";
import { formatURL, makeJulianDate, removeEmptyKeys } from "../../../helpers";
import { AuthForm, SignUpForm } from "../../auth";
import { BroadcastWidget, RadioWidget, SearchWidget, BroadcastContext, AuthContext } from "../../../widgets";
import CrippledForMobile from "../../Crippled";
import { BroadcastIndicatorMobile } from "../../../widgets/Broadcast/Indicator";
import { GoBack } from "../../Buttons";
import { MetaPosition } from "../../Meta";
import { useThemeContext } from "../../Layout/ThemeContext";

import "./MainMenu.scss";

export function MobileMenu({ location, links }) {
	// NOTE: uncomment when there will be such a task

	const [isOpen, setIsOpen] = useState({
		dot: false,
		menu: false,
	});

	const [select, setSelect] = useState(null);
	const [dotSelect, setDotSelect] = useState({ opened: false });
	const [data, setData] = useState({
		title: "",
		component: "",
		icon: "",
		sub: [],
		idx: null,
	});
	const { state: auth, toggle: setAuth } = useToggle();
	const { state: signUp, toggle: setSignUp } = useToggle();

	const wrapper = useRef();
	const header = useRef();

	const { theme } = useThemeContext();
	const { enabled, items } = useContext(BroadcastContext);
	const { updateClient, user, signOut } = useContext(AuthContext);

	const isLogin = get(user, "isLogin", false);
	const avatar = get(user, "avatar", null);

	const enbledItem = items.filter(item => get(item, "enabled", ""));

	const indicatorDisabledForClick = enabled && items.length === 1 && last(get(location, "pathname", "").split("/")) === get(enbledItem, "[0].slug", "");

	useEffect(() => {
		document.body.style.overflow = isOpen.menu ? "hidden" : "";
		if (!isOpen.menu) {
			setSelect(null);
		}
	}, [isOpen]);

	useEffect(() => {
		setSelect(select === data.idx ? null : data.idx);
	}, [data]);

	function onSimpleMenuSelect(event, data) {
		event.preventDefault();
		event.stopPropagation();

		setData(data);
	}

	function onSimpleMenuClick() {
		setIsOpen(!isOpen.menu ?
			{ dot: false,
				menu: true } :
			{ dot: false,
				menu: false });
	}

	function handleToggle(event) {
		event.preventDefault();
		event.stopPropagation();
		setIsOpen(!isOpen.menu ?
			{ dot: false,
				menu: true } :
			{ dot: false,
				menu: false });
	}

	function handleToggleDotMenu(event) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		setIsOpen(!isOpen.dot ?
			{ dot: true,
				menu: false } :
			{ dot: false,
				menu: false });
	}

	function handleSetDotSelect(item) {
		setDotSelect(dotSelect.opened ?
			{ opened: false,
				key: null } :
			{ opened: true,
				key: item });
	}

	function logOut() {
		signOut(
			() => {
				localStorage.removeItem("serafim_token");
				notification.info({
					message: "Выход успешно выполнен",
				});
				updateClient();
				handleToggleDotMenu();
				handleSetDotSelect(null);
			},
			error => console.error(error),
		);
	}

	function onSelect() {
		handleToggleDotMenu();
		handleSetDotSelect(null);
	}

	function menu() {
		return (
			<div className={"d-flex justify-content-start user-menu_mobile" }>
				<GoBack onClick={handleSetDotSelect}/>
				<Menu style={removeEmptyKeys({
					backgroundColor: theme.color.body,
					color: theme.color.text,
				})}>
					{!isLogin ?
						<Menu.Item onClick={() => {
							onSelect();
							navigate("/profile");
						}}>
							<AntIcon type={"wallet"} />Мои пожертвования
						</Menu.Item> :
						null
					}
					{!!process.env.GATSBY_SHOP_ENABLE && <Menu.Item>
						<Link to={"/store/#!/~/cart"}>Корзина</Link>
					</Menu.Item>}
					{isLogin && [
						!!process.env.GATSBY_SHOP_ENABLE && <Menu.Item key={"store"}>
							<Link to={"/store/#!/~/accountSettings"}>Мои заказы</Link>
						</Menu.Item>,
						<Menu.Item key={"profile"} onClick={() => {
							onSelect();
							navigate("/profile");
						}}>
						Профиль
						</Menu.Item>,
					]}
					<Menu.Divider />
					{!isLogin ?
						[
							<Menu.Item key={"log-in"} onClick={() => setAuth(true) }>
								<AntIcon type={"login"} /> Войти
							</Menu.Item>,
							<Menu.Item key={"sign-in"} onClick={() => setSignUp(true) }>
								<AntIcon type={"user-add"} /> Зарегистрироваться
							</Menu.Item>,
						]
						: (
							<Menu.Item onClick={logOut}>
								<AntIcon type="logout" /> Выйти
							</Menu.Item>
						)}
				</Menu>
			</div>
		);
	}

	function dotMenuItems() {
		return ["eye", "radio", "media", "search", "user"].map((item, idx) => {
			if (!dotSelect.opened) {
				let profileImg = item === "user" && avatar ? <img src={avatar} width={"100%"} height={"100%"} style={{ objectFit: "cover" }} /> : null;

				return (
					<div
						key={`dot-menu-${idx}`}
						className={"mobile-dots-menu__icon d-flex justify-content-center align-items-center"}
						onClick={() => handleSetDotSelect(item)}
						style={removeEmptyKeys({
							borderColor: theme.color.text,
						})}
					>
						{profileImg || <Icon id={item} />}
					</div>
				);
			}

			return null;
		});
	}

	const dotSubMenu = {
		eye: <CrippledForMobile goBack={handleSetDotSelect} />,
		radio: <RadioWidget mobileComponent goBack={handleSetDotSelect} />,
		media: <BroadcastWidget mobileComponent onClick={onSelect} goBack={handleSetDotSelect} />,
		search: <SearchWidget mobileComponent onClick={onSelect} goBack={handleSetDotSelect} />,
		user: menu(),
	};

	let realScreenHeight = "100vh"; // FIXME: Crutch for android scroll

	if (typeof document !== "undefined") {
		realScreenHeight = `${document.body.offsetHeight}px`;
	}

	return (
		<div className={"d-lg-none position-relative menu-mobile"}>
			<div
				className="container-fluid"
				style={removeEmptyKeys({
					zIndex: 99,
					position: "relative",
					backgroundColor: theme.color.body || "#fff",
				})}
				ref={header}
			>
				<div className={"row"}>
					<div className={"col-6 d-flex align-items-center justify-content-start"}>
						<Logo />
					</div>
					<div className={"col-6 d-flex justify-content-end align-items-center"}>
						<BroadcastIndicatorMobile onClick={indicatorDisabledForClick ? () => Function : () => navigate("/broadcasts")} enabled={enabled} />
						<DotBurger onClick={event => handleToggleDotMenu(event)} isOpen={isOpen.dot}/>
						<Burger onClick={event => handleToggle(event)} isOpen={isOpen.menu} />
					</div>
				</div>
			</div>
			<div
				ref={wrapper}
				className="container-fluid menu-main__wrapper menu-main__wrapper_fast-transition"
				style={Object.assign({}, {
					transform: `translateY(${isOpen.dot ? "0" : "-100%"})`,
					opacity: +isOpen.dot,
					visibility: isOpen.dot ? "visible" : "hidden",
					height: "auto",
					zIndex: 10,
					width: "100%",
					overflow: "initial",
					padding: 0,
				}, removeEmptyKeys({ backgroundColor: theme.color.body,
					borderColor: theme.color.text }))
				}
				itemScope
				itemType="https://schema.org/SiteNavigationElement"
			>
				{
					!dotSelect.opened &&
					<div className="d-flex justify-content-between align-items-center" style={{ padding: 16 }}>
						{ dotMenuItems() }
					</div>
				}
				{ dotSelect.opened && dotSelect.key && dotSubMenu[dotSelect.key] }
			</div>
			<div
				className={"mobile-dots-menu__overlay"}
				style={{
					visibility: isOpen.dot ? "visible" : "hidden",
				}}
				onClick={handleToggleDotMenu}></div>

			<div
				ref={wrapper}
				className="container-fluid menu-main__wrapper"
				style={Object.assign({},
					{
						transform: `translateY(${isOpen.menu ? "0" : "-100%"})`,
						opacity: +isOpen.menu,
						visibility: isOpen.menu ? "visible" : "hidden",
						height: `calc(${realScreenHeight} - ${header && header.current ? header.current.offsetHeight : 0}px)`,
					}, removeEmptyKeys({
						backgroundColor: theme.color.body || "#fff",
						borderColor: theme.color.text,
					}))
				}
				itemScope
				itemType="https://schema.org/SiteNavigationElement"
			>
				<nav
					className={"d-flex flex-column menu-main"}
					style={{
						transform: `translateX(${typeof select === "number" ? "-100%" : "0"})`,
						overflow: "scroll",
						height: "100%",
					}}
				>
					{links.map(({ title, icon, url, sub, component, mobile = true, mobileComponent }, idx) => {
						if (!mobile) {
							return null;
						}

						if (!url) {
							if (component === "CalendarMenu") {
								const calendarUrl = formatURL(`calendar/${makeJulianDate("-")}`);

								return (
									<Link
										to={calendarUrl}
										onClick={() => onSimpleMenuClick(calendarUrl)}
										key={`menu-main__item-${idx}`}
										className={"d-flex align-items-center py-3 menu-main__link link"}
										itemProp="url"
									>
										<Icon id={icon} style={{ width: 28 }} />
										<span
											className={"ml-3 menu-main__text"}
											itemProp="name"
											style={removeEmptyKeys({
												color: theme.color.text,
											})}>
											{title}
										</span>
										<MetaPosition content={idx} />
									</Link>
								);
							}

							return (
								<div
									key={`menu-main__item-${idx}`}
									onClick={event =>
										onSimpleMenuSelect(event, { title,
											icon,
											sub,
											component,
											mobileComponent,
											idx })
									}
									className={"d-flex align-items-center py-3 link menu-main__link"}
								>
									<Icon id={icon} style={{ width: 28 }} />
									<span
										className={"ml-3 menu-main__text"}
										style={removeEmptyKeys({
											color: theme.color.text,
										})}>{title}</span>
									<Icon id={"chevron"} className={"ml-auto"} />
								</div>
							);
						}

						return (
							<Link
								to={formatURL(url)}
								onClick={() => onSimpleMenuClick(url)}
								key={`menu-main__item-${idx}`}
								className={"d-flex align-items-center py-3 menu-main__link link"}
								itemProp="url"
							>
								<Icon id={icon} style={{ width: 28 }} />
								<span
									className={"ml-3 menu-main__text"}
									itemProp="name"
									style={removeEmptyKeys({
										color: theme.color.text,
									})}>
									{title}
								</span>
								<MetaPosition content={idx} />
							</Link>
						);
					})}
					<div key={"menu-main__item-shop"} className={"menu-main__item"}>
						{process.env.GATSBY_SHOP_ENABLE && <Link
							to={"/store"}
							onClick={() => onSimpleMenuClick("/store")}
							className={"d-flex align-items-center py-3 menu-main__link link"}
							itemProp="url"
						>
							<div className={"d-flex justify-content-center"} style={{ width: 28 }}>
								<Icon id={"store"} />
							</div>
							<span
								className={"menu-main__text"}
								style={Object.assign({},
									{
										marginLeft: 12,
										opacity: +isOpen.menu,
									}, removeEmptyKeys({ color: theme.color.text }))
								}
							>
								Магазин
							</span>
						</Link>}
					</div>
				</nav>
				<SubLevel
					key={"menu-sub"}
					headerTitle={data.title}
					component={data.mobileComponent || data.component}
					icon={data.icon}
					sub={data.sub}
					style={{
						transform: `translateX(${typeof select === "number" ? "0" : "100%"})`,
						width: "100%",
					}}
					handleBack={() => setSelect(null)}
					onHandleClick={() => onSimpleMenuClick(true)}
				/>
			</div>
			<AuthForm onClose={() => {
				onSelect(); setAuth(false);
			} } visible={auth} />
			<SignUpForm onClose={() => {
				onSelect(); setSignUp(false);
			} } visible={signUp} />
		</div>
	);
}

MobileMenu.propTypes = {
	links: PropTypes.array,
	location: PropTypes.object,
};

MobileMenu.defaultProps = {
	links: [],
	location: {},
};
