import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { useThemeContext } from "../../Layout/ThemeContext";
import sprite from "../../../sprites/defs/svg/sprite.defs.svg";

import "../../../sprites/defs/sprite.scss";
import "./Icon.scss";
import { removeEmptyKeys } from "../../../helpers";

export function Icon({ id, style, className }) {
	const { theme } = useThemeContext();

	return (
		<svg className={cx(`icon svg-${id} svg-${id}-dims`, className)} style={style}>
			<use
				xlinkHref={`${sprite}#${id}`}
				style={removeEmptyKeys({
					fill: theme.color.text,
				})}
			/>
		</svg>
	);
}

Icon.propTypes = {
	id: PropTypes.string,
	style: PropTypes.object,
	className: PropTypes.string,
};

Icon.defaultProps = {
	id: "",
	style: {},
	className: "",
};
