import React from "react";
import PropTypes from "prop-types";
import Vimeo from "@u-wave/react-vimeo";
import { Link } from "gatsby";
import ReactPlayer from "react-player";

import RutubePlayer from "../RutubePlayer";
import NoScript from "../../NoScript";
import { makeUrl, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";

import "./Video.scss";

export function Video({ videoSrcURL, width, slug, videoTitle, className, kind }) {
	const { theme } = useThemeContext();

	if (!videoSrcURL) {
		return null;
	}

	return (
		<div className={`${className} pb-1 video-wrapper`}>
			<VideoComponent
				videoSrcURL={videoSrcURL}
				kind={kind}
				width={width}
			/>
			<NoScript>
				<p>Чтобы посмотреть видео включите JavaScript</p>
			</NoScript>
			{videoTitle && (
				<Link to={makeUrl.video({ slug })}>
					<div
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
						className={"text mt-3"}
					>
						<p
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h2,
								lineHeight: theme.lineHeight.h2,
								color: theme.color.text,
							})}
							className={"mb-5"}
							dangerouslySetInnerHTML={{ __html: videoTitle }}
						/>
					</div>
				</Link>
			)}
		</div>
	);
}

Video.propTypes = {
	videoSrcURL: PropTypes.string,
	videoTitle: PropTypes.string,
	className: PropTypes.string,
	slug: PropTypes.string,
	width: PropTypes.string,
	kind: PropTypes.string,
};

Video.defaultProps = {
	videoSrcURL: "",
	videoTitle: "",
	className: "",
	slug: "",
	width: "",
	kind: "",
};

function VideoComponent({ videoSrcURL, kind, width }) {
	const { theme } = useThemeContext();

	const isRutubeVideo = /rutube/.test(videoSrcURL);

	if (isRutubeVideo) {
		return (
			<RutubePlayer src={videoSrcURL} />
		);
	}

	if (kind === "vimeo") {
		return (
			<Vimeo
				video={videoSrcURL}
				width={width}
				responsive
			/>
		);
	}

	return (
		<ReactPlayer
			className={"d-noscript-none video-bp"}
			url={videoSrcURL}
			controls
			width={"100%"}
			style={
				{
					minHeight: "200px",
					height: "300px",
					...theme.image,
				}
			}
		/>
	);
}

VideoComponent.propTypes = {
	videoSrcURL: PropTypes.string,
	kind: PropTypes.string,
	width: PropTypes.string,
};
