import React from "react";
import PropTypes from "prop-types";

import { VotingContextProvider } from "../Context";
import { get } from "lodash";
import gql from "graphql-tag";
import { useSubscription } from "@apollo/react-hooks";

const COMPETITION_PROJECT = gql`
	subscription competitionProject {
		competition_projects(order_by: {page_title_full: asc}) {
			author
			main_image
			main_image_preview
			id
			page_title_full
			page_title_short
			sort_order
			seo
			teaser
			updated_at
			votes
			voting_id
		}
	}
`;

export default function VotingProvider({ children }) {
	const { data } = useSubscription(COMPETITION_PROJECT);

	const projects = get(data, "competition_projects", []);

	return (
		<VotingContextProvider
			value={{
				projects,
			}}
		>
			{children}
		</VotingContextProvider>
	);
}

VotingProvider.propTypes = {
	children: PropTypes.array,
};

VotingProvider.defaultProps = {
	children: [],
};
