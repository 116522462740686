/* eslint-disable new-cap */
/* eslint-disable no-undef */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { VKContextProvider } from "../context";

export default function VKProvider({ children }) {
	const [isInit, setIsInit] = useState(false);

	useEffect(() => {
		const script = document.createElement("script");

		script.type = "text/javascript";
		script.src = "https://vk.com/js/api/openapi.js?168";

		script.onload = function VKInit() {
			setIsInit(true);
		};

		document.head.appendChild(script);
	}, []);

	function Post(elementId, ownerId, postId, hash, options) {
		if (isInit) {
			VK.Widgets.Post(elementId, ownerId, postId, hash, options);
		} else {
			console.warn("VK API not initialized");
		}
	}

	function Article(elementId, articleUrl) {
		if (isInit) {
			VK.Widgets.Article(elementId, articleUrl);
		} else {
			console.warn("VK API not initialized");
		}
	}

	return (
		<VKContextProvider
			value={
				{
					isInit,
					Post,
					Article,
				}
			}
		>
			{children}
		</VKContextProvider>
	);
}

VKProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

VKProvider.defaultProps = {
	children: null,
};

VKProvider.displayName = "VKProvider";
