import React, { useContext } from "react";
import { Link } from "gatsby";
import { Dropdown, Badge, Menu } from "antd";
import cx from "classnames";
import { last, get } from "lodash";
import PropTypes from "prop-types";

import { makeUrl, removeEmptyKeys } from "../../helpers";
import BroadcastContext, { BroadcastContextProvider, BroadcastConsumer } from "./Context";
import BroadcastIndicator from "./Indicator";
import BroadcastProvider from "./Provider";
import { Icon } from "../../components/Media";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { GoBack } from "../../components/Buttons";

import "./Broadcast.scss";

export function BroadcastWidget({ mobileComponent, onClick, goBack, location }) {
	const { items, enabled } = useContext(BroadcastContext);
	const { theme } = useThemeContext();

	const enabledBroadcasts = items?.filter?.(item => get(item, "enabled", "")) ?? [];

	function onHandleClick() {
		onClick();
	}

	function broadcastBlock() {
		return (
			<Menu
				style={removeEmptyKeys({ backgroundColor: theme.color.body,
					paddingBottom: mobileComponent ? 12 : "inherit" })}
				className={"broadcast-menu"}
			>
				{
					items?.map?.(({ title_short, title_full, slug, enabled }, idx) => (
						<Menu.Item key={idx}>
							<Link
								to={makeUrl.broadcast({ slug })}
								className={cx("d-flex p-2 d-noscript-none", { "justify-content-start": mobileComponent,
									"justify-content-between": !mobileComponent })}
								onClick={onHandleClick}
							>
								{enabled && (
									<span
										className={cx({ "col-1": !mobileComponent })}
										style={{
											margin: mobileComponent ? "inherit" : "auto",
											textAlign: "center",

										}}
									>
										<Badge color={"red"} status="processing"/>
									</span>
								)}
								<span
									className={cx({ "col-11": enabled && !mobileComponent,
										"col-12": !mobileComponent })}
									style={{
										color: theme.color.text,
									}}
								>
									{title_short || title_full}
								</span>
							</Link>
						</Menu.Item>
					))
				}
			</Menu>
		);
	}

	const indicator = <span
		className={"d-flex top-menu__item link link_ugly link_main-light widget-broadcast"}
		style={
			{
				maxWidth: 60,
				overflow: "hidden",
			}
		}
	>
		{
			enabled ?
				<BroadcastIndicator />
				: (
					<Icon
						id={"media"}
						style={{
							width: 28,
							height: 28,
						}}
					/>
				)
		}
	</span>;

	if (mobileComponent) {
		return (
			<div className={"broadcast_mobile"}>
				<div onClick={goBack} className={"d-flex justify-content-between align-items-center broadcast__header"}>
					<GoBack />
					<div
						style={{
							color: theme.color.text,
							fontFamily: theme.fontFamily,
						}}
					>
						Онлайн-трансляции
					</div>
				</div>
				{ broadcastBlock() }
			</div>
		);
	}

	return enabled && items.length === 1 && last(get(location, "pathname", "").split("/")) === get(enabledBroadcasts, "[0].slug", "") ?
		indicator
		:
		<Dropdown
			trigger={["click"]}
			overlayClassName={"auth-dropdown"}
			placement={"bottomLeft"}
			overlay={broadcastBlock()}
			style={{
				backgroundColor: theme.color.body,
				color: theme.color.text,
			}}
		>
			{indicator}
		</Dropdown>;
}

BroadcastWidget.propTypes = {
	mobileComponent: PropTypes.bool,
	onClick: PropTypes.func,
	goBack: PropTypes.func,
	location: PropTypes.object,
};

BroadcastWidget.defaultProps = {
	mobileComponent: false,
	onClick: () => Function,
	goBack: () => Function,
	location: {},
};

export { BroadcastContext, BroadcastContextProvider, BroadcastConsumer, BroadcastProvider };

