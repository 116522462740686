import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { formatURL, removeEmptyKeys } from "../../../../helpers";
import { useThemeContext } from "../../../Layout/ThemeContext";
import { MetaTelephone, MetaAddress, MetaName } from "../../../../components/Meta";

export default function SubMenu({ items, isOpen }) {
	const group = useRef(null);
	const { theme } = useThemeContext();
	const [height, setHeight] = useState(null);

	useEffect(() => {
		setHeight(group.current.offsetHeight);
	}, [height]);

	return (
		<div className={"menu-footer__sub-wrapper"} style={{ height: height && (isOpen ? height : 0) }}>
			<div ref={group} className={"menu-footer__sub"}>
				{items.map(({ url, title = "", telephone, organization, address, itemprops }, idx) => {
					return (
						<div key={`menu-footer__sub-item-${idx}`} className={"menu-footer__sub-item"}>
							{url ? (
								<Link
									to={formatURL(url)}
									className={"link_light menu-footer__sub-link"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										color: theme.color.text,
									})}
								>
									{title}
								</Link>
							) : (
								<div
									className={"menu-footer__text pb-5"} /* FIXME: Need to fix this crutch pb-5 */
									itemScope
									itemType="https://schema.org/Organization"
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										color: theme.color.text,
									})}
								>
									{title}
									{
										itemprops && (
											<>
												<MetaAddress content={address} />
												<MetaName content={organization} />
												<MetaTelephone content={telephone} />
											</>
										)
									}
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
}

SubMenu.propTypes = {
	items: PropTypes.array,
	isOpen: PropTypes.bool,
};

SubMenu.defaultProps = {
	items: [],
	isOpen: false,
};
