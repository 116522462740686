import * as ws from "ws";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import fetch from "isomorphic-fetch";

let token = process.env.GATSBY_HASURA_GUEST_TOKEN;
let headers = {};

// if (typeof window !== "undefined" && localStorage.getItem("serafim_token")) {
// 	token = localStorage.getItem("serafim_token");
// }

// if (process.env.GATSBY_HASURA_ENV === "test") {
// 	headers = {
// 		"x-hasura-admin-secret": process.env.GATSBY_HASURA_SECRET,
// 		"content-type": "application/json",
// 		"credentials": "include",
// 	};
// } else {
// 	headers = {
// 		"Authorization": `Bearer ${token}`,
// 		"content-type": "application/json",
// 		"credentials": "include",
// 	};
// }

export default function createClient() {
	if (typeof window !== "undefined" && localStorage.getItem("serafim_token")) {
		token = localStorage.getItem("serafim_token");
	}

	headers = {
		"Authorization": `Bearer ${token}`,
		"content-type": "application/json",
		"credentials": "include",
	};

	const httpLink = createHttpLink({
		uri: process.env.GATSBY_HASURA_ENDPOINT,
		headers,
		fetchOptions: {
			credentials: "include",
		},
	});
	const wsLink = new WebSocketLink({
		uri: process.env.NODE_ENV === "test" ? "wss://localhost:8000" : process.env.GATSBY_HASURA_WS_ENDPOINT,
		options: {
			reconnect: true,
			connectionParams() {
				return {
					headers,
				};
			},
		},
		webSocketImpl: typeof window === "undefined" ? ws : WebSocket,
	});
	const link = split(
		({ query }) => {
			const { kind, operation } = getMainDefinition(query);

			return kind === "OperationDefinition" && operation === "subscription";
		},
		wsLink,
		httpLink,
	);

	return new ApolloClient({
		link,
		cache: new InMemoryCache(),
		fetch,
	});
}

export function alternateClient() {
	const cache = new InMemoryCache();
	const link = createHttpLink({
		uri: process.env.GATSBY_HASURA_ENDPOINT,
		headers,
		fetchOptions: {
			credentials: "include",
		},
	});

	return new ApolloClient({
		cache,
		link,
		defaultOptions: {
			watchQuery: {
				fetchPolicy: "cache-and-network",
			},
		},
	});
}
