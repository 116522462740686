import { createContext } from "react";

export const broadcast = [];

export const BroadcastContext = createContext(
	{
		broadcast,
		changeIsBroadcast: () => Function,
	},
);

BroadcastContext.displayName = "BroadcastContext";

export const BroadcastContextProvider = BroadcastContext.Provider;
export const BroadcastConsumer = BroadcastContext.Consumer;
export default BroadcastContext;
