import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import cx from "classnames";

import { Icon } from "../../Media";
import NoScript from "../../NoScript";
import { MetaPosition } from "../../Meta";

export default function NoScriptMenu({ links }) {
	return (
		<>
			{links.map(({ url = "", title = "", icon, mobileOnly = "off" }, idx) => {
				if (mobileOnly) {
					return null;
				}

				return (
					<div key={`menu-desktop__item-${idx}`} className={"menu-desktop__item"}>
						{url &&
						<Link
							to={url}
							className={"d-flex align-items-center justify-content-start link link_ugly link_main-light menu-desktop__link"}
							itemProp="url"
						>
							<div
								className={"d-flex justify-content-center"}
								style={{ width: 28 }}
							>
								<Icon id={icon}/>
							</div>
							<span
								className={"menu-desktop__text"}
								style={{
									marginLeft: 12,
									opacity: 0.9,
								}}
								itemProp="name"
							>
								{title}
							</span>
							<Icon
								id={"chevron"}
								className={cx({ "d-none": !!url })}
								style={{
									position: "absolute",
									marginRight: 24,
									right: 0,
								}}
							/>
							<MetaPosition content={idx}/>
						</Link>
						}
					</div>
				);
			})}
			<div key={"menu-desktop__item-shop"} className={"menu-desktop__item"}>
				{process.env.GATSBY_SHOP_ENABLE && <Link
					to={"/store"}
					className={cx(
						"d-flex align-items-center justify-content-start link link_ugly link_main-light menu-desktop__link",
					)}
					itemProp="url"
				>
					<div className={"d-flex justify-content-center"} style={{ width: 28 }}>
						<Icon id={"store"} />
					</div>
					<NoScript>
						<span
							className={"menu-desktop__text"}
							style={{ marginLeft: 12 }}
						>
									Магазин
						</span>
					</NoScript>
				</Link>}
			</div>
		</>
	);
}
NoScriptMenu.propTypes = {
	links: PropTypes.array,
};

