export const makeDonate = "Сделать пожертвование";
export const donateAim = {
	label: "Цели пожертвования",
	placeholder: "Выберите цели пожертвования",
};
export const donate = "Пожертвовать";
export const cancel = "Отменить";
export const messageForAnonymous = "При анонимном пожертвовании мы не сможем отослать Вам подарки.";
export const anonymDonate = "Анонимное пожертвование";
export const entity = "Юридическое лицо";
export const formMessages = {
	firstName: {
		label: "Имя",
		placeholder: "Введите ваше имя",
	},
	email: {
		label: "Email",
		placeholder: "Введите ваш E-mail",
	},
	phone: {
		label: "Телефон",
		placeholder: "Введите ваш телефон",
	},
	fund: {
		placeholder: "Сумма",
	},
	address: {
		label: "Адрес доставки",
		placeholder: "Введите адрес доставки",
	},
};
export const showMore = "Посмотреть еще";
export const noData = "Нет данных";

export const ymNumber = 56949043;
