/* eslint-disable */
import React, { Component } from "react";

export default class SVGMap extends Component {
	render() {
		return (
			<svg width={"100%"} height={"100%"} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3710.000000 1520.000000" preserveAspectRatio="xMidYMid meet" style={{ maxHeight: "100%",
				maxWidth: "100%" }}>
				<style type="text/css">
					{
						`.firstb { fill: red; opacity:0.4; transition: opacity.3s ease;}
					.firstb:hover { fill: red; opacity:0.8;cursor:pointer}
					.secondb { fill: yellow; }
					`
					}
				</style>
				<g transform="translate(0.000000,1520.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
					<path onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="diveevo" data-id="1" d="M11860 5291 c-72 -44 -109 -54 -136 -36 -12 8 -40 17 -61 21 -61 9
				-65 -5 -36 -118 l26 -96 -22 -23 c-11 -12 -21 -33 -21 -46 0 -20 -4 -22 -37
				-20 -34 2 -38 0 -38 -23 0 -19 10 -29 52 -50 32 -15 51 -31 50 -40 -1 -8 -23
				-25 -49 -38 -39 -18 -48 -27 -48 -48 0 -19 7 -29 30 -37 28 -11 30 -15 30 -63
				0 -28 3 -73 7 -99 l6 -47 34 6 c111 20 99 22 113 -20 24 -75 26 -76 68 -58 34
				14 41 14 89 -2 44 -15 58 -16 86 -6 18 6 54 15 79 18 l45 6 17 99 16 98 125
				190 c116 176 134 209 120 223 -3 3 -65 -5 -138 -18 -73 -13 -135 -24 -139 -24
				-3 0 -13 19 -20 42 -12 35 -20 44 -49 51 -36 9 -79 43 -79 63 0 6 7 17 15 24
				19 16 19 45 -1 75 -22 34 -75 32 -134 -4z"/>
					<path onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="sarov" data-id="2" d="M12551 3446 c-11 -13 -45 -167 -57 -251 -4 -27 -15 -83 -25 -124 -11
				-41 -22 -118 -25 -170 -6 -86 -4 -102 16 -149 l23 -52 -28 -40 c-23 -33 -27
				-47 -22 -77 6 -34 3 -42 -31 -76 l-37 -38 18 -30 c40 -71 13 -159 -94 -295
				-41 -53 -87 -121 -104 -152 -38 -73 -78 -95 -152 -87 -69 8 -112 -14 -216
				-114 -45 -43 -104 -93 -132 -111 -56 -37 -77 -59 -69 -71 6 -10 333 -12 357
				-3 13 5 14 22 10 101 -6 86 -4 97 12 109 23 17 119 18 197 2 l58 -12 0 -88 c0
				-94 2 -98 53 -79 13 5 59 42 103 83 158 146 254 221 254 199 0 -7 5 -9 10 -6
				6 3 10 14 10 24 0 12 7 17 23 17 20 -1 22 5 28 64 3 36 9 71 13 78 10 16 43
				15 49 -2 3 -7 21 -16 40 -20 28 -5 37 -14 55 -54 18 -41 22 -73 27 -242 3
				-107 8 -241 11 -298 l6 -103 117 3 116 3 3 46 3 46 83 23 82 22 365 -182 c200
				-100 365 -183 366 -184 2 -2 -21 -84 -31 -112 -4 -10 -80 -2 -198 20 l-28 6 0
				-400 0 -400 575 0 575 0 0 278 c0 153 -3 388 -7 521 l-6 241 -324 387 -323
				387 -162 265 c-90 146 -170 273 -178 281 -8 8 -196 96 -417 194 l-401 180 6
				55 c4 31 7 76 7 100 l0 44 -297 127 c-221 95 -300 125 -307 116z"/>
					<path onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="arzamas" data-id="3" d="M19342 13778 c-7 -7 -12 -21 -12 -31 0 -16 -7 -18 -42 -17 -71 4 -78
				2 -78 -25 0 -24 7 -33 45 -59 18 -13 17 -15 -22 -26 -36 -11 -49 -10 -90 4
				-74 27 -97 29 -136 12 -38 -15 -40 -18 -91 -146 -34 -84 -34 -116 1 -144 15
				-11 34 -22 43 -22 8 -1 28 -7 43 -13 16 -6 54 -11 85 -11 43 0 61 5 78 21 15
				14 29 19 43 14 22 -7 51 -52 51 -79 0 -23 -53 -46 -105 -46 -49 0 -50 -1 -45
				-75 4 -65 45 -82 107 -44 31 19 35 20 57 5 13 -8 38 -39 55 -68 17 -29 31 -47
				31 -40 0 21 16 13 56 -28 21 -22 51 -45 66 -51 57 -23 85 -136 53 -212 -21
				-52 -19 -97 9 -150 32 -60 56 -140 57 -191 1 -50 57 -145 111 -188 57 -47 111
				-47 221 -3 15 6 17 1 17 -37 0 -81 54 -102 97 -39 28 41 29 71 4 102 -24 31
				-16 50 43 114 45 49 48 50 104 50 42 -1 69 -7 102 -26 46 -25 50 -33 75 -151
				5 -24 10 -28 40 -28 27 0 35 4 35 18 0 13 12 23 42 31 23 7 58 24 77 37 20 13
				40 24 46 24 5 0 26 14 45 30 27 23 45 30 77 30 58 0 95 35 142 134 39 82 61
				189 41 201 -5 3 -27 1 -47 -4 -21 -6 -102 -13 -180 -17 l-143 -7 0 -34 c0 -35
				-23 -54 -42 -35 -6 6 -20 35 -33 65 -22 54 -22 55 -2 92 14 27 17 49 13 76 -5
				31 -3 40 13 49 30 16 92 11 136 -10 58 -28 76 -25 114 20 20 24 43 40 55 40
				30 0 66 -27 66 -50 0 -28 27 -50 61 -50 l30 0 -5 78 c-3 42 -8 127 -11 187 -7
				117 -13 155 -25 155 -4 0 -10 -20 -12 -45 -4 -33 -16 -57 -47 -94 l-41 -49
				-85 -7 c-69 -6 -85 -11 -90 -26 -3 -10 -23 -23 -43 -30 -20 -6 -44 -18 -54
				-26 -14 -13 -17 -12 -23 9 -4 13 -26 68 -50 123 -30 67 -51 137 -64 210 -26
				139 -27 135 7 135 39 0 43 7 28 45 -13 29 -12 36 4 63 10 16 34 42 54 57 19
				15 38 34 41 44 3 9 28 27 55 38 27 12 53 28 56 37 8 22 -13 46 -42 46 -13 0
				-28 9 -34 20 -7 13 -21 20 -38 20 -30 0 -106 -32 -116 -49 -12 -18 -148 -104
				-202 -127 -29 -11 -64 -31 -79 -42 -15 -12 -34 -22 -41 -22 -7 0 -42 -18 -76
				-41 -57 -37 -68 -40 -114 -37 -36 3 -63 14 -98 37 -50 35 -55 54 -15 59 69 8
				70 9 67 60 l-3 47 -46 10 c-30 6 -61 23 -87 47 -45 40 -81 55 -109 43 -10 -4
				-58 -9 -108 -10 -75 -3 -93 0 -114 16 -28 22 -42 24 -59 7z"/>
					<polygon onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="viezdnoe" data-id="4" points="18900 12500, 19100 12900, 19500 12900, 19500 12090, 19380 12090, 19150 12450"/>
					<polygon onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="orehovec" data-id="5" points="17100 9650, 17400 9450, 17450 9250, 17300 9250, 17000 9350, 17120 9500"/>
					<polygon onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="veryahushki" data-id="6" points="16480 8000, 16750 8050, 16850 7900, 16700 7550, 16400 7450, 16450 7650"/>
					<polygon onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="gluhovo" data-id="7" points="15500 6600, 15900 6650, 15950 6050, 15750 6000, 15500 6150"/>
					<polygon onMouseEnter={event => this.props.onMouseEnter(event)} onMouseMove={event => this.props.onMouseEnter(event)} onMouseLeave={event => this.props.onMouseLeave(event)} onClick={event => this.props.onClick(event)} className="firstb" id="elizarievo" data-id="8" points="12800 5400, 13000 5450, 13100 5300, 12850 5150, 12750 5200"/>
				</g>
			</svg>
		);
	}
}
