const Moment = require("moment");

export * from "./validators";
export * from "./formBWtheme";

export function countdown(dateTime = new Date().toJSON(), setState = () => Function) {
	const countDownDate = new Moment(dateTime);

	let x = setInterval(() => {
		const now = new Moment();
		const distance = Moment.duration(countDownDate.diff(now));

		let days = Math.floor(distance / (1000 * 60 * 60 * 24));
		days = days > 0 ? `${String(days).padStart(2, "0")} д.` : "";
		const hours = String(Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))).padStart(2, "0");
		const minutes = String(Math.floor(distance % (1000 * 60 * 60) / (1000 * 60))).padStart(2, "0");
		const seconds = String(Math.floor(distance % (1000 * 60) / 1000)).padStart(2, "0");

		if (distance < 0) {
			clearInterval(x);
			setState(null);
		} else {
      if (days < 30) {
			  setState(`${days} ${hours} ч. ${minutes} м. ${seconds} с.`);
      } else {
			  setState(null);
      }
		}
	}, 1000);

	return x;
}
