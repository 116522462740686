import React from "react";

export const formBWtheme = (isReturn, color) => isReturn && (
	<style>
		{`
            input.ant-input::placeholder {
                color: ${color};
            }
            .ant-input-group-addon {
                color: ${color};
            }
        `}
	</style>
);
