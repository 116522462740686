import React from "react";
import { Tweet } from "react-twitter-widgets";
import PropTypes from "prop-types";

import "./TwitterPost.scss";

export default function TwitterPost({ url }) {
	return (
		<div className={"twitter-post"}>
			<Tweet tweetId={url} />
		</div>
	);
}

TwitterPost.displayName = "TwitterPost";

TwitterPost.propTypes = {
	url: PropTypes.string,
};

TwitterPost.defaultProps = {
	url: "",
};
