import React, { useContext } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { formatURL } from "../../helpers";

import { SiteMetadataContext } from "../../widgets";

export function MetaAddress({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const defaultAddress = get(siteMetadata, "metaAddress", "");

	return (
		<meta itemProp="address" content={content || defaultAddress} />
	);
}

MetaAddress.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaAddress.defaultProps = {
	content: "",
};

export function MetaAuthor({ content }) {
	return (
		<meta itemProp="author" content={content} />
	);
}

MetaAuthor.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaAuthor.defaultProps = {
	content: "",
};

export function MetaDateDateCreated({ content }) {
	return (
		<meta itemProp="dateCreated" content={content} />
	);
}

MetaDateDateCreated.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaDateDateCreated.defaultProps = {
	content: "",
};

export function MetaDateModified({ content }) {
	return (
		<meta itemProp="dateModified" content={content} />
	);
}

MetaDateModified.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaDateModified.defaultProps = {
	content: "",
};

export function MetaDatePublished({ content }) {
	return (
		<meta itemProp="datePublished" content={content} />
	);
}

MetaDatePublished.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaDatePublished.defaultProps = {
	content: "",
};

export function MetaDescription({ content }) {
	return (
		<meta itemProp="description" content={content} />
	);
}

MetaDescription.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaDescription.defaultProps = {
	content: "",
};

export function MetaDuration({ content }) {
	return (
		<meta itemProp="duration" content={content} />
	);
}

MetaDuration.propTypes = {
	content: PropTypes.string,
};

MetaDuration.defaultProps = {
	content: "T20M33S",
};

export function MetaGeo({ content }) {
	const [lon, lat] = Array.isArray(content) ? content : ["error in MetaGeo props", "error in MetaGeo props"];

	if (lat && lon) {
		return (
			<div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
				<meta itemProp="latitude" content={lat} />
				<meta itemProp="longitude" content={lon} />
			</div>
		);
	}

	return null;
}

MetaGeo.propTypes = {
	content: PropTypes.array,
};

MetaGeo.defaultProps = {
	content: [],
};

export function MetaImage({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const defaultImage = get(siteMetadata, "metaImage", "");

	return (
		<link itemProp="image" href={content || defaultImage} />
	);
}

MetaImage.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaImage.defaultProps = {
	content: "",
};

export function MetaMainEntity({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const href = formatURL(typeof content === "string" ? content : "MetaMainEntity is not string", get(siteMetadata, "siteUrl", process.env.REACT_APP_BASE_FRONT_URL));

	return (
		<link itemProp="mainEntity" href={href} />
	);
}

MetaMainEntity.propTypes = {
	content: PropTypes.string,
};

MetaMainEntity.propTypes = {
	content: "",
};

export function MetaMainEntityOfPage({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const href = formatURL(typeof content === "string" ? content : "MetaMainEntityOfPage is not string", get(siteMetadata, "siteUrl", process.env.REACT_APP_BASE_FRONT_URL));

	return (
		<link itemProp="mainEntityOfPage" href={href} />
	);
}

export function MetaIdentifier({ content }) {
	return (
		<meta itemProp={"identifier"} content={content} />
	);
}

MetaMainEntityOfPage.propTypes = {
	content: PropTypes.string,
};

MetaMainEntityOfPage.propTypes = {
	content: "",
};

MetaIdentifier.propTypes = {
	content: "",
};

export function MetaIsFamilyFriendly({ content }) {
	return (
		<meta itemProp="isFamilyFriendly" content={content} />
	);
}

MetaIsFamilyFriendly.propTypes = {
	content: PropTypes.bool,
};

MetaIsFamilyFriendly.propTypes = {
	content: true,
};

export function MetaName({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const defaultName = get(siteMetadata, "metaName", "");

	return (
		<meta itemProp="name" content={content || defaultName} />
	);
}

MetaName.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaName.defaultProps = {
	content: "",
};

export function MetaOpeningHours({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const defaultHours = get(siteMetadata, "metaOpeningHours", "");

	return (
		<meta itemProp="openingHours" content={content || defaultHours} />
	);
}

MetaOpeningHours.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaOpeningHours.defaultProps = {
	content: "",
};

export function MetaPosition({ content }) {
	return (
		<meta itemProp="position" content={content} />
	);
}

MetaPosition.propTypes = {
	content: PropTypes.number.isRequired,
};

MetaPosition.defaultProps = {
	content: "",
};

export function MetaPublicAccess({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const defaultPublicAccess = get(siteMetadata, "metaPublicAccess", "");

	return (
		<meta itemProp="publicAccess" content={content || defaultPublicAccess} />
	);
}

MetaPublicAccess.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaPublicAccess.defaultProps = {
	content: "",
};

export function MetaTelephone({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const defaultTelephone = get(siteMetadata, "metaTelephone", "");

	return (
		<meta itemProp="telephone" content={content || defaultTelephone} />
	);
}

MetaTelephone.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaTelephone.defaultProps = {
	content: "",
};

export function MetaTouristType({ content }) {
	const { siteMetadata } = useContext(SiteMetadataContext);
	const defaultTouristType = get(siteMetadata, "touristType", "");

	return (
		<meta itemProp="touristType" content={content || defaultTouristType} />
	);
}

MetaTouristType.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaTouristType.defaultProps = {
	content: "",
};

export function MetaTranscript({ content }) {
	return (
		<meta itemProp="transcript" content={content} />
	);
}

MetaTranscript.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaTranscript.defaultProps = {
	content: "",
};

export function MetaUploadDate({ content }) {
	return (
		<meta itemProp="uploadDate" content={content} />
	);
}

MetaUploadDate.propTypes = {
	content: PropTypes.string.isRequired,
};

MetaUploadDate.defaultProps = {
	content: "",
};

export default function Meta() {
	return (
		<>
			<MetaAddress />
			<MetaAuthor />
			<MetaDateDateCreated />
			<MetaDateModified />
			<MetaDatePublished />
			<MetaDescription />
			<MetaDuration/>
			<MetaGeo/>
			<MetaImage/>
			<MetaMainEntity/>
			<MetaMainEntityOfPage/>
			<MetaIsFamilyFriendly/>
			<MetaName />
			<MetaOpeningHours/>
			<MetaPosition/>
			<MetaPublicAccess/>
			<MetaTelephone/>
			<MetaTouristType/>
			<MetaTranscript/>
			<MetaUploadDate/>
		</>
	);
}

Meta.propTypes = {
	data: PropTypes.object,
};

Meta.defaultProps = {
	data: {},
};
