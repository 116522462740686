import React from "react";
import PropTypes from "prop-types";

import { BroadcastContextProvider } from "../Context";
import { get } from "lodash";
import gql from "graphql-tag";
import { useSubscription } from "@apollo/react-hooks";

const BROADCAST = gql`
	subscription broadcast {
		v_broadcasts {
			enabled
			slug
			title_short
			title_full
		}
	}
`;

export default function BroadcastProvider({ children }) {
	const { data } = useSubscription(BROADCAST);

	const items = get(data, "v_broadcasts", []);
	const enabled = get(data, "v_broadcasts", []).some(item => item.enabled);

	return (
		<BroadcastContextProvider
			value={{
				items,
				enabled,
			}}
		>
			{children}
		</BroadcastContextProvider>
	);
}

BroadcastProvider.propTypes = {
	children: PropTypes.array,
};

BroadcastProvider.defaultProps = {
	children: [],
};
