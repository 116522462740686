import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { get } from "lodash";
import { Button } from "antd";

import Calendar from "../../components/Calendar";
import { PreloaderCubeGrid } from "../../components/Preloader";
import { makeJulianDate, removeEmptyKeys } from "../../helpers";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import "./Calendar.scss";

const getCalendar = gql`
	query calendarDataQuery($currentDay: date!) {
		days(where: { date: { _eq: $currentDay } }) {
			date
			name
			old_date_style
			new_date_style
			day: title
			week {
				title
			}
			glas_name
			days_posts {
				post {
					title
					description
					type
					slug
				}
			}
			memorial_days(where: {saint: {in_calendar: {_eq: true}}}) {
				saint {
					title
					rank {
						short_name
					}
					slug
					type

					ikons_saints {
						ikon {
							title_full
							slug
							type
							main_image: main_image_preview
						}
					}
				}
			}
			gospels {
		      	gospel_kind {
		        	title
		      	}
		      	saint_text {
		        	saint_book {
			          	slug
			          	name
		        	}
		      	}
		    }
			food {
				name
			}
			days_holidays {
				holiday {
					title_full
					slug
				}
			}
		}
	}
`;

export default function CalendarR(props) {
	const { theme } = useThemeContext();

	const [calendarData, setCalendarData] = useState({});

	const currentDay = makeJulianDate("-");

	const { loading, error, data } = useQuery(getCalendar, {
		variables: { currentDay },
	});

	useEffect(() => {
		if (!error) {
			setCalendarData(get(data, "days[0]", {}));
		}
	}, [loading]);

	if (loading) {
		return <PreloaderCubeGrid />;
	}
	if (error) {
		return null;
	}

	function handleClick(event) {
		event.preventDefault();
		props.details.handleClose();
		navigate(`/calendar/${get(calendarData, "date", "")}`);
	}
	const style = { color: "#fff" };
	const moreDetails = (
		<div>
			<Button
				className={"calendar_details-link button-accent"}
				onClick={event => handleClick(event)}
				style={removeEmptyKeys({
					color: theme.color.body,
					backgroundColor: theme.color.text,
				})}
			>
				Подробнее
			</Button>
		</div>
	);

	return <Calendar data={calendarData} details={{ moreDetails,
		style }} menu className={"menu-calendar"} />;
}

CalendarR.propTypes = {
	details: PropTypes.object,
};
CalendarR.defaultProps = {
	details: {
		handleClose: () => Function,
	},
};
