import React from "react";

import * as Tour from "./Tours";
import PropTypes from "prop-types";

export function VirtualTourBlock(props) {
	const {
		component,
		settings,
		preview,
	} = props;

	return React.createElement(Tour[component] || Tour.TourVirtualBlock, {
		settings,
		preview,
	});
}
VirtualTourBlock.propTypes = {
	component: PropTypes.string,
	settings: PropTypes.object,
	preview: PropTypes.string,
};
