import React, { useContext } from "react";
import InstagramEmbed from "react-instagram-embed";
import PropTypes from "prop-types";
import SiteMetadataContext from "../../../widgets/SiteMetadata";
import get from "lodash/get";

import "./index.scss";

export default function InstaPost({ url }) {
	const { globalSettings } = useContext(SiteMetadataContext);

	return (
		<div className={"insta-post"}>
			<InstagramEmbed
				url={url}
				clientAccessToken={get(globalSettings, "socials.instagramAppId", "")}
				maxWidth={600}
				hideCaption={false}
				containerTagName="div"
				protocol=""
				injectScript
			/>
		</div>
	);
}

InstaPost.displayName = "InstaPost";

InstaPost.propTypes = {
	url: PropTypes.string,
};

InstaPost.defaultProps = {
	url: "",
};
