import React from "react";
import PropTypes from "prop-types";

import { useThemeContext } from "../Layout/ThemeContext";

import "./Caption.scss";
import { removeEmptyKeys } from "../../helpers";

export default function Caption({ text, style, slug }) {
	const { theme } = useThemeContext();

	return (
		<div className={"caption"} style={style}>
			{slug === "/" ? <h1 style={removeEmptyKeys({
				color: theme.color.text,
			})}>{text}</h1> : <span style={removeEmptyKeys({
				color: theme.color.text,
			})}>{text}</span>}
		</div>
	);
}

Caption.propTypes = {
	text: PropTypes.string,
	style: PropTypes.object,
	slug: PropTypes.string,
};

Caption.defaultProps = {
	text: "",
	style: {},
	slug: "",
};
