import React, { useReducer, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { AudioguideContextProvider } from "../Context";
import { RadioContext } from "../../index";

export default function AudioguideProvider({ children }) {
	const { stop: stopRadio, play: playsRadio, fwdRef } = useContext(RadioContext);

	function init() {
		return {
			plays: false,
			loading: true,
			disabled: true,
			slug: "",
		};
	}

	function reducer(state, { type, payload }) {
		switch (type) {
			case "plays":
				return {
					...state,
					plays: payload,
				};
			case "slug":
				return {
					...state,
					slug: payload,
				};
			case "loading":
				return {
					...state,
					loading: payload,
				};
			case "disabled":
				return {
					...state,
					disabled: payload,
				};
			case "error":
				return {
					...state,
					error: payload,
				};
			case "reset":
				return init();
			default:
				throw new Error("Not found the method or unexpected problem");
		}
	}

	const [state, dispatch] = useReducer(reducer, {}, init);

	useEffect(() => {
		fwdRef.current = [
			...fwdRef.current,
			() => {
				dispatch({
					type: "slug",
					payload: "",
				});
				dispatch({
					type: "plays",
					payload: false,
				});
			},
		];
	}, []);

	return (
		<AudioguideContextProvider
			value={{
				state,
				onReady() {
					dispatch({
						type: "disabled",
						payload: false,
					});
				},
				onEnded() {
					dispatch({
						type: "slug",
						payload: "",
					});
					dispatch({
						type: "plays",
						payload: false,
					});
				},
				onBuffer() {
					dispatch({
						type: "loading",
						payload: true,
					});
				},
				onBufferEnd() {
					dispatch({
						type: "loading",
						payload: false,
					});
				},
				onError(error) {
					dispatch({
						type: "error",
						payload: error,
					});
				},
				onPlay(slug) {
					if (playsRadio) {
						stopRadio();
					}
					dispatch({
						type: "plays",
						payload: (() => {
							if (state.slug === slug) {
								return !state.plays;
							}

							return state.slug !== slug;
						})(),
					});
					dispatch({
						type: "slug",
						payload: slug,
					});
				},
			}}
		>
			{children}
		</AudioguideContextProvider>
	);
}

AudioguideProvider.propTypes = {
	children: PropTypes.element.isRequired,
};

AudioguideProvider.defaultProps = {
	children: <></>,
};
