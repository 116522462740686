import React from "react";
import { Radio } from "antd";
import PropTypes from "prop-types";

import { useThemeContext } from "../Layout/ThemeContext";
import { GoBack } from "../../components/Buttons";

import "./Crippled.scss";
import { removeEmptyKeys } from "../../helpers";

export default function Crippled({ goBack }) {
	const { theme, changeFontFamily, changeImage, changeFontSize, changeLineHeight, changeBgColor } = useThemeContext();

	return (
		<div className={"crippled_mobile"}>
			<div onClick={goBack} className={"d-flex justify-content-between align-items-center crippled__header"}>
				<GoBack />
				<div
					style={removeEmptyKeys({
						color: theme.color.text,
						fontFamily: theme.fontFamily,
					})}
				>
					Настройки для слабовидящих
				</div>
			</div>

			<div className={"d-flex justify-content-between align-items-baseline crippled__item"}
				style={removeEmptyKeys({
					borderColor: theme.color.text,
				})}>
				<span
					style={removeEmptyKeys({
						color: theme.color.text,
						fontFamily: theme.fontFamily,
					})}
				>
					Шрифт
				</span>
				<Radio.Group
					buttonStyle={"solid"}
					style={{
						display: "flex",
						marginBottom: "8px",
					}}
					value={theme.fontFamilyValue}
					onChange={changeFontFamily}
				>
					<Radio.Button value={""} style={{ flex: 1,
						textAlign: "center" }}>
						Обычный
					</Radio.Button>
					<Radio.Button
						value={"Times New Roman, Times, serif"}
						style={{ flex: 1,
							textAlign: "center" }}
					>
						Засечки
					</Radio.Button>
				</Radio.Group>
			</div>
			<div
				className={"d-flex justify-content-between align-items-baseline crippled__item"}
				style={removeEmptyKeys({
					borderColor: theme.color.text,
				})}
			>
				<span
					style={removeEmptyKeys({
						color: theme.color.text,
						fontFamily: theme.fontFamily,
					})}
				>
					Размер
				</span>
				<Radio.Group
					buttonStyle={"solid"}
					style={{
						display: "flex",
						margin: "8px 0",
					}}
					value={theme.fontSizeValue}
					onChange={changeFontSize}
				>
					<Radio.Button value={"default"} style={{ flex: 1,
						textAlign: "center" }}>
						A
					</Radio.Button>
					<Radio.Button value={"middle"} style={{ flex: 1,
						textAlign: "center" }}>
						A+
					</Radio.Button>
					<Radio.Button value={"large"} style={{ flex: 1,
						textAlign: "center" }}>
						A++
					</Radio.Button>
				</Radio.Group>
			</div>
			<div className={"d-flex justify-content-between align-items-baseline crippled__item"}
				style={removeEmptyKeys({
					borderColor: theme.color.text,
				})}
			>
				<span
					style={removeEmptyKeys({
						color: theme.color.text,
						fontFamily: theme.fontFamily,
					})}
				>
					Интервал
				</span>
				<Radio.Group
					buttonStyle={"solid"}
					style={{
						display: "flex",
						margin: "8px 0",
					}}
					value={theme.lineHeightValue}
					onChange={changeLineHeight}
				>
					<Radio.Button value={"default"} style={{ flex: 1,
						textAlign: "center" }}>
						1.0
					</Radio.Button>
					<Radio.Button value={"middle"} style={{ flex: 1,
						textAlign: "center" }}>
						1.5
					</Radio.Button>
					<Radio.Button value={"large"} style={{ flex: 1,
						textAlign: "center" }}>
						2.0
					</Radio.Button>
				</Radio.Group>
			</div>
			<div
				className={"d-flex justify-content-between align-items-baseline crippled__item"}
				style={removeEmptyKeys({
					borderColor: theme.color.text,
				})}
			>
				<span
					style={removeEmptyKeys({
						color: theme.color.text,
						fontFamily: theme.fontFamily,
					})}
				>
					Цвет
				</span>
				<Radio.Group
					buttonStyle={"solid"}
					style={{
						display: "flex",
						textAlign: "center",
						margin: "8px 0",
					}}
					value={theme.colorValue}
					onChange={changeBgColor}
				>
					<Radio.Button
						value={"blackWhite"}
						style={{
							flex: 1,
							textAlign: "center",
							backgroundColor: "#000",
							color: "#fff",
						}}
					>
						А
					</Radio.Button>
					<Radio.Button value={"whiteBlack"} style={{ flex: 1,
						textAlign: "center" }}>
						А
					</Radio.Button>
					<Radio.Button
						value={"blue"}
						style={{
							flex: 1,
							textAlign: "center",
							backgroundColor: "#9dd1ff",
							color: "#000",
						}}
					>
                        A
					</Radio.Button>
					<Radio.Button value={""} style={{ flex: 1,
						textAlign: "center" }}>
						Выкл
					</Radio.Button>
				</Radio.Group>
			</div>
			<div className={"d-flex justify-content-between align-items-baseline crippled__item"}>
				<span
					style={removeEmptyKeys({
						color: theme.color.text,
						fontFamily: theme.fontFamily,
					})}
				>
					Изображения
				</span>
				<Radio.Group
					buttonStyle={"solid"}
					style={{
						display: "flex",
						textAlign: "center",
						margin: "8px 0 16px 0",
					}}
					value={theme.imageValue}
					onChange={changeImage}
				>
					<Radio.Button value={""} style={{ flex: 1,
						textAlign: "center" }}
					className={"radio-buttonovich"}>
						Цвет
					</Radio.Button>
					<Radio.Button value={"gray"} style={{ flex: 1,
						textAlign: "center",
						whiteSpace: "nowrap" }}
					className={"radio-buttonovich"}>
						Ч/Б
					</Radio.Button>
					<Radio.Button value={"off"} style={{ flex: 1,
						textAlign: "center" }}
					className={"radio-buttonovich"}>
						Выкл
					</Radio.Button>
				</Radio.Group>
			</div>
		</div>
	);
}

Crippled.propTypes = {
	goBack: PropTypes.func,
};

Crippled.defaultProps = {
	goBack: () => Function,
};
