import { createContext } from "react";

export const voting = [];

export const VotingContext = createContext(
	{
		voting,
		changeIsVoting: () => Function,
	},
);

VotingContext.displayName = "VotingContext";

export const VotingContextProvider = VotingContext.Provider;
export const VotingConsumer = VotingContext.Consumer;
export default VotingContext;
