import React, { useReducer, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Slider, Progress } from "antd";
import moment from "moment";
import ReactPlayer from "react-player";
import { isMobile, isIOS } from "react-device-detect";

import { useThemeContext } from "../../Layout/ThemeContext";
import { AudioguideContext } from "../../../widgets";

import "./Audio.scss";

export function AudioPlayer({ url, slug, format }) {
	const { state, onReady, onEnded, onBuffer, onBufferEnd, onError, onPlay } = useContext(AudioguideContext);
	const { theme } = useThemeContext();
	const player = useRef(null);

	function init() {
		return {
			mute: false,
			volume: 1,
			ready: false,
			progress: {
				loaded: 0,
				loadedSeconds: 0,
				played: 0,
				playedSeconds: 0,
			},
			duration: 0,
		};
	}

	function reducer(state, { type, payload }) {
		switch (type) {
			case "mute":
				return {
					...state,
					mute: !state.mute,
				};
			case "volume":
				return {
					...state,
					volume: payload,
				};
			case "ready":
				return {
					...state,
					ready: true,
				};
			case "progress":
				return {
					...state,
					progress: payload,
				};
			case "duration":
				return {
					...state,
					duration: payload,
				};
			default:
				throw new Error("Not found the method or unexpected problem");
		}
	}

	const [audioState, dispatch] = useReducer(reducer, {}, init);

	function timeFormat(seconds = 0) {
		return moment(new Date()).startOf("day").seconds(seconds).format(format);
	}

	const size = isMobile ? "large" : "small";

	const { plays, disabled, slug: contextSlug } = state;
	const { mute, volume, ready, duration } = audioState;

	const current = contextSlug === slug;

	let currentTime = 0;
	let secondsLoaded = 10;

	if (player.current && ready) {
		currentTime = player.current.getCurrentTime();
		secondsLoaded = player.current.getSecondsLoaded();
	}

	return (
		<div className={"player"}>
			<style>
				{
					`
						span.ant-progress-text.player__time {
							color: ${theme.color.text}
						}
					`
				}
			</style>
			<ReactPlayer
				ref={player}
				url={url}
				playing={plays && current}
				loop={false}
				controls={false}
				light={false}
				volume={volume}
				muted={mute && current}
				playbackRate={1}
				width={0}
				height={0}
				style={{}}
				progressInterval={1000}
				playsinline={false}
				pip={false}
				stopOnUnmount={true}
				wrapper={"div"}
				// playIcon
				// config
				onReady={() => {
					dispatch(
						{
							type: "ready",
						},
					);
					onReady();
				}}
				onProgress={played => dispatch(
					{
						type: "progress",
						payload: played,
					},
				)}
				onEnded={onEnded}
				onBuffer={onBuffer}
				onBufferEnd={onBufferEnd}
				onError={error => onError(error)}
				onDuration={d => dispatch(
					{
						type: "duration",
						payload: d,
					},
				)}
			/>
			<div className={"player__controls player__controls_mobile"}>
				<Button
					type={"primary"}
					shape={"circle"}
					icon={plays && current ? "pause-circle" : "play-circle"}
					size={size}
					disabled={disabled}
					onClick={() => onPlay(slug)}
				/>
				<div className={"player__progress player__progress_mobile"}>
					<div className={"player__progress__slider-progress"}>
						<Slider
							disabled={disabled}
							min={0}
							max={duration}
							step={0.001}
							value={currentTime}
							onChange={value => {
								if (player.current) {
									player.current.seekTo(value);
								}
							}}
							tooltipVisible={false}
						/>
						<div style={{
							position: "absolute",
							width: "100%",
							top: "-8px",
						}}>
							<Progress
								percent={secondsLoaded * 100 / duration}
								showInfo={false}
								strokeWidth={4}
								strokeColor={"rgb(160 160 160)"}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={"player__progress player__progress_desktop"}>
				<div className={"player__progress__slider-progress"}>
					<Slider
						disabled={disabled}
						min={0}
						max={duration}
						step={0.001}
						value={currentTime}
						onChange={value => {
							if (player.current) {
								player.current.seekTo(value);
							}
						}}
						tooltipVisible={false}
					/>
					<div style={{
						position: "absolute",
						width: "100%",
						top: "-8px",
					}}>
						<Progress
							percent={secondsLoaded * 100 / duration}
							showInfo={false}
							strokeWidth={4}
							strokeColor={"rgb(160 160 160)"}
						/>
					</div>
				</div>
				<span className={"ant-progress-text player__time"}>
					{`${timeFormat(currentTime)} / ${timeFormat(duration)}`}
				</span>
			</div>
			<div className={"player__sound"}>
				<Button
					type={"primary"}
					shape={"circle"}
					icon={"sound"}
					theme={mute ? "" : "filled"}
					size={size}
					disabled={disabled}
					onClick={mute => dispatch(
						{
							type: "mute",
							payload: !mute,
						},
					)}
				/>
				{
					!isIOS && (
						<Slider
							disabled={mute || disabled}
							min={0}
							max={1}
							step={0.01}
							value={volume}
							onChange={value => dispatch(
								{
									type: "volume",
									payload: value,
								},
							)}
							tooltipVisible={false}
						/>
					)
				}
				<div className={"player__time player__time_mobile"}>
					<span className={"ant-progress-text"}>
						{`${timeFormat(currentTime)} / ${timeFormat(duration)}`}
					</span>
				</div>
			</div>
		</div>
	);
}

AudioPlayer.propTypes = {
	url: PropTypes.string,
	slug: PropTypes.string,
	format: PropTypes.string,
};

AudioPlayer.defaultProps = {
	url: "",
	slug: "",
	format: "mm:ss",
};
