import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ErrorBoundary.scss";

export default class ErrorBoundary extends Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	};

	static defaultProps = {
		children: [],
	};

	constructor(props) {
		super(props);

		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error,
		};
	}

	componentDidCatch(error, errorInfo) {
		console.error(this, error, errorInfo);

		// eslint-disable-next-line no-undef
		Sentry.configureScope(scope => {
			Object.keys(errorInfo).forEach(key => {
				scope.setExtra(key, errorInfo[key]);
			});
		});

		// eslint-disable-next-line no-undef
		Sentry.captureException(error);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className={"error-boundary"}>
					<div className={"container"}>
						<div className={"row flex-column"}>
							Произошла ошибка.
							<br />
							Разработчики уже получили уведомление об этом.
							<br />
							Нажмите{" "}
							<a className={"link"} href={"/"}>
								<b>Cmd+Shift+R</b>
							</a>{" "}
							для принудительной перезагрузки страницы, или{" "}
							<a href={"/"}>
								<b>перейдите на главную страницу.</b>
							</a>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}
