import React from "react";
import PropTypes from "prop-types";

import { TextBlockMedia } from "../../Blocks";
import { translit, markdownConverter } from "../../../helpers";

import "./LiturgicalDirections.scss";

export default function LiturgicalDirections({ items }) {
	return items.length ? (
		<div className={"calendar__desc-block_paragraph liturgical_directions"}>
			<h4 className={"my-3 calendar__desc-block_title"}>
                Богослужебные указания
			</h4>
			{
				items.map(({ content_blocks }) =>
					content_blocks && content_blocks.map(({ title, body, media, type }, idx) => {
						if (type === "schedule_broadcast") {
							return null;
						}

						return (
							<TextBlockMedia
								id={translit(title)}
								key={`${translit(title)}-${idx}`}
								title={title}
								items={media ? media.items : []}
								desc = {markdownConverter(body)}
								left={idx % 2 !== 0}
								orientation={type}
							/>
						);
					}),

				)
			}
		</div>
	) : null;
}

LiturgicalDirections.propTypes = {
	items: PropTypes.array,
};

LiturgicalDirections.defaultProps = {
	items: [],
};
