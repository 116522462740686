/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";

import Logo from "../Logo";
import { Icon } from "../Media";

import SVGMap from "./svg";

import "./Map.scss";

import Map from "../Map";

import MapRegion from "../../images/mapRegion.jpg";

import arzamas from "./arzamas.jpg";
import diveevo from "./diveevo.jpg";
import sarov from "./sarov.jpg";
import viezdnoe from "./viezdnoe.jpg";
import gluhovo from "../../images/gluhovo.jpg";
import veryahushki from "../../images/veryakyshi.jpg";
import orehovecTrue from "../../images/orehovec.jpg";
import elizarievo from "../../images/elizarievo.jpg";

const cities = [
	{
		id: "arzamas",
		url: "/arzamas",
		title: "Арзамас",
		img: arzamas,
	},
	{
		id: "sarov",
		url: "/sarov",
		title: "Саров",
		img: sarov,
	},
	{
		id: "diveevo",
		url: "/diveevo",
		title: "Дивеево",
		img: diveevo,
	},
	{
		id: "viezdnoe",
		url: "/vyezdnoe",
		title: "Выездное",
		img: viezdnoe,
	},
	{
		id: "elizarievo",
		url: "/elizarievo",
		title: "Елизарьево",
		img: elizarievo,
	},
	{
		id: "arzamas",
		url: "/mordovian",
		title: "Мордовский природный заповедник",
		img: arzamas,
	},
	{
		id: "gluhovo",
		url: "/gluhovo",
		title: "Глухово",
		img: gluhovo,
	},
	{
		id: "veryahushki",
		url: "/veryahushki",
		title: "Веряхушки",
		img: veryahushki,
	},
	{
		id: "orehovec",
		url: "/orehovec",
		title: "Ореховец",
		img: orehovecTrue,
	},
];

export class ObjectMap extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: null,
			title: "",
			img: "",
			maxHeight: "auto",
		};

		/* this.handleLoad = this.handleLoad.bind(this); */
		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);
		this.handleRedirect = this.handleRedirect.bind(this);
		this.handleHide = this.handleHide.bind(this);
	}

	componentDidMount() {
		this.setState({
			maxHeight: this.maps.offsetHeight,
		});
	}

	handleRedirect(event) {
		event.preventDefault();

		const city = cities.find(element => element.id === event.currentTarget.id);

		city && navigate(city.url);
	}

	handleShow(event) {
		event.preventDefault();

		const map = this.map.getBoundingClientRect();
		const city = cities.find(element => element.id === event.currentTarget.id);
		const center = this.map.offsetHeight / 2;
		const top = event.clientY - this.map.getBoundingClientRect().top + 20;
		const bottom = -event.clientY + this.map.getBoundingClientRect().bottom + 20;
		const width = event.clientX - this.map.getBoundingClientRect().left + 20;
		let toastWidth = 0;
		let toastHeight = 0;

		if (this.toast) {
			toastWidth = this.toast.offsetWidth;
			toastHeight = this.toast.offsetHeight;
		}

		city &&
			this.setState({
				id: event.currentTarget.id,
				title: city.title,
				img: city.img,
				position: {
					top: map.height < toastHeight + top ? "initial" : map.top < top ? 0 : top,
					left: width,
					bottom: map.height < toastHeight + top ? 0 : "initial",
				},
			});
	}

	handleHide() {
		this.setState({ id: null });
	}

	render() {
		return (
			<div
				className="d-none d-md-block Map"
				ref={map => (this.map = map)}
				style={{ maxHeight: this.state.maxHeight }}
			>
				<h1 className={"p-3"} style={{ fontSize: "33px" }}>
					{this.props.title}
				</h1>
				<h5 className={"pl-3"}>{this.props.subtitle}</h5>
				<div id="wrapmap" className="wrapmap">
					<div id="RegionMapWrapper">
						<div
							className="position-relative d-flex align-items-center justify-content-center"
							style={{ width: "100%" }}
						>
							<div id="RegionMap" className="MapPad">
								<SVGMap
									ref={maps => (this.maps = maps)}
									onMouseEnter={this.handleShow}
									onClick={this.handleRedirect}
									onMouseLeave={this.handleHide}
								/>
								{this.state.id && (
									<div
										ref={toast => (this.toast = toast)}
										className={"toast"}
										style={this.state.position}
									>
										<h2 className={"pb-3"}>{this.state.title}</h2>
										<img src={this.state.img} alt={"Object map"} />
									</div>
								)}
							</div>
							<div className={"d-none cite"}>
								<Logo
									link={"/"}
									style={{ textDecoration: "none" }}
									icon={
										<Icon
											id={"logo"}
											alt={"Logo"}
											style={{ width: 60, height: 60, margin: "0 .75rem" }}
										/>
									}
								/>
								«Тут у нас и Афон и Иверия и Киев»
							</div>
							<img id="canvas-img" src={MapRegion} className="WarpMap" alt="RegionMap" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const FETCH_QUERY = gql`
	query FetchData {
		v_map_objects {
			location_object
			title_full
			slug
			main_image
			main_image_preview
			city_slug
			monastery_slug
			type
			settings
		}
	}
`;

export function ClusterMap({ filter = "", className, style }) {
	const [isMount, setIsMount] = useState(false);
	const [allItems, setAllItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	let temp_filteredItems = [];

	const { loading, data: fetchData, error } = useQuery(FETCH_QUERY, {
		fetchPolicy: "cache-and-network",
		ssr: false,
	});

	useEffect(() => {
		setIsMount(true);
	});

	useEffect(() => {
		if (isMount) {
			const data = get(fetchData, "v_map_objects", []).map(e => {
				return ({
					...e,
					city: {
						slug: e.city_slug
					},
					monastery: {
						slug: e.monastery_slug
					}
				})
			});
			setAllItems(data);
			setFilteredItems(data);
		}
	}, [loading]);

	useEffect(() => {
		if (filter) {
			const regexp = new RegExp(filter, "i");
			temp_filteredItems = allItems.filter(e => e.title_full.match(regexp));
			if (temp_filteredItems.length == 0) {
				setFilteredItems(filteredItems);
			} else {
				setFilteredItems(temp_filteredItems);
			}
		} else {
			setFilteredItems(allItems);
		}
	}, [filter]);

	return (
		filteredItems &&
		!!filteredItems.length && (
			<Map mapId={"MenuAboutClusterMap"} items={filteredItems} className={className} style={style} useSlug={true} />
		)
	);
}

ClusterMap.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
};

ClusterMap.defaultProps = {
	className: "",
	style: {},
};
