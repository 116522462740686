import React from "react";
import { Icon, Button } from "antd";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { useThemeContext } from "../Layout/ThemeContext";

import "./Buttons.scss";
import { removeEmptyKeys } from "../../helpers";

export function GoBack({ onClick }) {
	const { theme } = useThemeContext();

	return (
		<div
			className={"btn btn_circle"}
			style={removeEmptyKeys({
				borderColor: theme.color.text,
			})}
			onClick={onClick}
		>
			<Icon
				type={"arrow-left"}
				style={removeEmptyKeys({
					color: theme.color.text,
				})}
			/>
		</div>
	);
}

GoBack.propTypes = {
	onClick: PropTypes.func,
};

GoBack.defaultVAlue = {
	onClick: () => Function,
};

export function ContentButtons({ items }) {
	const { theme } = useThemeContext();

	return (
		<div
			className={"row justify-content-center align-items-center flex-column flex-md-row mt-5 mb-3"}
		>
			{items.map(({ title, link }, idx) => {
				const blank = /^(http|https|tel|mailto):/i.test(link);

				return (
					<div
						key={`${title}-${idx}`}
						className="col-12 col-md-4"
					>
						{
							blank ?
								<Button
									block
									className={"mb-3 m-md-0 button-accent"}
									href={link}
									target={"_blank"}
									style={removeEmptyKeys({
										color: theme.color.body,
										backgroundColor: theme.color.text,
									})}
								>
									{title}
								</Button>
								:
								<Link
									className={"mb-3 m-md-0 button-accent ant-btn ant-btn-block"}
									to={link}
									style={removeEmptyKeys({
										color: theme.color.body,
										backgroundColor: theme.color.text,
									})}
								>
									{title}
								</Link>
						}
					</div>
				);
			})}
		</div>
	);
}

ContentButtons.propTypes = {
	items: PropTypes.array,
};

ContentButtons.defaultProps = {
	items: [],
};
