import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { MetaPosition } from "../../Meta";
import { Icon } from "../../Media";

export default function NoScriptTopMenu({ items }) {
	return (
		<div className={"d-flex justify-content-between align-items-center top-menu__item p-0"}>
			{
				items.map(({ title, icon, url }, idx) => (
					<Link
						key={`top-menu-item-${idx}`}
						to={url}
						className={"d-flex link link_ugly link_main-light top-menu__item pr-5 pl-5"}
						style={{
							height: "100%",
						}}
					>
						<Icon
							id={icon}
							style={{
								width: 28,
								height: 28,
								marginRight: "1rem",
							}}
						/>
						<div
							className={"link_text-light"}
							itemProp="name"
						>
							{title}
						</div>
						<MetaPosition content={idx} />
					</Link>
				))
			}
		</div>
	);
}
NoScriptTopMenu.propTypes = {
	items: PropTypes.array,
};

NoScriptTopMenu.defaultProps = {
	items: [],
};
