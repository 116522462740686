import { createContext } from "react";

export const radio = {};

export const RadioContext = createContext(
	{
		radio,
		changeRadio: () => Function,
	},
);

RadioContext.displayName = "RadioContext";

export const RadioContextProvider = RadioContext.Provider;
export const RadioConsumer = RadioContext.Consumer;
export default RadioContext;
